import { DtcPopulatedFieldsReportData, DtcPopulatedFieldsReport, SubCategory } from "./dtc-populated-fields.types";

function transformData(
  input: DtcPopulatedFieldsReportData, 
  categoryKey: string
): DtcPopulatedFieldsReport[] {
  const dataForCategory = input[categoryKey] as Record<string,{ stats: Array<{ autofill_fields: string; errors: number }> }>;

  if (!dataForCategory) {
    return [];
  }

  const result = Object.keys(dataForCategory).map((key) => {
    const stats = dataForCategory[key].stats || [];

    const subCategoriesMap: { [reason: string]: SubCategory } = {};

    let totalCount = 0;

    stats.forEach(({ autofill_fields, errors }: { autofill_fields: string; errors: number }) => {
      totalCount += errors;

      if (!subCategoriesMap[autofill_fields]) {
        subCategoriesMap[autofill_fields] = {
          reason: autofill_fields,
          count: errors
        };
      } else {
        subCategoriesMap[autofill_fields].count += errors;
      }
    });

    const subCategories = Object.values(subCategoriesMap) as SubCategory[];

    const reportItem: DtcPopulatedFieldsReport = {
      [categoryKey]: key,
      count: totalCount,
      subCategories
    };

    return reportItem;
  });

  return result;
}

export default transformData;

