import React, {useContext, useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from "dayjs";
import ReportName from "../../../../odinForgeService/Enums/ReportName";
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import '../../../../css/AffiliateSalesReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import FilterBar from "../../../organism/filterbar/FilterBar";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import { determineNestingLevels, reorderLevels, updateExpandedState } from "../../../../helpers/collapsingFunctions";
import { formatLocalISO } from "../../../../helpers/formatLocalISO";
import { createHash } from "../../../../helpers/createHash";
import { UserContext } from "../../../../context/UserContext/UserContext";
import DefaultTableMessage from "../../../organism/DefaultTableMessage/DefaultTableMessage";
import { DataRangesParamsList } from "../../../../types/enums/DataRangesParamsList";
import ReportTable from "../../../organism/ReportTable/ReportTable";
import { fieldsForCSV, getColumns, namesEnum, options, rangeConfigs, dateRangeList, generateRangeConfigs } from "./utils/config";
import { CapProjectionsReportClonedData, CapProjectionsReportData, NestedLevels, TransformedCapProjectionsReportData } from "./types/cap-projections-report.types";
import transformDataToCSVFormat from "./utils/transformDataToCSVFormat";
import { prepareDataForTransform, transformData } from "./utils/transformData";
import useMediaQuery from "../../../../hooks/useMediaQuery";

dayjs.extend(utc);
dayjs.extend(timezone);

interface props {
    names: string[]
    clonedData: CapProjectionsReportClonedData
    passData: (data: CapProjectionsReportClonedData) => void
    passId: (id: string | number | null | undefined) => void
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const CapProjectionsReport = ({
    names,
    clonedData,
    passData,
    passId,
    setRefreshKey,
    refreshKey,
}: props) => {
    const { theme } = useContext(ThemeContext)!;
    const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence'] && clonedData['group_sequence'].length > 0 ? clonedData['group_sequence'] : ['Merchant Account']);
    const [selectedGroupings, setSelectedGroupings] = React.useState(3)
    const [collapse, setCollapse] = React.useState(clonedData['expand_level']?clonedData['expand_level']:'0');
    const [copyButton, setCopyButton] = React.useState(!(clonedData['group_sequence']));
    const [freezeButton, setFreezeButton] = React.useState(!(clonedData['group_sequence']))
    const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date']?dayjs.tz(clonedData['start_date']):dayjs.tz().startOf('day'))
    const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date']?dayjs.tz(clonedData['end_date']):dayjs.tz())
    const [showTable, setShowTable] = React.useState(!!clonedData['data'])
    const [data, setData] = React.useState<TransformedCapProjectionsReportData[] | []>(clonedData['data']?clonedData['data']:[]);
    const expandLevel = collapse;
    const reportName = ReportName.CapProjectionsReport;
    const groupSequence =  groupings;
    const reportConfigValues = useRef({ expandLevel, reportName, groupSequence });
    const [clonedReportId] = React.useState(clonedData['id'])
    const [showDelete] = React.useState(clonedData['type'] === 'cloned')
    const [deletePopup, setDeletePopup] = React.useState(false)
    const [tableLoading, setTableLoading] = useState(true);
    const [dateRange, setDateRange] = useState(clonedData['date_range']?clonedData['date_range']:1);
    const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
    const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
    const [previousStartDate, setPreviousStartDate] = useState(startDate);
    const [previousEndDate, setPreviousEndDate] = useState(endDate);

    let transformedGroupings = ['Currency', ...groupings];

    const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});

    const isInitialLoadCollapse = useRef(true);

    const isInitialLoad = useRef(true);

    const [loaderKey, setLoaderKey] = useState(0);

    const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
    const [globalFilter, setGlobalFilter] = useState('');
    const [isNoData, setIsNoData] = useState(false);

    const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<TransformedCapProjectionsReportData[]>([]);

    const [selectedType, setSelectedType] = React.useState(clonedData['selectedType']?clonedData['selectedType']:'main_offer');
    const [previousSelectedType, setPreviousSelectedType] = React.useState(selectedType);

    const [csvFilters, setCsvFilters] = useState<string[]>([])

    const isMobile = useMediaQuery('(max-width: 1023px)');

    useEffect(() => {
        if (data && collapse !== undefined) {
            const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
            setNestedLevels(updatedLevels);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapse, data]);

    useEffect(() => {
        if (dateRange !== 0) handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if ((startDate as Dayjs).isAfter(endDate)) {
            setEndDate(startDate);
    }},[startDate,endDate])

    const previousValuesRef = useRef({
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange,
        selectedType: selectedType
    });

    useEffect(() => {
        const isButtonActive = selectedGroupings > 0;

        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            collapse !== clonedData['expand_level']
            || JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence'])
            || dateRange !== clonedData['date_range']
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
            || selectedType !== clonedData['selectedType']
        );
        if (valuesChanged) {
            setFreezeButton(!isButtonActive);
        }
        if (!valuesChanged) {
            setFreezeButton(true)
        }
        reportConfigValues.current = { expandLevel, reportName, groupSequence };

        // Refetch if date range is not custom
        const previousValues = previousValuesRef.current;
        if (dateRange && (
            previousValues.groupings !== JSON.stringify(groupings) ||
            previousValues.collapse !== collapse ||
            previousValues.dateRange !== dateRange ||
            previousValues.selectedType !== selectedType
        )) {
            handleBlur();
            previousValuesRef.current = {
                groupings: JSON.stringify(groupings),
                collapse: collapse,
                dateRange: dateRange,
                selectedType: selectedType
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings, reportName, dateRange, startDate, endDate, selectedType]);

    useEffect(() => {
        if (dateRange === 0) {
            handleBlur()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupings]);

    useEffect(() => {
        previousValuesRef.current = {
            groupings: JSON.stringify(groupings),
            collapse: collapse,
            dateRange: dateRange,
            selectedType: selectedType
        };
    }, [groupings, collapse, reportName, dateRange, theme, selectedType]); 

    function generateParams() {
        const params = new URLSearchParams();
        if (dateRange === 0) {
            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        } else {
            const { startDate, endDate } = (generateRangeConfigs(timezone))[dateRange];

            params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
            params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
        }

        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        filters.forEach((value, index) => {
            params.append(`filters[${index}]`, value)
        })
        params.append('timezone', timezone) 
        if (isSuperAdmin && superTenant) {
            superTenant?.toString() && params.append('tenant', superTenant?.toString())
        } else {
            tenantId?.toString() && params.append('tenant', tenantId?.toString())
        }

        params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList])

        params.append('selected_type', selectedType)

        return params;
    }

    function generateObjectToHash(){
        let filters: string[] = []
        groupings.forEach((value) => {
            filters.push((namesEnum)[value])
        })
        const dataToHash :object = {
            filters: filters,
            startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
            endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
        }

        return dataToHash;
    }

    const isDataSame = (groupings: string[], previousGroupings: string[], startDate: string | Dayjs, previousStartDate: string | Dayjs, superTenant: string | null = null, previousSuperTenant: string | null = null, selectedType: string | null = null) => {
        if (!isSuperAdmin) {
            return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && selectedType === previousSelectedType);
        }
        return (groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && superTenant === previousSuperTenant && selectedType === previousSelectedType);
    };

    useEffect(() => {
        if (!isInitialLoad.current) {
            handleBlur();
        } else {
            isInitialLoad.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    async function handleBlur(isRefresh?: boolean) {
        setShowTable(false);
        if (tenantId === null) return;
        setTableLoading(true);
        if (groupings.length === 0) {
            setTableLoading(false);
            return;
        }
        if (isRefresh) {} else {
            setSearchTerm('');
            localStorage.removeItem('searchTerm');
        }
        const params =generateParams()
        if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant, selectedType) || isRefresh) {
            setData([]);
            const cachedData = Utils.instance().checkHash(generateObjectToHash(), reportName)
            if (cachedData){
                setTableLoading(false);
                const transformedData = await transformData(cachedData);
                setData(transformedData)
                setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))

                setSelectedGroupings(transformedGroupings.length);
                if (Number(collapse)>transformedGroupings.length){
                    setCollapse((transformedGroupings.length).toString())
                }
                setShowTable(true);
                
                // It is for expanding the table to the last level
                if (!isInitialLoadCollapse.current) {
                    setCollapse(transformedGroupings.length.toString());
                } else {
                    isInitialLoadCollapse.current = false;
                }
            } else {
                OdinForgeService.instance().getCapProjectionsReportData(params, clonedReportId)
                // @ts-ignore
                    .then(async (result: {data: CapProjectionsReportData, cacheTime: number}) => {
                        setTableLoading(false);
                        const transformedData = await transformData(result.data);

                        if (result.data[(namesEnum)[(transformedGroupings)[0]]] && (result.data[(namesEnum)[(transformedGroupings)[0]]]! as []).length !== 0){
                            const csvData = prepareDataForTransform(result.data)
                            // @ts-ignore
                            setTransformedTableDataCSV(csvData)

                            setData(transformedData);
                            setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)))

                            setSelectedGroupings(transformedGroupings.length);
                            if (Number(collapse)>transformedGroupings.length){
                                setCollapse((transformedGroupings.length).toString())
                            }
                            setShowTable(true);

                            createHash(generateObjectToHash, result.data, reportName, result.cacheTime)

                            // It is for expanding the table to the last level
                            if (!isInitialLoadCollapse.current) {
                                setCollapse(transformedGroupings.length.toString());
                            } else {
                                isInitialLoadCollapse.current = false;
                            }
                        } else {
                            setTableLoading(false);
                            setData([]);
                        }
                    })
                    .catch((error) => {
                        if (error.code === 'ERR_CANCELED') {
                            setLoaderKey(prevKey => prevKey + 1);
                            console.log('Request canceled');
                        } else {
                            setTableLoading(false);
                            Utils.instance().onFailure('An error occurred while fetching the report');
                        }
                    });
            }
        } else {
            if (data.length!==0)
            setTableLoading(false);
        }
        setPreviousGroupings(groupings);
        setPreviousStartDate(startDate)
        setPreviousEndDate(endDate)
        setPreviousSuperTenant(superTenant);
        setPreviousSelectedType(selectedType);
    }

    function generateConfigRequestBody() {
        return {
            filters: {
                group_sequence: groupings,
                expand_level: collapse,
                date_range: dateRange,
                start_date: startDate ? formatLocalISO((startDate as Dayjs)) : null,
                end_date: endDate ? formatLocalISO((endDate as Dayjs)) : null,
                selected_type: selectedType
            },
            report_name: reportName
        }
    }

    function generateClonedData(reportId: number)  {
        const dataToPass = {
            group_sequence: groupings,
            id: reportId,
            expand_level: collapse,
            start_date: startDate,
            end_date: endDate,
            data: data,
            type: 'cloned',
            date_range: dateRange,
            selectedType: selectedType
        }
        passData(dataToPass)
    }

    useEffect(() => {
        handleBlur()
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [dateRange, superTenant, groupings, selectedType]);

     const handleSearch = (isReset = false) => {
        if (searchTerm !== '') localStorage.setItem('searchTerm', searchTerm);

        if (!isReset) {
            setSearchTerm(''); 
            localStorage.removeItem('searchTerm');
            setGlobalFilter('')
            setIsNoData(false)
        } else {
            setGlobalFilter(searchTerm)
        }

        handleBlur(true)
    };

    const handleChangeType = (newValue: string) => {
        setSelectedType(newValue);
    };

    useEffect(() => {
        setCsvFilters(Array.from(new Set([
            ...groupings.map((grouping) => `${namesEnum[grouping]}`),
            `tableType-${selectedType}`,
            ...(searchTerm ? [`search-${searchTerm}`] : []), 
        ])))
    }, [groupings, selectedType, searchTerm])

    return (
        <div>
            <div className="!p-6 tablet-size:!p-0">
            <FilterBar
                handleBlur={handleBlur}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                dateRange={dateRange}
                setDateRange={setDateRange}
                groupings={groupings}
                setGroupings={setGroupings}
                collapse={collapse}
                setCollapse={setCollapse}
                selectedGroupings={selectedGroupings}
                copyButton={copyButton}
                freezeButton={freezeButton}
                showDelete={showDelete}
                clonedReportId={clonedReportId}
                setDeletePopup={setDeletePopup}
                passId={passId}
                deletePopup={deletePopup}
                setFreezeButton={setFreezeButton}
                setCopyButton={setCopyButton}
                clonedData={clonedData}
                generateConfigRequestBody={generateConfigRequestBody}
                generateClonedData={generateClonedData}
                names={names}
                title={ReportName.CapProjectionsReport}
                isAdditionalCurrencyField={true}
                transformedGroupings={transformedGroupings}
                maxSelections={5}
                data={transformedTableDataCSV}
                fieldsForCSV={fieldsForCSV}
                disabledCSV={!(data && data.length !== 0) || transformedTableDataCSV.length === 0}
                namesEnum={namesEnum}
                isCSV={true}
                transformDataToCSVFormat={transformDataToCSVFormat}
                csvFilters={csvFilters}
                isCustomFilters={true}
                rangeConfigs={rangeConfigs}
                generateRangeConfigs={generateRangeConfigs}
                dateRangeList={dateRangeList}
                setRefreshKey={setRefreshKey}
                refreshKey={refreshKey}
                isSearch={true}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
                isCustomToggleButtonGroup={true}
                selectedType={selectedType}
                handleChangeTxsType={handleChangeType}
                options={options}
                toggleLabelWidth={isMobile ? '' : '70px'}
            />
            </div>
            {
                data && data.length !== 0 && showTable && groupings.length !== 0 ? (
                    <ReportTable
                        data={data} 
                        initialExpandedDepth={(Number(collapse) - 1)?.toString()}
                        onNoData={setIsNoData} 
                        globalFilter={globalFilter}
                        searchTerm={searchTerm}
                        groupingsCount={transformedGroupings.length}
                        levelNames={transformedGroupings}
                        getColumns={getColumns}
                    />
                ) : (
                    <div className="mt-6">
                        <DefaultTableMessage
                            key={loaderKey}
                            tableLoading={tableLoading}
                            state={
                                groupings.length === 0 ? 
                                'noFilters' :
                                isNoData ? 'emptySearchResult' :
                                'noData'
                            }
                        />
                    </div>
                )
            }
        </div>
    );
}

export default CapProjectionsReport