import { DonutChart} from '@tremor/react';
import React, { useContext } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import FilterDropDown from './FilterDropDown';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import { DonutChartData, ForecastedRebillsReportChartData } from '../types/forecasted-rebills-report.types';

// We are using 2 different color enums for the chart, because this lib has issue with colors. 
// The first one is an object and the second one is an array.
const chartColorEnum: { [key: number]: string } = {
    0: "#0095ac",
    1: "#e96a0f",
    2: "#a7a9f8",
    3: "#a855f7",
    4: "#06b6d4",
    5: "#5acbff",
    6: "#0ea4e9",
    7: "#3b82f6",
    8: "#10c075",
    9: "#e5e911",
    10: "#e8b90e",
    11: "#e9920c",
    12: "#6366f1",
    13: "#e96a0f",
    14: "#F05252",
    15: "#C27803",
    16: "#71717a",
    17: "#78716c",
    18: "#10b981",
};

const chartColorArray = [
    "#0095ac",  //  0
    "#e96a0f",  //  1
    "#a7a9f8",  //  2
    "#a855f7",  //  3
    "#06b6d4",  //  4
    "#5acbff",  //  5
    "#0ea4e9",  //  6
    "#3b82f6",  //  7
    "#10c075",  //  8
    "#e5e911",  //  9
    "#e8b90e",  //  10
    "#e9920c",  //  11
    "#6366f1",  //  12
    "#e96a0f",  //  13
    "red",      //  14
    "yellow",   //  15
    "zinc",     //  16
    "stone",    //  17
    "emerald",  //  18
];

interface DonutChartHeroProps {
    data: ForecastedRebillsReportChartData[];
    setChartFilter: React.Dispatch<React.SetStateAction<string>>
    chartFilter: string
    groupings:string[]
}

export function DonutChartHero({ data, setChartFilter, chartFilter, groupings}: DonutChartHeroProps) {
    const { themedColors } = useContext(ThemeContext)!;

    function renameFields(data: ForecastedRebillsReportChartData[]): DonutChartData[] {
        return data
            .sort((a, b) => b.stats - a.stats)
            .map((item) => {
                const newItem: Partial<DonutChartData> = {};
    
                if (item.stats !== undefined) {
                    newItem.value = item.stats;
                }
    
                Object.keys(item).forEach((key) => {
                    if (key !== 'stats') {
                        newItem.name = capitalizeSpecificWords(
                            item[key] === 'NaN' ||
                            item[key] === null ||
                            item[key] === '' ||
                            item[key] === undefined
                                ? '---'
                                : String(item[key])
                        );
                    }
                });
    
                return newItem as DonutChartData;
            });
    }
    

    const filterList = groupings

    return (
        <div className='w-full'>
                    <div className='flex items-center justify-between w-full pb-6'>
                        <p className='text-lg font-medium' style={{color: themedColors.content}}>{chartFilter}</p>
                        {chartFilter && <FilterDropDown filterList={filterList} chartFilter={chartFilter} setChartFilter={setChartFilter} />}
                    </div>
                    <div className="flex flex-wrap md:flex-row md:flex-nowrap justify-between gap-4 tablet-size:gap-10 items-start">
                            <DonutChart
                                data={renameFields(data)}
                                variant="pie"
                                category="value"
                                index="name"
                                colors={chartColorArray}
                                className={`w-[170px]  md:w-64  tablet-size:w-96 shrink-0 h-40  md:h-64 tablet-size:h-80 [&_g]:stroke-none`}
                            />
                        <div className={"flex flex-col flex-1 flex-wrap gap-2"} style={{minWidth: '280px'}}>
                            <div 
                                className='flex items-center justify-between'
                                style={{
                                    borderBottom: `1px solid ${themedColors.border}`,
                                    paddingBottom: '3px',
                                    marginBottom: '4px'
                                }}
                            >
                                <p className='p-1 text-sm font-bold' style={{color: themedColors.content}}>Total</p>
                                <p className='p-0 text-sm font-bold' style={{color: themedColors.content}}>
                                    {data.reduce((acc, item) => {
                                        return acc + item.stats;
                                    }
                                    , 0)}
                                </p>
                            </div>
                            {renameFields(data).sort((a, b) => b.value - a.value).map((item, index) => (
                                <div className="relative flex flex-nowrap items-center gap-4 justify-start w-full" key={index} style={{minWidth: 0}}>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{fontSize: "8px", padding: "5px", color: `${chartColorEnum[index]}`}}
                                            className={"mr-1 flex-shrink-0"}
                                        />
                                        <div className='flex-1'  style={{minWidth: '0px'}}>
                                        <p
                                            className='text-sm flex-grow'
                                            style={{
                                                color: themedColors.content,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                minWidth: 0
                                            }}
                                        >{capitalizeSpecificWords((item.name === 'NaN' || item.name === null || item.name === "" || item.name === undefined) ? "---" : item.name)}</p>
                                </div>
                                    <p
                                        className={'right-0 text-sm overflow-hidden font-semibold ml-auto flex-shrink-0'} style={{color: themedColors.content}}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                </div>
        </div>
    );
}