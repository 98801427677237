import React, { useContext, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Icon } from '@tremor/react';
import { styled } from '@mui/material/styles';
import EmptyCheckbox from '../../../assets/icons/EmptyCheckbox';
import CheckedCheckbox from '../../../assets/icons/CheckedCheckbox';
import { MenuItem } from '@mui/material';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import Fade from '@mui/material/Fade';


// Example of data:

// interface MenuItem {
//     id: string;
//     label: string;
//     checked?: boolean;
//     subItems?: MenuItem[];
// }

// const menuItems: MenuItem[] = [
//     {
//         id: 'geo', 
//         label: 'Geo', 
//         subItems: [
//             { id: 'us', label: 'US' },
//             { id: 'fr', label: 'France' },
//             { id: 'gb', label: 'Great Britain' },
//             { id: 'de', label: 'Germany' }
//         ]
//     },
//     {
//         id: 'device', 
//         label: 'Device',
//         subItems: [
//             { id: '1', label: 'US' },
//             { id: '2', label: 'France'},
//             { id: '3', label: 'Great Britain' },
//             { id: '4', label: 'Germany' }
//         ]
//     },
//     {
//         id: 'browser', 
//         label: 'Browser',
//         subItems: [
//             { id: '5', label: 'US'},
//             { id: '6', label: 'France'},
//         ]
//     },
//     {
//         id: 'user_agent', 
//         label: 'User Agent',
//         subItems: [
//             { id: '7', label: 'US' },
//             { id: '8', label: 'France' },
//             { id: '9', label: 'Great Britain' },
//         ]
//     }
// ];

// Example of state and function:

// const [selectedItems, setSelectedItems] = useState<{ [key: string]: { [subItemId: string]: boolean } }>({});

// const handleSelectionChange = (newSelectedItems: { [key: string]: { [subItemId: string]: boolean } }) => {
//   setSelectedItems(newSelectedItems);
//   console.log(newSelectedItems)
// };

// Example of calling this component:

// <FilterDropdown
    // menuItems={menuItems}
    // selectedItems={selectedItems}
    // onSelectionChange={handleSelectionChange}
// />

// Styling the accordion

interface MenuItemProp {
  id: string;
  label: string;
}

interface DropdownProps {
  menuItems: {
    id: string;
    label: string;
    subItems?: MenuItemProp[];
  }[];
  selectedItems: { [key: string]: { [subItemId: string]: boolean } };
  onSelectionChange: (selectedItems: {[key: string]: { [subItemId: string]: boolean };}) => void;
}

const CustomAccordion = styled(Accordion)({
    marginTop: '0 !important',
    padding: '0',
    boxShadow: '0px 0px 0px 1px #0000000D, 0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: '0',
    },
});

const FilterDropdown = ({
  menuItems,
  selectedItems,
  onSelectionChange,
}: DropdownProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const { themedColors } = useContext(ThemeContext)!;

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded((prevExpanded) => 
          isExpanded ? [...prevExpanded, panel] : prevExpanded.filter(p => p !== panel)
        );
    };

    const handleCheckboxChange = (parentId: string, itemId: string, checked: boolean) => {
        const newSelectedItems = { ...selectedItems, [parentId]: {...selectedItems[parentId], [itemId]: checked} };
        onSelectionChange(newSelectedItems);
    };

    // Styled accordion header
    const CustomAccordionSummary = styled(AccordionSummary)({
        // @ts-ignore
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${themedColors.border}`,
        marginBottom: -1,
        padding: '0 16px',
        minHeight: 44,
        '&.Mui-expanded': {
            minHeight: 44,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0',
        },
        // Use function to change color
        // @ts-ignore
        color: (props) => (props.$expanded ? 'black' : '#9e9e9e'),
    });

  return (
    <div className='w-auto rounded-xl mt-1'>
      {menuItems.map((item, index) => (
        <CustomAccordion 
        // @ts-ignore
            slots={{ transition: Fade }}
            expanded={expanded.includes(`panel${index}`)} 
            onChange={handleChange(`panel${index}`)} 
            key={item.id} 
            sx={{
                "& .MuiAccordionSummary-expandIconWrapper span": {
                    color: themedColors.content,
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded span": {
                    color: "#0079FF",
                }
            }}
        >
          <CustomAccordionSummary
            expandIcon={
              <Icon
                icon={
                  expanded.includes(`panel${index}`)
                    ? ChevronDownIcon
                    : ChevronDownIcon
                }
              />
            }
            aria-controls={`panel${index}d-content`}
            id={`panel${index}d-header`}
            // @ts-ignore
            expanded={expanded === `panel${index}`}
            sx={{
                height: '40px',
            }}
          >
            <Typography>{item.label}</Typography>
          </CustomAccordionSummary>
          <AccordionDetails style={{
            padding: '0',
          
          }}>
            {item.subItems?.map((subItem) => {
              return (
                <MenuItem
                  key={subItem.id}
                  value={subItem.id}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheckboxChange(
                      item.id,
                      subItem.id,
                      !(selectedItems[item.id]?.[subItem.id] || false)
                    )
                  }
                  }
                  sx={{
                    '&.MuiMenuItem-root': {
                        marginTop: '1px',
                        padding: '7px 8px',
                        height: '40px',
                      '&.MuiMenuItem-root:hover, &.Mui-selected': {
                        backgroundColor: themedColors.background_1,
                      },
                      '& .MuiTypography-root': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '14px !important',
                      },
                    },
                  }}
                >
                  <Checkbox
                    checked={selectedItems[item.id]?.[subItem.id] || false}
                    onMouseDown={(e) => e.stopPropagation()}
                    onMouseUp={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(
                        item.id,
                        subItem.id,
                        e.target.checked
                      );
                    }}
                    inputProps={{
                      'aria-label': `Checkbox for ${subItem.label}`,
                    }}
                    icon={<EmptyCheckbox />}
                    checkedIcon={<CheckedCheckbox />}
                  />
                  <ListItemText primary={subItem.label} />
                </MenuItem>
              );
            })}
          </AccordionDetails>
        </CustomAccordion>
      ))}
    </div>
  );
};

export default FilterDropdown;
