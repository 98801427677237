// @ts-nocheck
import React, { SVGProps, useContext, useEffect, useRef, useState } from 'react';
import { Box, Stepper, Step, StepLabel, CircularProgress, MobileStepper, StepIconProps, Checkbox, styled, Theme } from '@mui/material';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

interface DataLoaderProps {
  steps: string[];
  currentStep: number;
  loading: boolean;
}

const CheckedCheckbox = (props: SVGProps<SVGSVGElement>) => {
  const { theme } = useContext(ThemeContext)!;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill={theme === 'light' ? '#0079FF' : '#0079FF'} rx={12} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.334 8 10 15.333 6.667 12"
      />
    </svg>
  )
}

function StepIcon(props: StepIconProps & { index: number }) {
  const { theme } = useContext(ThemeContext)!;
  const { active, completed, index } = props;

  return (
    <>
      {active && <span className='relative top-[2px] pl-2'><CircularProgress size="15px" sx={{color: '#0079ff'}} /></span>}
      {completed && <Checkbox size="small"  checked={true} checkedIcon={<span className='transform scale-75'
        sx={{
          fontSize: '12px', 
          position: 'relative',
          
          '& .MuiStep-root .Mui-completed': {
            padding: '20px'
          }
        }} 
      ><CheckedCheckbox /></span>}
      />}
      {!active && !completed && (
        <span
          className='text-xs text-gray-500 bg-gray-200 rounded-full w-5 h-5 flex items-center justify-center relative'
          style={{
            backgroundColor: theme === 'light' ? '#ACBFDF' : '#495D80',
            color: theme === 'light' ? '#fff' : '#fff',
          }}
        >{index + 1}</span>
      )}
    </>
    
  );
}

const QontoConnector = styled(StepConnector)(({ theme }: { theme: string }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    height: 2,
    border: 'none',
    backgroundColor: theme === 'light' ? '#ACBFDF' : '#495D80',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%',
      backgroundColor: theme === 'light' ? '#ACBFDF' : '#495D80',
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: '#0079ff',
      width: 0,
      zIndex: 2,
      transition: 'width 0.9s ease-in-out',
    },
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}::after`]: {
    width: '100%',
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}::after`]: {
    width: '100%',
  },
}));


const DataLoader: React.FC<DataLoaderProps> = ({ steps, currentStep, loading }) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const { theme, themedColors } = useContext(ThemeContext)!;

  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState<number | 'auto'>('auto');
  const [paddingTop, setPaddingTop] = useState<number>(20);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading) {
      if (containerRef.current) {
        const elementHeight = containerRef.current.scrollHeight;
        setHeight(elementHeight);
        setPaddingTop(20);
      }
      setTimeout(() => {
        setHeight(0);
        setPaddingTop(0);
      }, 10);

      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      setIsVisible(true);
      setHeight('auto');
      setPaddingTop(20);
    }
  }, [loading]);

  if (!isVisible) return null;

 
  return (
    <Box 
      sx={{ 
        width: '100%', 
        paddingTop: `${paddingTop}px`,
        height: height === 'auto' ? '42px' : `${height}px`,
        overflow: 'hidden',
        opacity: loading ? 1 : 0,
        transition: 'height 1s ease-in-out, padding-top 1s ease-in-out, opacity 1s ease-in-out',
      }}
      ref={containerRef}
    >

      {
        !isMobile && (
          <Stepper 
            activeStep={currentStep}
            sx={{
              height: '20px',
              '& .MuiStep-root': {
                paddingLeft: '0px'
              },
              '& .MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer.Mui-completed': {
                paddingRight: '0px'
              },
            }}
            connector={<QontoConnector theme={theme} />}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={(props) => <StepIcon {...props} index={index} />}
                >
                  <span 
                    className='text-xs'
                    style={{
                      color: themedColors.content,
                    }}
                  >{label}</span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )
      }

      {
        isMobile && (
          <div className='w-[95%] relative'>
            <MobileStepper
              variant="progress"
              steps={steps.length + 1}
              position="static"
              activeStep={currentStep}
              sx={{
                '& .MuiMobileStepper-progress': {
                  width: '100%',  
                },
                '& .MuiMobileStepper-progress span': {
                  backgroundColor: theme === 'light' ? '#1976d2' : '#1976d2',
                },
              }}
            />
            <div className='absolute top-0 right-[-15px]'>
              <CircularProgress size="15px" sx={{color: '#0079ff'}} />
            </div>
           
          </div>
        )
      }
      
    </Box>
  );
};

export default DataLoader;


