import React, { useContext, useEffect, useRef } from "react";
import TableSelectDropdown from "../../UI/selection/TableSelectDropdown";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import EditTableDialog from "../../pages/reports/transactionPerformanceReport/components/EditTableDialog";
import { EditTable } from "../../../assets/icons/EditTable";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import { MenuItem } from "../../pages/reports/DeclineResponseReport/utils/config";
import FilterDropdown from "../../UI/filterDropdown/FilterDropdown";
import ChartSettingsButtonMobile from "../../UI/selection/UI/ChartSettingsButtonMobile";
import SelectedOptions from "../../pages/reports/DeclineResponseReport/components/SelectedOptions";
import MobileSelectDropdownDeclineReport from "../../molecules/MobileSelectDropdownDeclineReport/MobileSelectDropdownDeclineReport";
import TableDateTimePicker from "../../UI/DatePickerComponent/TableDateTimePicker";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import { DeclineResponseReport, DeclineResponseReportClonedData } from "../../../types/decline-response-report.types";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";
import { ChargebackSummaryReport } from "../../pages/reports/ChargebackSummaryReport/types/chargeback-summary.types";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarDeclineResponseReportProps {
  handleBlur: () => void;
  startDate: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  groupings: string[];
  setGroupings: (newValue: string[]) => void;
  names: string[];
  selectedGroupings: number;
  setTempCounter?(): number[];
  copyButton: boolean;
  freezeButton: boolean;
  showDelete: boolean;
  clonedReportId: string | number | null | undefined;
  setDeletePopup: (newValue: boolean) => void;
  passId: (id: string | number | null | undefined) => void;
  deletePopup: boolean;
  setFreezeButton: (newValue: boolean) => void;
  setCopyButton: (newValue: boolean) => void;
  clonedData: DeclineResponseReportClonedData;
  generateConfigRequestBody: () => object;
  generateClonedData(reportId: number): void;
  title: string;
  isEdit?: boolean;
  openEditTableDialog?: boolean;
  setOpenEditTableDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  columnList?: number[];
  setColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  actualColumnList?: number[];
  setActualColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  dialogApplyButton?: boolean;
  setDialogApplyButton?: React.Dispatch<React.SetStateAction<boolean>>;
  maxSelections?: number;
  startNumber?: number;
  setIsCollapsedByExandButton?: (value: boolean) => void;
  setIsManualExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  data?: DeclineResponseReport[] | ChargebackSummaryReport[];
  fieldsForCSV?: { [key: string]: string };
  disabledCSV?: boolean;
  namesEnum?: { [key: string]: string };
  isCSV?: boolean;
  transformDataToCSVFormat?: any;
  csvFilters?: string[];
  isCustomFilters?: boolean;
  label?: string;
  menuItems?: MenuItem[];
  handleSelectionChange: (newSelectedItems: {
    [key: string]: {
        [subItemId: string]: boolean;
    };
    }) => void;
  selectedItems: {
        [key: string]: {
            [subItemId: string]: boolean;
        };
    }
    setSelectedItems: React.Dispatch<React.SetStateAction<{
      [key: string]: {
          [subItemId: string]: boolean;
      };
  }>>
  handleApplyMobileSelect?: (groupings: string[], filters: {
    [key: string]: {
        [subItemId: string]: boolean;
    };
  }) => void
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  }
  reportName: ReportName;
    isSearch?: boolean;
    searchTerm?: string;
    setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
    handleSearch?: (isReset?: boolean) => void
}

const FilterBarDeclineResponseReport = ({
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  groupings,
  setGroupings,
  names,
  copyButton,
  freezeButton,
  showDelete,
  clonedReportId,
  setDeletePopup,
  passId,
  deletePopup,
  setFreezeButton,
  setCopyButton,
  clonedData,
  generateConfigRequestBody,
  generateClonedData,
  title,
  isEdit = false,
  openEditTableDialog,
  setOpenEditTableDialog,
  columnList,
  setColumnList,
  actualColumnList,
  setActualColumnList,
  dialogApplyButton,
  setDialogApplyButton,
  maxSelections,
  data,
  fieldsForCSV,
  disabledCSV,
  namesEnum,
  isCSV,
  transformDataToCSVFormat,
  csvFilters,
  isCustomFilters,
  label,
  menuItems,
  handleSelectionChange,
  selectedItems,
  setSelectedItems,
  handleApplyMobileSelect,
  setRefreshKey,
  refreshKey,
  rangeConfigs,
  reportName,
  isSearch = false,
  searchTerm,
  setSearchTerm,
  handleSearch,
}: FilterBarDeclineResponseReportProps) => {
  const { themedColors } = useContext(ThemeContext)!;
  const [ isOpenedFilterDropdown, setIsOpenedFilterDropdown ] = React.useState(false);
  const [ countSelectedOptions, setCountSelectedOptions ] = React.useState(0);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  
  const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpenedFilterDropdown(false);
      }
  };

  useEffect(() => {
    if (isOpenedFilterDropdown) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenedFilterDropdown]);

  const countSelectedItems = () => {
    let count = 0;
    for (const key in selectedItems) {
      count += Object.keys(selectedItems[key]).filter((k) => selectedItems[key][k]).length;
    }
    setCountSelectedOptions(count);
  }

  useEffect(() => {
    countSelectedItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [selectedItems])

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title} 
        </div>
        <TableDateTimePicker
          handleBlur={handleBlur}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          customRangeConfigs={rangeConfigs}
          reportName={reportName}
        />
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <div
          className={
            "hidden tablet-size:flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
          }
        >
          <TableSelectDropdown
            handleBlur={handleBlur}
            groupings={groupings}
            setGroupings={setGroupings}
            names={names}
            maxSelections={maxSelections}
            label={label}
            multiple={false}
            dateRange={dateRange}
          />
        {
            menuItems && menuItems.length > 0 && (
                <div className="hidden tablet-size:block relative bottom-[2px]">
                    <div  ref={buttonRef} onClick={() => setIsOpenedFilterDropdown((prev) => !prev)}>
                        <ChartSettingsButtonMobile numberOfSelectedOptions={countSelectedOptions} isSelected={countSelectedOptions > 0}  />
                    </div>

                    {
                        isOpenedFilterDropdown && (
                            <div ref={dropdownRef} className="absolute z-[10000]">
                                <FilterDropdown
                                    menuItems={menuItems}
                                    selectedItems={selectedItems}
                                    onSelectionChange={handleSelectionChange}
                                />
                            </div>
                        )
                    }
        
                    
                </div>

            )
        }
        </div>

        <div className="block tablet-size:hidden">
          <MobileSelectDropdownDeclineReport
            handleBlur={handleBlur}
            groupings={groupings}
            setGroupings={setGroupings}
            names={names}
            maxSelections={maxSelections}
            multiple={false}
            label={label}
            menuItems={menuItems}
            selectedItems={selectedItems}
            onSelectionChange={handleSelectionChange}
            setSelectedItems={setSelectedItems}
            handleApplyMobileSelect={handleApplyMobileSelect}
          />     
          
        </div>
        <div className="flex flex-wrap gap-3 items-end lala">
          {isEdit && setOpenEditTableDialog && (
            <button
              onClick={() => {
                setOpenEditTableDialog(!openEditTableDialog);
              }}
              className={
                "hidden big-mobile-size:flex tablet-size:flex border-2 h-10 w-44 rounded-3xl copy justify-center items-center  hover:!shadow-btnShadow dark:hover:!bg-odin-dark-blue-custom-blue-hover transition-all"
              }
            >
              <EditTable />
              <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10 }}>
                Edit the table
              </span>
            </button>
          )}
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            data={data}
            fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            namesEnum={namesEnum}
            disabledCSV={disabledCSV}
            isCSV={isCSV}
            transformDataToCSVFormat={transformDataToCSVFormat}
            groupings={csvFilters}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            isCustomFilters={isCustomFilters}
          />
        </div>

        {isEdit && setOpenEditTableDialog && (
          <button
            onClick={() => {
              setOpenEditTableDialog(!openEditTableDialog);
            }}
            className={
              "flex big-mobile-size:hidden border-2 h-10 w-44 rounded-3xl copy justify-center items-center self-end hover:!shadow-btnShadow"
            }
          >
            <EditTable />
            <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10, fontWeight: 500 }}>
              Edit the table
            </span>
          </button>
        )}

        {isEdit && (
          <EditTableDialog
            openEditTableDialog={openEditTableDialog ?? false}
            setOpenEditTableDialog={setOpenEditTableDialog ?? (() => {})}
            columnList={columnList ?? []}
            setColumnList={setColumnList ?? (() => {})}
            actualColumnList={actualColumnList ?? []}
            setActualColumnList={setActualColumnList ?? (() => {})}
            dialogApplyButton={dialogApplyButton ?? false}
            setDialogApplyButton={setDialogApplyButton ?? (() => {})}
          />
        )}  

      </div>

        {
            menuItems && menuItems.length > 0 && (
              <div className='hidden tablet-size:block'>
                <SelectedOptions
                    selectedItems={selectedItems}
                    menuItems={menuItems}
                    onSelectionChange={handleSelectionChange}
                />    
              </div>          
            )
        }

      
        {
            isSearch && (
              <div className="max-w-[440px] mt-6">
                <SearchByRow
                  searchTerm={searchTerm ?? ""}
                  setSearchTerm={setSearchTerm ?? (() => {})}
                  handleSearch={handleSearch ?? (() => [])}
                />
              </div>
            )
          }
      
    </>
  );
};

export default FilterBarDeclineResponseReport;
