import React, { useContext, useEffect, useState } from 'react';
import { Card } from '@tremor/react';
import './ProfileInfo.css';
import OdinForgeService from '../../../odinForgeService/OdinForgeService';
import SessionManager from '../../../odinForgeService/SessionManager';
import Utils from '../../../odinForgeService/Utils';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import Input from '../../UI/Input/Input';
import DefaultButton from '../../UI/buttons/DefaultButton';
import ProfileImageController from '../../organism/ProfileImageController/ProfileImageController';
import { useUserProfile } from '../../../hooks/useUserProfile';

interface ProfileInfo {
  name?: string;
  email?: string;
}

type PasswordField = 'oldPassword' | 'newPassword' | 'confirmNewPassword';

function ProfileInfo() {
  const userContext = useUserProfile();
  const { setEmail, email, avatar, name, setName, isSuperAdmin } = userContext;

  const [username, setUsername] = useState(name ?? '');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [saveEmail, setSaveEmail] = useState(true);
  const [savePassword, setSavePassword] = useState(false);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>({});
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const { themedColors } = useContext(ThemeContext)!;

  useEffect(() => {
    // @ts-ignore
    setProfileInfo({ name, email });
    setUsername(name ?? '');
  }, [name, email]);

  useEffect(() => {
    if (profileInfo['name'] !== username || profileInfo['email'] !== email) {
      setSaveEmail(false);
    } else {
      setSaveEmail(true);
    }
  }, [username, email, profileInfo]);

  useEffect(() => {
    if (oldPassword === '' || newPassword === '' || confirmNewPassword === '') {
      setSavePassword(true);
    } else {
      setSavePassword(false);
    }
  }, [oldPassword, newPassword, confirmNewPassword]);

  if (!email) {
    return null;
  }

  const handleSaveEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      Utils.instance().onFailure('Please enter a valid email address.');
      return;
    }

    const request = {
      name: username,
      email: email,
    };
    const response = OdinForgeService.instance().updateProfileInfo(request);
    response
      .then((result) => {
        SessionManager.instance().saveEmail(email);
        profileInfo['name'] = username;
        profileInfo['email'] = email;
        Utils.instance().onSuccess('Changes are saved.');
        setName(username);
        setEmail(email);
      })
      .catch(() => {
        Utils.instance().onFailure('There was an error');
      });
  };

  const handleCancelEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmail(profileInfo['email'] ?? '');
    setUsername(profileInfo['name'] ?? '');
  };

  const handleCancelPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  const handleSavePassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword === confirmNewPassword) {
      const request = {
        current_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmNewPassword,
      };
      const response = OdinForgeService.instance().updatePassword(request);
      response
        .then((result) => {
          if (result.data.status) {
            Utils.instance().onSuccess('Changes are saved.');
          } else {
            Utils.instance().onFailure(result.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          if ('current_password' in error.response.data.errors) {
            Utils.instance().onFailure('The Current Password is incorrect!');
          } else if ('new_password' in error.response.data.errors) {
            const errors = error.response.data.errors.new_password;
            errors.map((e: string) => {
              Utils.instance().onFailure(e);
            });
          } else {
            Utils.instance().onFailure('There was an error!');
          }
        });
    } else
      Utils.instance().onFailure(
        "The Passwords do not match"
      );
  };

  const handleTogglePassword = (field: PasswordField) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <Card className='h-full mt-3 p-4 tablet-size:p-6 ring-0 flex flex-col dark:shadow-none !shadow-none !border-none border-top rounded-none tablet-size:rounded-[12px] bg-white dark:bg-odin-dark-blue-dark'>
      <div
        style={{ color: themedColors.content }}
        className='font-bold text-xl '
      >
        Profile info
      </div>

      <Card
        className='ring-0 mt-6 p-0 border bg-white dark:bg-odin-dark-blue-dark'
        style={{ borderColor: themedColors.gray.light }}
      >


        <div className='ring-0 px-4 tablet-size:px-6  pt-4 tablet-size:pt-6  pb-1 tablet-size:pb-2 bg-white dark:bg-odin-dark-blue-dark'>
          <ProfileImageController />
        </div>

        <form onSubmit={handleSaveEmail} onReset={handleCancelEmail}>
          <div className='flex gap-6 p-4 tablet-size:p-6 flex-wrap'>
            <div className='flex-1 min-w-[200px] mobile-size:min-w-[250px] tablet-size:min-w-[300px] w-full bg-white dark:bg-odin-dark-blue-dark'>
              <Input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                type='text'
                label={'Username'}
                name={'username'}
                placeholder={'Enter Username'}
                id='userName'
              />
            </div>
            <div className='flex-1 min-w-[200px] mobile-size:min-w-[250px] tablet-size:min-w-[300px] w-full'>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type='email'
                label={'Email'}
                name={'Email'}
                placeholder={'Enter Email'}
                id='email'
              />
            </div>
          </div>
          <div className='ml-5 mt-2 mb-5 flex gap-4 flex-wrap '>
            <DefaultButton
              disabled={saveEmail}
              type='submit'
              label='Save'
              className='h-10'
            />
            <DefaultButton
              disabled={saveEmail}
              type='reset'
              label='Cancel'
              variant='outlined'
              className='h-10'
            />
          </div>
        </form>
      </Card>
      {
        isSuperAdmin && (
          <Card
            className='ring-0 mt-6 p-0 border bg-white dark:bg-odin-dark-blue-dark'
            style={{ borderColor: themedColors.gray.light }}
          >
            <form onSubmit={handleSavePassword} onReset={handleCancelPassword}>
              <div className='flex gap-6 p-4 tablet-size:p-6 flex-wrap'>
                <div className='flex-1 min-w-[200px] mobile-size:min-w-[250px] tablet-size:min-w-[300px] w-full'>
                  <Input
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    type={showPassword.oldPassword ? 'text' : 'password'}
                    label={'Current Password'}
                    name={'Current Password'}
                    placeholder={'Enter your Password'}
                    showPassword={showPassword.oldPassword}
                    handleTogglePassword={() => handleTogglePassword('oldPassword')}
                    isPassword={true}
                  />
                </div>
                <div className='flex-1 min-w-[200px] mobile-size:min-w-[250px] tablet-size:min-w-[300px] w-full'>
                  <Input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    type={showPassword.newPassword ? 'text' : 'password'}
                    label={'New Password'}
                    name={'New Password'}
                    placeholder={'Enter your Password'}
                    showPassword={showPassword.newPassword}
                    handleTogglePassword={() => handleTogglePassword('newPassword')}
                    isPassword={true}
                  />
                </div>
                <div className='relative  flex-1 min-w-[200px] mobile-size:min-w-[250px] tablet-size:min-w-[300px] w-full'>
                  <Input
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    type={showPassword.confirmNewPassword ? 'text' : 'password'}
                    label={'Confirm New Password'}
                    name={'Confirm New Password'}
                    placeholder={'Enter your Password'}
                    showPassword={showPassword.confirmNewPassword}
                    handleTogglePassword={() =>
                      handleTogglePassword('confirmNewPassword')
                    }
                    isPassword={true}
                  />
                </div>
              </div>

              <div className='ml-5 mt-2 mb-5 flex gap-4 flex-wrap '>
                <DefaultButton
                  disabled={savePassword}
                  type='submit'
                  label='Change Password'
                  className='h-10'
                />
                <DefaultButton
                  disabled={savePassword}
                  type='reset'
                  label='Cancel'
                  variant='outlined'
                  className='h-10'
                />
              </div>
            </form>
          </Card>
        )
      }
    </Card>
  );
}

export default ProfileInfo;
