import React, { useContext } from 'react';
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import { ThemeContext } from '../../../../../context/ThemeContext/ThemeContext';
import Tooltip from '@mui/material/Tooltip';
import SortedArrowAscending from '../../../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../../../assets/icons/SortedArrowDescending';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import ServerPaginationComponent from '../../../../molecules/PaginationComponent/ServerPaginationComponent';
import PaginationState from '../types/pagination.types';
import DefaultTableMessage from '../../../../organism/DefaultTableMessage/DefaultTableMessage';
import { BinRoutingReportData, CustomColumnMeta } from '../types/bin-routing-performace-report.types';
import { tableColumns } from '../utils/config';
import highlightMatch from '../../../../../helpers/highlightMatch';

interface BinRoutingPerformaceReportTableProps {
  data: BinRoutingReportData[];
  setTransformedTableDataCSV: React.Dispatch<React.SetStateAction<BinRoutingReportData[]>>
  setTransformedNamesEnumCSV: React.Dispatch<React.SetStateAction<{
    [key: string]: string;
  }>>
  onPageChange: (newPageIndex: number) => void
  onPageSizeChange: (newPageSize: number) => void
  paginationState: PaginationState;
  searchTerm: string;
}

const BinRoutingPerformaceReportTable = ({
  data,
  setTransformedTableDataCSV,
  setTransformedNamesEnumCSV,
  onPageChange,
  onPageSizeChange,
  paginationState,
  searchTerm,
}: BinRoutingPerformaceReportTableProps) => {
  const filteredData = React.useMemo(() => {
    setTransformedTableDataCSV(data);
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { themedColors, theme } = useContext(ThemeContext)!;

  const isMobile = useMediaQuery('(max-width: 1023px)')

  const table = useReactTable({
    columns: tableColumns,
    data: filteredData,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: paginationState.totalPages,
    rowCount: paginationState.totalItems,
    manualPagination: true,
    state: {
      pagination: {
        pageIndex: paginationState.currentPage,
        pageSize: paginationState.pageSize,
      }
    },
    // @ts-ignore
    onPaginationChange: ({ pageIndex, pageSize } : {pageIndex: number, pageSize: number}) => {
      onPageChange(pageIndex);
      onPageSizeChange(pageSize);
    },
  });

  const getCanNextPage = () => {
    return paginationState.totalPages > 0 && paginationState.totalPages >  paginationState.pageIndex;
  }

  const getCanPreviousPage = () => {
    return paginationState.totalPages > 0 && paginationState.pageIndex > 1;
  }

  const getLastPage = () => {
    return paginationState.totalPages;
  }
  
  return (
    <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
        {
          filteredData.length > 0 ? (
            <>
            <ScrollContainer
              className='scroll-container'
              hideScrollbars={false}
              mouseScroll={{ rubberBand: false }}
              style={{ cursor: 'default', borderRadius: !isMobile ? 8 : 0}}
            >
              <table
              className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
              style={{
                minWidth: 500,
              }}
            >
              <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]'>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          const isLastInGroup = (header.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                          const isFontBold = (header.column.columnDef.meta as CustomColumnMeta)?.isFontBold;
                          const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';
                          const isAlignTextRight = (header.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
    
                          return (
                            <th key={header.id} colSpan={header.colSpan} 
                              style={{
                                borderRight: isLastInGroup ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                                borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: themedColors.gray.bluish,
                                fontWeight: isFontBold ? '600' : '500',
                              }}
                              className={`${headerBackground}`}
                            >
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                                className={`!text-xs font-inherit text-left ${
                                  header.column.getCanSort() ? 'cursor-pointer' : ''
                                } 
                                  !h-[56px] !py-0 !px-2 flex justify-start items-center`}
                                  style={{
                                    justifyContent: isAlignTextRight ? 'right' : 'left',
                                    fontSize: '12px',
                                    lineHeight: '14.52px',
                                  }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <span className='ml-2'><SortedArrowAscending /></span>,
                                  desc: <span className='ml-2'><SortedArrowDescending /></span>,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    )
                  )
                })}
              </thead>
    
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} style={{ backgroundColor: 'inherit' }} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                      {row.getVisibleCells().map((cell) => {
                        const isLastInGroup = (cell.column.columnDef.meta as CustomColumnMeta)?.isLastInGroup;
                        const isAlignTextRight = (cell.column.columnDef.meta as CustomColumnMeta)?.isAlignTextRight;
                        const isSearch = (cell.column.columnDef.meta as CustomColumnMeta)?.isSearch;


                        function getIsTooltop(cell: Cell<BinRoutingReportData, unknown>) {
                          const cellWidth = cell.column.getSize()
                          const charCount = Math.floor(cellWidth! / 8);

                          return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                        }

                        const cellValue: string = cell?.getValue() !== null ? (cell?.getValue() as string | number)?.toString(): '';                            

                        return (
                          <td
                            key={cell.id}
                            id={cell.id}
                            className='!text-xs !h-[42px] !py-0 !px-2 !font-normal'
                            style={{
                              borderBottom: row.getIsExpanded()
                                ? ''
                                :  theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                              color: themedColors.content,
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)' : undefined,
                              textAlign: isAlignTextRight ? 'right' : 'left'
                            }}
                          >
                            <Tooltip
                              classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                              title={getIsTooltop(cell) as string}
                              placement="left"
                              arrow
                            >
                                <span className='' style={{fontSize: '12px',lineHeight: '16.94px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {
                                  isSearch ? highlightMatch(cellValue, searchTerm, theme) : flexRender(cell.column.columnDef.cell,cell.getContext())
                                }
                              </span>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </ScrollContainer>

          <ServerPaginationComponent
            firstPage={() => table.setPageIndex(0)}
            previousPage={() => table.previousPage()}
            getCanPreviousPage={() => getCanPreviousPage()}
            getCanNextPage={() => getCanNextPage()}
            nextPage={() => table.nextPage()}
            lastPage={() => table.setPageIndex(table.getPageCount() - 1)}
            getPageCount={() => table.getPageCount()}
            pageIndex={paginationState.pageIndex}
            setPageIndex={(pageIndex) => onPageChange(pageIndex)}
            setPageSize={(pageSize) => onPageSizeChange(pageSize)}
            pageSize={paginationState.pageSize}
            getRowCount={() => paginationState.totalItems}
            getLastPage={getLastPage}
          />
          </>
          
          ) : (
            <DefaultTableMessage
              state={'noData'}
            />
          )
        }
      
    </div>
  )
}

export default BinRoutingPerformaceReportTable