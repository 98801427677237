import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";
import getSymbolFromCurrency from 'currency-symbol-map';
import { getFontColor } from '../../../../../util';
import { additionalRowsInterface } from '../types/sales-pace-report.types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid',
    'Merchant Category',
    'Campaign Category',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid': 'c1',
    'Merchant Category': 'mid_category',
    'Campaign Category': 'campaign_category_name',
};

export const transformNames = [
  'currency',
  'geo',
  'affiliate_name',
  'mid_name',
  'funnel_name',
  'c1',
  'mid_category',
  'campaign_category_name'
];

export const fields = {
    "State": "state",
    "Sales": "total_sales",
    "Prior Period Sales": "total_24_sales",
    "Revenue": "total_revenue",
    "Prior Period Rev": "total_24_revenue",
    "AOV": "total_aov",
    "Prior Period AOV": "total_24_aov",
    "Revenue Change %": "s1_24_percentage",
} as const;

export const firstRowData = [
    { id: 2, name: 'Sales', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 3, name: 'Prior Period Sales', fontSize: "12px", width: "150px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 4, name: 'Revenue', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 5, name: 'Prior Period Rev', fontSize: "12px", width: "150px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 6, name: 'AOV', fontSize: "12px", width: "120px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 7, name: 'Prior Period AOV', fontSize: "12px", width: "150px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
    { id: 9, name: 'Revenue Change %', fontSize: "12px", width: "150px", padding: "8px 8px", isTips: false, tips: "", hasSortField: true, align: 'flex-end' },
];

export const fieldsForCSV = {
    "Sales": "total_sales",
    "Prior Period Sales": "total_24_sales",
    "Revenue": "total_revenue",
    "Prior Period Rev": "total_24_revenue",
    "AOV": "total_aov",
    "Prior Period AOV": "total_24_aov",
    "Revenue Change %": "s1_24_percentage",
};

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];

export const isSuitableDateRangeForRefreshList = [
    "Today",
    "Last 7 days",
    "This Week",
    "This Month",
    "This Year",
]

export const options = [
  { value: 'all', label: 'All' },
  { value: 'sales', label: 'Sales' },
  { value: 'subscriptions', label: 'Subscriptions' },
];

export const additionalRows: additionalRowsInterface[] = [
  { value: 's1', label: 'Step 1', type: 'common' },
  { value: 's2', label: 'Step 2', type: 'common' },
  { value: 'subscriptions', label: 'Subscriptions', type: 'common' },
  { value: 'total', label: 'Total', type: 'total'  },
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
    1: { startDate: dayjs.tz().startOf('D'), endDate: dayjs.tz() },
    2: { startDate: dayjs.tz().startOf('D').subtract(1, "d"), endDate: dayjs.tz().endOf('D').subtract(1, "d") },
    3: { startDate: dayjs.tz().subtract(7, "d"), endDate: dayjs.tz() },
    4: { startDate: dayjs.tz().subtract(30, "d"), endDate: dayjs.tz() },
    5: { startDate: dayjs.tz().startOf('w'), endDate: dayjs.tz() },
    6: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz() },
    7: { startDate: dayjs.tz().startOf('y'), endDate: dayjs.tz() },
    8: { startDate: dayjs.tz().startOf('w').subtract(1, "w"), endDate: dayjs.tz().startOf('w').subtract(1, "s") },
    9: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
    10: { startDate: dayjs.tz().startOf('y').subtract(1, "y"), endDate: dayjs.tz().startOf('y').subtract(1, "s") }
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
    return {
      1: { startDate: dayjs.tz().startOf('day').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      2: { startDate: dayjs.tz().startOf('day').subtract(1, "day").tz(timezone), endDate: dayjs.tz().endOf('day').subtract(1, "day").tz(timezone) },
      3: { startDate: dayjs.tz().subtract(7, "d").tz(timezone), endDate: dayjs.tz().tz(timezone) },
      4: { startDate: dayjs.tz().subtract(30, "d").tz(timezone), endDate: dayjs.tz().tz(timezone) },
      5: { startDate: dayjs.tz().startOf('week').tz(timezone), endDate: dayjs.tz().tz(timezone) }, 
      6: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      7: { startDate: dayjs.tz().startOf('year').tz(timezone), endDate: dayjs.tz().tz(timezone) },
      8: { startDate: dayjs.tz().startOf('week').subtract(1, "week").tz(timezone), endDate: dayjs.tz().startOf('week').subtract(1, "second").tz(timezone) },
      9: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
      10: { startDate: dayjs.tz().startOf('year').subtract(1, "year").tz(timezone), endDate: dayjs.tz().startOf('year').subtract(1, "second").tz(timezone) }
    };
};

export function getColumns(
  themedColors: any,
  searchTerm: string,
  theme: 'light' | 'dark' = 'light',
  groupingsCount?: number,
  step?: 's1' | 's2' | 'total' | 'subscriptions',
  additionalRows?: additionalRowsInterface[]
): ColumnDef<any>[] {

  return [
    {
      accessorKey: 'name',
      header: () => <>Groupings</>,
      cell: ({ row, getValue }) => {
        let rowName = formatRowName(row.original.name, row.original.stats.currency, Number(row.depth))

        if (additionalRows && additionalRows.length > 0 && additionalRows.findIndex((row) => row.value === step) !== 0) {
          rowName = '';
        }

        return (
          <div
            style={{
              paddingLeft: `${row.depth * 2}rem`,
            }}
            className='flex gap-1 items-center justify-between w-full'
          >
            <div className="flex gap-1 items-center flex-nowrap">
              {row.getCanExpand() && additionalRows && additionalRows.length > 0 && additionalRows.findIndex((row) => row.value === step) === 0 ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <Icon icon={ChevronDownIcon} style={{ color: themedColors.content }} className="cursor-pointer" />
                  ) : (
                    <Icon icon={ChevronRightIcon} style={{ color: themedColors.content }} className="cursor-pointer" />
                  )}
                </button>
              ) : (
                <span style={{ width: 16 }} />
              )}
              <div className='flex justify-between w-full'>
                <span
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{
                    maxWidth: `${
                      348 + (Number(groupingsCount)) * 8 - (row.depth + 1) * 32
                    }px`,
                  }}
                >
                  <span 
                        className='overflow-hidden text-ellipsis whitespace-nowrap'
                      >
                        <span className={hasFlag(rowName)?'pr-3 text-center':'text-center hidden'}>
                        {
                          hasFlag(rowName) ? <ReactCountryFlag countryCode={rowName} svg style={{ width: '18px', height: '16px', }} /> : null
                        }
                        </span>
                        {highlightMatch(rowName, searchTerm, theme)}
                  </span>
                </span>
                
              </div>
            </div>
            <span>
              {step === 's2' ? 'Step 2' : step === 'total' ? 'Total' : step === 'subscriptions' ? 'Subs' : 'Step 1'}
            </span>
          </div>
        );
      },
      meta: {
        isLastInGroup: true,
        isPinned: true,
        isGroupingColumn: true,
      },
      filterFn: 'includesStringSensitive',
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_sales;
        } else if (step === 's2') {
          return row.original.stats.s2_sales;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_sales;
        } else if (step === 's1') {
          return row.original.stats.s1_sales;
        }

        return row.stats.s1_sales;
      },
      id: 'Sales',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Sales',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_24_sales;
        } else if (step === 's2') {
          return row.original.stats.s2_24_sales;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_24_sales;
        } else if (step === 's1') {
          return row.original.stats.s1_24_sales;
        }

        return row.stats.s1_24_sales;
      },
      id: 'Prior Period Sales',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Prior Period Sales',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_revenue;
        } else if (step === 's2') {
          return row.original.stats.s2_revenue;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_revenue;
        } else if (step === 's1') {
          return row.original.stats.s1_revenue;
        }

        return row.stats.s1_revenue;
      },
      id: 'Revenue',
      cell: ({ getValue, row }) => {
        const currency = row.original.stats.currency;
        const value = getValue();
        return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
      },
      header: 'Revenue',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_24_revenue;
        } else if (step === 's2') {
          return row.original.stats.s2_24_revenue;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_24_revenue;
        } else if (step === 's1') {
          return row.original.stats.s1_24_revenue;
        }

        return row.stats.s1_24_revenue;
      },
      id: 'Prior Period Rev',
      cell: ({ getValue, row }) => {
        const currency = row.original.stats.currency;
        const value = getValue();
        return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
      },
      header: 'Prior Period Rev',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_aov;
        } else if (step === 's2') {
          return row.original.stats.s2_aov;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_aov;
        } else if (step === 's1') {
          return row.original.stats.s1_aov;
        }

        return row.stats.s1_aov;
      },
      id: 'AOV',
      cell: ({ getValue, row }) => {
        const currency = row.original.stats.currency;
        const value = getValue();
        return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
      },
      header: 'AOV',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          return row.original.stats.total_24_aov;
        } else if (step === 's2') {
          return row.original.stats.s2_24_aov;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_24_aov;
        } else if (step === 's1') {
          return row.original.stats.s1_24_aov;
        }

        return row.stats.s1_24_aov;
      },
      id: 'Prior Period AOV',
      cell: ({ getValue, row }) => {
        const currency = row.original.stats.currency;
        const value = getValue();
        return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
      },
      header: 'Prior Period AOV',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => {
        if (step === 'total') {
          const { total_revenue, total_24_revenue } = row.original.stats;
          return (total_24_revenue && total_24_revenue !== 0)
            ? (total_revenue - total_24_revenue) / total_24_revenue * 100
            : 0;
        } else if (step === 's2') {
          return row.original.stats.s2_24_percentage;
        } else if (step === 'subscriptions') {
          return row.original.stats.rec_24_percentage;
        } else if (step === 's1') {
          return row.original.stats.s1_24_percentage;
        }

        return row.stats.s1_24_percentage;
      },
      id: 'Revenue Change %',
      cell: ({ getValue, row }) => {
        const value = getValue();
        const color = getFontColor(value as number);
        return <span style={{color}}>{Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%'}</span>
      },
      header: 'Revenue Change %',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
      },
      sortingFn: numericStringSortingFn,
    },
  ];
}