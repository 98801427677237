import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import SessionManager from '../../../odinForgeService/SessionManager';
import useTenantRedirect from '../../../hooks/useTenantRedirect';
import AccessDeniedIcon from '../../../assets/icons/AccessDeniedIcon/AccessDeniedIcon';

const GreetingsPage = () => {
    const [rememberMe] = useState(false);
    const userContext = useContext(UserContext)!;
    const { setPermissions, setIsSuperAdmin, setIsAdmin } = userContext;
    let navigate = useNavigate();

    let location = useLocation();
    const { redirectToAppropriateUrl } = useTenantRedirect({ isLoginPage: true });

    useEffect(() => {
        

        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        const accessToken = queryParams.get('accessToken');

        if (email && accessToken) {
            SessionManager.instance().saveToken(accessToken, rememberMe);
            SessionManager.instance().saveEmail(email)

            navigate('/dashboard');
            return;
        }

        const token = SessionManager.instance().getToken();

        if (token) {
            navigate('/dashboard');
            return;
        }
        
        redirectToAppropriateUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, navigate, setPermissions, setIsSuperAdmin, setIsAdmin]);
    
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-white dark:bg-odin-dark-blue-dark px-4">
            <div className='w-full flex justify-center max-w-[400px]'>
            <AccessDeniedIcon />
            </div>

            <p className="text-[32px] tablet-size:text-[48px] font-bold text-black mb-8 text-center mt-8 tablet-size:mt-10">Welcome!</p>
            <p className="text-[16px] tablet-size:text-[20px] text-gray-500 mb-8  text-center" >
                To access SWISS BI reports, please log in through your SWISS CRM page.
            </p>
        </div>
    );
}


export default GreetingsPage