export const DataRangesParamsList = {
    'Custom Range': 'custom',
    "Today": "today",
    "Yesterday": "yesterday",
    "Last 7 days": "last_7_days",
    "This Week": "this_week",
    "This Month": "this_month",
    "This Year": "this_year",
    "Last Week": "last_week",
    "Last Month": "last_month",
    "Last Year": "last_year",

    "Tomorrow": "tomorrow",
    "Next Week": "next_week",
    "Next Month": "next_month",

    "Last 30 days": "last_30_days",

    "2 Month Ago": "two_month_ago",
    "3 Month Ago": "three_month_ago",
}

export const dateRangeList = [
    "Custom Range",
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "This Week",
    "This Month",
    "This Year",
    "Last Week",
    "Last Month",
    "Last Year",
];