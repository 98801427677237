import { DuplicateCustomerReportData } from "../types/duplicate-customer-report.types";

export const transformDuplicateCustomerData = (
  data: DuplicateCustomerReportData[]
): DuplicateCustomerReportData[] => {
  return data.map(item => {
    const allDetailsAreYes = item.details.every(
      detail => detail.product_vertical_is_same === 1 || detail.product_vertical_is_same === "Yes"
    );

    const updatedItem: DuplicateCustomerReportData = {
      ...item,
      product_vertical_is_same: allDetailsAreYes ? 1 : 0
    };

    return updatedItem;
  });
};