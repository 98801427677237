import React, { useState } from "react";
import DefaultButton from "../../../../UI/buttons/DefaultButton";
import ChartSettingsButtonMobile from "../../../../UI/selection/UI/ChartSettingsButtonMobile";
import ReportName from "../../../../../odinForgeService/Enums/ReportName";
import ConversionReportMobileModal from "./ConversionReportMobileModal";
import ConversionSelection from "./ConversionSelection";
import { SelectChangeEvent } from "@mui/material";

interface ConversionSelectDropdownProps {
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  }[];
  getLineChartData: () => void;
  enableFiltersApplyButton: boolean;
  clearOptions: (() => void);
  isDisableNotNeeded?: boolean;
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
  isFraudToggle?: boolean;
  reportName?: string;
}

const ConversionSelectDropdown = ({
  filterConfig,
  getLineChartData,
  enableFiltersApplyButton,
  clearOptions,
  isDisableNotNeeded = false,
  selectedType,
  handleChange,
  toggleOptions,
  isFraudToggle,
  reportName,
}: ConversionSelectDropdownProps) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsMobileModalOpen(true);
    document.body.style.overflow = "hidden";

    const baseDashboardContainer = document.getElementById(
      "baseDashboardContainer"
    );
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "100";
    }

    const mainContainer = document.getElementsByClassName(
      "main-content"
    )[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "100";
    }
  };

  const handleCloseModal = () => {
    setIsMobileModalOpen(false);
    document.body.style.overflow = "visible";

    const baseDashboardContainer = document.getElementById(
      "baseDashboardContainer"
    );
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "1";
    }

    const mainContainer = document.getElementsByClassName(
      "main-content"
    )[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "";
    }
  };

  const numberOfSelectedOptions = filterConfig.slice(0, 4).reduce((acc, filter) => {
    return acc + filter.itemName.length;
  }, 0);

  return (
    <>
      <div
        className={
          "hidden tablet-size:flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
        }
      >
        {filterConfig.map((filter) => (
          <div key={filter.name} className="flex flex-col">
            <ConversionSelection
              label={filter.label}
              itemName={filter.itemName}
              handleSelectChange={filter.handleSelectChange}
              filterName={filter.options}
              multiple={true}
            />
          </div>
        ))}
        <div className={"flex mr-2 items-end"}>
            <span>
              <DefaultButton disabled={!isDisableNotNeeded && !enableFiltersApplyButton} type='button' label='Apply'  className="flex-grow !text-base max-w-[168px] !hidden tablet-size:!flex h-9" onClick={getLineChartData} />  
            </span>
        </div>
      </div>

      <div className="block tablet-size:hidden">
        <div onClick={handleOpenModal}>
          <ChartSettingsButtonMobile isSelected={enableFiltersApplyButton} numberOfSelectedOptions={numberOfSelectedOptions ? numberOfSelectedOptions : null} />
        </div>
         { isMobileModalOpen && reportName === ReportName.ConversionReport && (
            <ConversionReportMobileModal
              onClose={handleCloseModal}
              filterConfig={filterConfig}
              enableFiltersApplyButton={enableFiltersApplyButton}
              getLineChartData={getLineChartData}
              clearOptions={clearOptions}
              selectedType={selectedType}
              handleChange={handleChange}
              toggleOptions={toggleOptions}
              isFraudToggle={isFraudToggle}
            />
         )
         }
      </div>
    </>
  );
};

export default ConversionSelectDropdown;
