import React, { useContext, useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import {
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import EmptyCheckbox from "../../../../assets/icons/EmptyCheckbox";
import { ThemeContext } from "../../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../buttons/DefaultButton";
import FilledCheckbox from "../../../../assets/icons/FilledCheckbox";
import useMediaQuery from "../../../../hooks/useMediaQuery";

interface FullScreenChartMobileModalProps {
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: any) => void;
  }[];
  onClose: () => void;
  enableFiltersApplyButton: boolean;
  getLineChartData: () => void;
  clearOptions: () => void;
  isDisableNotNeeded?: boolean;
  isFraudToggle?: boolean;
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
}

const FullScreenChartMobileModal = ({
  filterConfig,
  onClose,
  enableFiltersApplyButton,
  getLineChartData,
  clearOptions,
  isDisableNotNeeded = false,
  isFraudToggle = false,
}: FullScreenChartMobileModalProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const originalGeoGroupings = useRef<string[]>([]);
  const originalAffiliateGroupings = useRef<string[]>([]);
  const originalOfferGroupings = useRef<string[]>([]);
  const originalShowDataOfGroupings = useRef<string[]>([]);

  const isMobile = useMediaQuery('(max-width: 1023px)')

  useEffect(() => {
    filterConfig.forEach((filter) => {
      if (filter.name === "geoName") {
        originalGeoGroupings.current = [...filter.itemName];
      } else if (filter.name === "affiliateName") {
        originalAffiliateGroupings.current = [...filter.itemName];
      } else if (filter.name === "offerName") {
        originalOfferGroupings.current = [...filter.itemName];
      } else if (filter.name === "displayFilters") {
        originalShowDataOfGroupings.current = [...filter.itemName];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClear = () => {
    clearOptions();
  };

  const handleApply = () => {
    getLineChartData();
    onClose();
  };

  const handleClose = () => {
    filterConfig.forEach((filter) => {
      if (filter.name === "geoName") {
        filter.handleSelectChange({ target: { value: originalGeoGroupings.current } });
      } else if (filter.name === "affiliateName") {
        filter.handleSelectChange({ target: { value: originalAffiliateGroupings.current } });
      } else if (filter.name === "offerName") {
        filter.handleSelectChange({ target: { value: originalOfferGroupings.current } });
      } else if (filter.name === "displayFilters") {
        filter.handleSelectChange({ target: { value: originalShowDataOfGroupings.current } });
      }
    }
    );
    
    onClose();
  }

  const handleTooltipToggle = () => {
    if (isMobile) {
        setTooltipOpen(prev => (prev === false ? true : false));
    }
  };
  // @ts-ignore
  const hasOptions = filterConfig.some(filter => filter.options?.length > 0);

  return (
    <>
      <div className="fixed inset-0 z-50 bg-white overflow-y-auto" style={{backgroundColor: themedColors.background_1}}>
        <div className="relative min-h-screen flex flex-col justify-start items-center">
          <div className="flex justify-between w-full border-b border-borderColor-semi-light-blue dark:border-odin-dark-blue-dark_2 p-4">
            <h2 style={{color: themedColors.content}} className="text-xl font-bold text-[#1A1F36]">Filters</h2>
            <button className="p-2 text-black" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>

          <div className={`flex flex-col w-full justify-between `}
              style={{
                minHeight: `calc(100vh - ${isFraudToggle ? 140 : 100}px)`,
              }}
          >
            <div className="flex flex-col gap-6 p-4">
              {
                !hasOptions ? (
                  <p className="text-center text-gray-500">No filters available.</p>
                ) : (
                  <>
                    {filterConfig.map((filter) => (
                      <FormControl component="fieldset" key={filter.name} sx={{ m: 1 }}>
                        <label className="text-base mb-2" style={{ color: themedColors.content }}>{filter.label}</label>
                        <FormGroup>
                          {filter.options?.map((option) => {
                            const isChecked = filter.itemName.includes(option);

                            const handleChange = () => {
                              const simulatedEvent = {
                                target: {
                                  value: isChecked
                                    ? filter.itemName.filter((item) => item !== option)
                                    : [...filter.itemName, option],
                                },
                              };
                              filter.handleSelectChange(simulatedEvent);
                            };

                            return (
                              <>
                                <FormControlLabel
                                  key={option}
                                  control={
                                    <Checkbox
                                      checked={isChecked}
                                      onChange={handleChange}
                                      name={option}
                                      icon={<EmptyCheckbox />}
                                      checkedIcon={<FilledCheckbox />}
                                    />
                                  }
                                  label={option}
                                  sx={{ "& .MuiTypography-body1": { fontSize: "14px !important", color: themedColors.content, fontWeight: isChecked ? 500 : 400, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '80vw'} }}
                                />
                              </>
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    ))}
                  </>
                )
              }

            </div>

            <div className="p-4 flex justify-between pt-4 w-full gap-2"  style={{borderTop: '1px solid', borderColor: themedColors.border}}>
              <DefaultButton disabled={false} type='button' label='Clear Filter' variant='outlined'  className="w-full h-9" onClick={handleClear} />
              <div  className="w-full h-9">
                  <span onClick={handleTooltipToggle}>
                    <DefaultButton disabled={isDisableNotNeeded && !enableFiltersApplyButton} type='button' label='Apply' className="w-full h-9" onClick={handleApply} />  
                  </span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default FullScreenChartMobileModal;
