import {toast} from "react-toastify";
import ReportName from "./Enums/ReportName";
import {createHash} from "crypto";
import { getCookie } from "../helpers/getCookie";
class Utils {
    private static instanceCache?: Utils;

    public static instance(): Utils {
        if (!this.instanceCache) {
            this.instanceCache = new this();
        }

        return this.instanceCache;
    }

    public onFailure(error: string) {
        return toast.error(error, {
            position: "bottom-right",
            icon: false,
            closeButton:false,
            style: {
                backgroundColor: getCookie('theme') === 'dark' ?  "#453838" : "#ffe5e3",
                color: getCookie('theme') === 'dark' ?  "#FDB5AE" : "#E74C3C",
            },
            autoClose:5000
        })
    }

    public onSuccess(message: string) {
        return toast.success(message, {
            position: "bottom-right",
            icon: false,
            closeButton:false,
            style: {
                backgroundColor: getCookie('theme') === 'dark' ?  "#14321B" : "#DEFCF5",
                color: getCookie('theme') === 'dark' ?  "#0EC539" : "#0F766E",
            },
            autoClose:5000
        })
    }

    public onWarning(message: string) {
        return toast.warning(message, {
            position: "bottom-right",
            icon: false,
            closeButton:false,
            style: {
                backgroundColor: getCookie('theme') === 'dark' ?  "#FFF4E5" : "#FFF4E5",
                color: getCookie('theme') === 'dark' ?  "#FF9500" : "#FF9500",
            },
            autoClose:5000
        })
    }

    public createHash(dataToHash: object, dataToSave: object, reportName: ReportName | string, cacheTime?: number) {
    }
    
    public checkHash(dataToHash: object, reportName: ReportName | string, cacheTime?: number) {
        const hashToCheck = btoa(JSON.stringify(dataToHash));
        const savedDataString: string | null = localStorage.getItem(reportName);

        let result = null;

        const cacheDuration = cacheTime || 60 * 1000;
    
        if (savedDataString) {
            const savedData = JSON.parse(savedDataString);
            const cachedData = savedData[hashToCheck];
            if (cachedData && (Date.now() - cachedData.timestamp < cacheDuration)) {
                result = cachedData.data;
            } else {
                if (cachedData) {
                    delete savedData[hashToCheck];
                    localStorage.setItem(reportName, JSON.stringify(savedData));
                }
            }
        }
        return result;
    }
}
export default Utils;