import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";

function getKeyByValue(obj: { [key: string]: string }, value: string) {
    return Object.keys(obj).find((key) => obj[key] === value);
  }

function transformDataToCSVFormat(
  data: any[],
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined
) {
  const result: { [key: string]: string | number }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = [...filters!] 

  function processItem(item: any, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string | number } = {
        number_of_all_attempts: item.number_of_all_attempts === null || item.number_of_all_attempts === undefined ? '---' : Number(item.number_of_all_attempts).toLocaleString(),
        percentage_successful_attempts_all: item.percentage_successful_attempts_all === null || item.percentage_successful_attempts_all === undefined ? '---' : Number(item.percentage_successful_attempts_all).toFixed(2) + '%',
        number_of_first_attempts: item.number_of_first_attempts === null || item.number_of_first_attempts === undefined ? '---' : Number(item.number_of_first_attempts).toLocaleString(),
        percentage_of_successful_first_attempts: item.percentage_of_successful_first_attempts === null || item.percentage_of_successful_first_attempts === undefined ? '---' : Number(item.percentage_of_successful_first_attempts).toFixed(2) + '%',
        difference_all_and_first_attempt_successes: item.difference_all_and_first_attempt_successes === null || item.difference_all_and_first_attempt_successes === undefined ? '---' : Number(item.difference_all_and_first_attempt_successes).toFixed(2) + '%',
        number_of_second_attempts: item.number_of_second_attempts === null || item.number_of_second_attempts === undefined ? '---' : Number(item.number_of_second_attempts).toLocaleString(),
        percentage_of_successful_second_attempts: item.percentage_of_successful_second_attempts === null || item.percentage_of_successful_second_attempts === undefined ? '---' : Number(item.percentage_of_successful_second_attempts).toFixed(2) + '%',
        difference_first_and_second_attempt_successes: item.difference_first_and_second_attempt_successes === null || item.difference_first_and_second_attempt_successes === undefined ? '---' : Number(item.difference_first_and_second_attempt_successes).toFixed(2) + '%',
        number_of_third_attempts: item.number_of_third_attempts === null || item.number_of_third_attempts === undefined ? '---' : Number(item.number_of_third_attempts).toLocaleString(),
        percentage_of_successful_third_attempts: item.percentage_of_successful_third_attempts === null || item.percentage_of_successful_third_attempts === undefined ? '---' : Number(item.percentage_of_successful_third_attempts).toFixed(2) + '%',
        difference_second_and_third_attempt_successes: item.difference_second_and_third_attempt_successes === null || item.difference_second_and_third_attempt_successes === undefined ? '---' : Number(item.difference_second_and_third_attempt_successes).toFixed(2) + '%',
        number_of_fourth_attempts: item.number_of_fourth_attempts === null || item.number_of_fourth_attempts === undefined ? '---' : Number(item.number_of_fourth_attempts).toLocaleString(),
        percentage_of_successful_fourth_attempts: item.percentage_of_successful_fourth_attempts === null || item.percentage_of_successful_fourth_attempts === undefined ? '---' : Number(item.percentage_of_successful_fourth_attempts).toFixed(2) + '%',
        difference_third_and_fourth_attempt_successes: item.difference_third_and_fourth_attempt_successes === null || item.difference_third_and_fourth_attempt_successes === undefined ? '---' : Number(item.difference_third_and_fourth_attempt_successes).toFixed(2) + '%',
        average_attempts_per_customer: item.average_attempts_per_customer === null || item.average_attempts_per_customer === undefined ? '---' : Number(item.average_attempts_per_customer).toFixed(2) + '%',
        percentage_customers_with_declines: item.percentage_customers_with_declines === null || item.percentage_customers_with_declines === undefined ? '---' : Number(item.percentage_customers_with_declines).toFixed(2) + '%',
        average_time_between_attempt_1_and_2: item.average_time_between_attempt_1_and_2 === null || item.average_time_between_attempt_1_and_2 === undefined ? '---' : Number(item.average_time_between_attempt_1_and_2).toFixed(2) + '%',
        average_time_between_attempt_2_and_3: item.average_time_between_attempt_2_and_3 === null || item.average_time_between_attempt_2_and_3 === undefined ? '---' : Number(item.average_time_between_attempt_2_and_3).toFixed(2) + '%',
        average_time_between_attempt_3_and_4: item.average_time_between_attempt_3_and_4 === null || item.average_time_between_attempt_3_and_4 === undefined ? '---' : Number(item.average_time_between_attempt_3_and_4).toFixed(2) + '%',
    };

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

    baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';;

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: any) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  Object.values(namesEnum).forEach((topLevelKey) => {
    // @ts-ignore
    if (data[topLevelKey] && Array.isArray(data[topLevelKey])) {
      // @ts-ignore
      data[topLevelKey].forEach((item) => processItem(item));
    }
  });

  const groupingFields = Array.from(groupingLevels).map((level) => {
    const foundKey = getKeyByValue(namesEnum, filtersList[level]);
    return foundKey ?? filtersList[level];
  });

  return result.map((item) => {
    const transformedItem: { [key: string]: string | number } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;
