import React, { createContext, useContext, ReactNode, useState } from 'react';
import tailwindConfig from '../../tailwind.config.link';
import resolveConfig from 'tailwindcss/resolveConfig';
import { DefaultColors } from 'tailwindcss/types/generated/colors';
import { getCookie } from '../../helpers/getCookie';
import { setCookie } from '../../helpers/setCookie';

export type Theme = 'light' | 'dark';

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
  tailwindColors: CustomColors;
  themedColors: Colors;
}

interface CustomColors extends DefaultColors {
  odin: {
    light: Colors;
    dark: Colors;
  };
}

export interface Colors {
  blue: {
    extra_light: string;
    extra_dark: string;
    light: string;
    medium: string;
    medium_2: string;
    very_light: string;
    dark: string;
    dark_2: string;
  };
  red: {
    light: string;
    bright: string;
  };
  green: {
    medium: string;
  };
  gray: {
    light: string;
    light_2: string;
    bluish_light: string;
    bluish: string;
    dark_bluish: string;
    dark_bluish_2: string;
    darkest_bluish: string;
    dark: string;
  };
  black: string;
  white: string;
  content: string;
  background_1: string;
  border: string;
  sceleton: string;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined
);

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const getInitialTheme = (): Theme => {
    const savedTheme = getCookie('theme');
    return savedTheme === 'dark' || savedTheme === 'light'
      ? savedTheme
      : 'light';
  };

  const [theme, setTheme] = useState<Theme>(getInitialTheme());
  const config = resolveConfig(tailwindConfig);
  const tailwindColors = config.theme.colors as CustomColors;
  const themedColors = tailwindColors.odin[theme];

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    setCookie('theme', theme === 'light' ? 'dark' : 'light');
  };



  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, tailwindColors, themedColors }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
