import { useNavigate, useLocation } from "react-router-dom";
import { TenantUrlsStaging } from "../odinForgeService/Enums/tenantUrls";
import { TenantUrls } from "../odinForgeService/Enums/tenantUrls";
import { getCookie } from "../helpers/getCookie";

interface UseTenantRedirectParams {
  isSuperAdmin?: boolean;
  isProfile?: boolean;
  isLoginPage?: boolean;
}

const useTenantRedirect = ({ isSuperAdmin = false, isProfile = false, isLoginPage = false }: UseTenantRedirectParams) => {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToAppropriateUrl = () => {
    const url = process.env.REACT_APP_BASE_URL;
    const queryParams = new URLSearchParams(location.search);
    const tenant = queryParams.get("tenant") || getCookie('tenant');
    const isSuperAdminFromQuery = queryParams.get("admin") || getCookie('admin');

    if (isSuperAdmin || isSuperAdminFromQuery === "1") {
        if (isProfile) {
            navigate(`/dashboard/profileInfo?${queryParams.toString()}`);
        } else {
            navigate(`/admin/login?${queryParams.toString()}`);
        }
    } else if (url === "https://reports-api.swisspriv.com") {
      if (tenant && tenant in TenantUrlsStaging) {
        if (isProfile) {
          window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging] + 'account';
        } else {
          window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging];
        }
      } else if (!isLoginPage) {
        window.location.href = "/";
      }
    } else {
      if (tenant && tenant in TenantUrls) {
        if (isProfile) {
          window.location.href = TenantUrls[tenant as keyof typeof TenantUrls] + 'account';
        } else {
          window.location.href = TenantUrls[tenant as keyof typeof TenantUrls];
        }
      } else if (!isLoginPage) {
        window.location.href = "/";
      }
    }
  };

  return { redirectToAppropriateUrl };
};

export default useTenantRedirect;

// i have this function here because we still have class based components that use this logic
export function handleTenantRedirect() {  
    const urlParams = new URLSearchParams(window.location.search);
    const tenant = urlParams.get("tenant") || getCookie('tenant');
    const isSuperAdmin = urlParams.get("admin") || getCookie('admin');
    const url = process.env.REACT_APP_BASE_URL;
  
    if (isSuperAdmin === "1") {
      window.location.href = `/admin/login?${urlParams.toString()}`;
    } else if (url === "https://reports-api.swisspriv.com") {
      if (tenant && tenant in TenantUrlsStaging) {
        window.location.href = TenantUrlsStaging[tenant as keyof typeof TenantUrlsStaging];
      } else {
        window.location.href = "/";
      }
    } else {
      if (tenant && tenant in TenantUrls) {
        window.location.href = TenantUrls[tenant as keyof typeof TenantUrls];
      } else {
        window.location.href = "/";
      }
    }
}


