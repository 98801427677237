import Cookies from 'js-cookie';

export const setCookie = (name: any, value: any) => {
    Cookies.set(name, value);
  };

export const setGlobalCookie = (name: string, value: string, expiresInDays = 30) => {
    Cookies.set(name, value, {
      expires: expiresInDays,
      path: '/',
      domain: '.swisscrm.com',
      secure: true,
      sameSite: 'None',
    });
};