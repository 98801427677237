import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChevronDownIcon } from "@heroicons/react/outline";
import EmptyCheckbox from "../../../assets/icons/EmptyCheckbox";
import { checkIcons } from "../../../types/enums/CheckIcons";
import React, { FC, useContext, useEffect, useState } from "react";
import SettingsButtonMobile from "./UI/SettingsButtonMobile";
import FullScreenMobileModal from "./UI/FullScreenMobileModal";
import Utils from "../../../odinForgeService/Utils";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import CheckedFilter from "../../../assets/icons/CheckedFilter";

interface selectInputProps {
  handleBlur: () => void;
  groupings: string[];
  setGroupings: (event: string[]) => void;
  names: string[];
  maxSelections?: number;
  label?: string;
  multiple?: boolean;
  dateRange?: number;
}
const TableSelectDropdown: FC<selectInputProps> = ({
  handleBlur,
  groupings,
  setGroupings,
  names,
  maxSelections,
  label = "Group in sequence",
  multiple = true,
}) => {
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const { theme, themedColors } = useContext(ThemeContext)!;
  const [selectedGroupings, setSelectedGroupings] = useState<string[]>(groupings);

  const handleSelectChange = (event: SelectChangeEvent<typeof groupings>) => {
    const {
      target: { value },
    } = event;

    let selectedValues = typeof value === 'string' ? value.split(',') : value;

    if (maxSelections !== undefined && selectedValues.length > maxSelections) {
      selectedValues = selectedValues.slice(0, maxSelections);
      Utils.instance().onFailure(`You can select up to ${maxSelections} options.`);
    }

    setSelectedGroupings(selectedValues);
    if (!multiple) {
      setGroupings(selectedValues);
    }
  };

  const handleOpenModal = () => { 
    setIsMobileModalOpen(true);
    document.body.style.overflow = "hidden";
  
    const baseDashboardContainer = document.getElementById("baseDashboardContainer");
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "100";
    }
  
    const mainContainer = document.getElementsByClassName("main-content")[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "100";
    }
  };

  const handleCloseModal = () => {
    setIsMobileModalOpen(false);
    document.body.style.overflow = "visible";
  
    const baseDashboardContainer = document.getElementById("baseDashboardContainer");
    if (baseDashboardContainer) {
      baseDashboardContainer.style.zIndex = "1";
    }
  
    const mainContainer = document.getElementsByClassName("main-content")[0] as HTMLElement;
    if (mainContainer) {
      mainContainer.style.zIndex = "";
    }
  };
  
  const onCloseHanler = () => {
    setGroupings(selectedGroupings);
  }

  useEffect(() => {
    setSelectedGroupings(groupings);
  }, [groupings]);

  return (
    <>
      <div className={"hidden tablet-size:flex flex-col relative top-[5px]"}>
        <label style={{ fontSize: "14px", color: themedColors.content }} className={"pl-3 "}>
          {label}
        </label>
        <FormControl sx={{ m: 1, width: 240, fontSize: "14px", marginTop: '4px' }}>
          <Select
            multiple={multiple}
            displayEmpty
            value={selectedGroupings}
            onClose={multiple ? onCloseHanler : undefined}
            onChange={handleSelectChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return (
                  <em style={{ fontSize: "12px", color: theme === 'light' ? '#677C9F' : '#677C9F' }}>Select from the list</em>
                );
              }

              return selected.join(", ");
            }}
            inputProps={{
              "aria-label": "Without label",
              MenuProps: {disableScrollLock: true}
            }}
            input={<OutlinedInput />}
            IconComponent={ChevronDownIcon}
            variant={"outlined"}
            style={{height: '40px !important'}}
            className={"!h-10 text-sm hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium"}
            sx={{
              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
                fontSize: "14px",
                lineHeight: "19.6px",
                fontWeight: 400,
              },
              "& .MuiSelect-icon, & .MuiSelect-iconOpen": {
                top: "10px",
                width: "20px",
                color: themedColors.content,
              },
              "& .MuiSelect-icon.MuiSelect-iconOpen": {
                width: "20px",
                color: "#0079FF",
              },
              "&.MuiOutlinedInput-root": { borderRadius: "6px" },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
              "& .MuiOutlinedInput-notchedOutline": { borderColor: theme === 'light' ? "#ACBFDF" : "#495D80"}
            }}
          >
            {names && names.length > 0 ? names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  "&.MuiMenuItem-root": {
                    padding: "0px 5px",
                  },
                  "&.MuiMenuItem-root .MuiTypography-root": {
                    backgroundColor: 'transparent !important',
                    fontSize: "14px !important",
                    fontWeight: selectedGroupings.indexOf(name) > -1 ? 500 : 400,
                    padding: 0
                  },
                  "&.MuiMenuItem-root:hover, &.Mui-selected": {
                    backgroundColor: 'transparent !important',
                    fontSize: "14px !important",
                  },
                }}
              >
                <Checkbox
                  sx={{ fontSize: "12px" }}
                  checked={selectedGroupings.indexOf(name) > -1}
                  icon={<EmptyCheckbox />}
                  checkedIcon={multiple ? checkIcons[selectedGroupings.indexOf(name)] : <CheckedFilter />}
                />
                <ListItemText primary={name} style={{ fontSize: "14px !important" }} />
              </MenuItem>
            ))
            : (
              <MenuItem disabled>
                  <ListItemText primary="No data available" sx={{
                      "& span": {
                          fontSize: '14px',
                      }
                  }} />
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>

      <div className="block tablet-size:hidden">
        <div onClick={handleOpenModal}>
          <SettingsButtonMobile
            isSelected={groupings.length === 0 ? false : true}
            numberOfSelectedOptions={
              groupings.length > 0 ? groupings.length : null
            }
          />
        </div>

        {isMobileModalOpen && (
          <FullScreenMobileModal
            onClose={handleCloseModal}
            handleSelectChange={handleSelectChange}
            groupings={groupings}
            setGroupings={setGroupings}
            handleBlur={handleBlur}
            names={names}
            maxSelections={maxSelections}
            multiple={multiple}
          />
        )}
      </div>
    </>
  );
};

export default TableSelectDropdown;
