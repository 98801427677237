import { v4 as uuidv4 } from 'uuid';
import { TransformedSalesPerformanceReportData, StatsInterface } from '../types/sales-performance-report.types';
import { transformNames } from './config';

export const prepareDataForTransform = (data: any) => {
  if (Object.keys(data).length === 0) {
    return {};
  }

  const transformObject = (obj: any, objName: any) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== 'stats') {
        if (typeof value === 'object' && !Array.isArray(value)) {
          transformed[key] = Object.entries(value)
            .sort((a: any, b: any) => (b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
            .map(([nestedKey, nestedValue]) => {
              if (nestedKey === '') {
                return transformObject(nestedValue, 'NaN');
              }
              return transformObject(nestedValue, nestedKey);
            });
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: any } = {};
  Object.entries(data).forEach(([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
    result[mainKey] = Object.entries(mainValue)
      .sort((a: any, b: any) => (b[1].stats?.initial_sales || 0) - (a[1].stats?.initial_sales || 0))
      .map(([nestedKey, nestedValue]) => {
        if (nestedKey === '') {
          return transformObject(nestedValue, 'NaN');
        }
        return transformObject(nestedValue, nestedKey);
      });
  });
  return result;
};

export function transformData(data: any): TransformedSalesPerformanceReportData[] | [] {
  const preparedData = prepareDataForTransform(data);
  function recursiveTransform(node: any): TransformedSalesPerformanceReportData[] {
    if (Array.isArray(node)) {
      const transformedArray = node.flatMap((item) => recursiveTransform(item));
      transformedArray.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));
      return transformedArray;
    } else if (typeof node === 'object' && node !== null) {
      const nameKey = transformNames.find((key) => key in node && typeof node[key] === 'string');

      if (nameKey) {
        const name = node.name ?? '---';
        const stats: StatsInterface = {
          name,
          clicks: node.clicks || null,
          clicks_into_orders: node.clicks_into_orders || null,
          partials: node.partials || null,
          partial_rate: node.partial_rate || null,
          partial_conv_rate: node.partial_conv_rate || null,
          conv_rate: node.conv_rate || null,
          sales: node.sales || null,
          upsell: node.upsell || null,
          take_rate: node.take_rate || null,
          declines: node.declines || null,
          approval: node.approval || null,
          currency: node.currency || null,
          new_subscription: node.new_subscription || null,
          new_subscription_percentage: node.new_subscription_percentage || null,
          one_time: node.one_time || null,
          one_time_percentage: node.one_time_percentage || null,
        };

        const child_data: TransformedSalesPerformanceReportData[] = [];

        for (const key in node) {
          if (transformNames.includes(key) && key !== nameKey) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
          } else if (typeof node[key] === 'object') {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
          }
        }

        child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

        return [
          {
            name,
            stats,
            child_data,
          },
        ];
      } else {
        const child_data: TransformedSalesPerformanceReportData[] = [];

        for (const key in node) {
          const child = recursiveTransform(node[key]);
          child_data.push(...child);
        }

        child_data.sort((a, b) => (b.stats.clicks || 0) - (a.stats.clicks || 0));

        return child_data;
      }
    }
    return [];
  }

  return recursiveTransform(preparedData);
}
