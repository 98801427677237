import React from "react";
import './TableLoading.css';

const TableLoading = () => {
    return (
        <div className="tableLoading-overlay">
            <div className="tableLoading-popup">
                <div className="tableLoadingBar-cover bg-odin-light-gray-light dark:bg-odin-dark-gray-dark_bluish_2">
                    <div className="tableLoading-bar "></div>
                </div>
            </div>
        </div>
    );
};

export default TableLoading;