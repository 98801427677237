import { ErrorsShownCustomerReportData, SubCategory } from "./errors-shown-customer.types";
import { ErrorsShownCustomerReport } from "./errors-shown-customer.types";

function transformData(
  input: ErrorsShownCustomerReportData, 
  categoryKey: string
): ErrorsShownCustomerReport[] {
  const dataForCategory = input[categoryKey] as Record<string,{ stats: Array<{ error_msg: string; errors: number }> }>;

  if (!dataForCategory) {
    return [];
  }

  const result = Object.keys(dataForCategory).map((key) => {
    const stats = dataForCategory[key].stats || [];

    const subCategoriesMap: { [reason: string]: SubCategory } = {};

    let totalCount = 0;

    stats.forEach(({ error_msg, errors }: { error_msg: string; errors: number }) => {
      totalCount += errors;

      if (!subCategoriesMap[error_msg]) {
        subCategoriesMap[error_msg] = {
          reason: error_msg,
          count: errors
        };
      } else {
        subCategoriesMap[error_msg].count += errors;
      }
    });

    const subCategories = Object.values(subCategoriesMap) as SubCategory[];

    const reportItem: ErrorsShownCustomerReport = {
      [categoryKey]: key,
      count: totalCount,
      subCategories
    };

    return reportItem;
  });

  return result;
}

export default transformData;
