
import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../../../css/EmptyReport.css';
import { SelectChangeEvent } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";
import '../../../../css/PaceReport.css';
import OdinForgeService from "../../../../odinForgeService/OdinForgeService";
import Utils from "../../../../odinForgeService/Utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import FilterBarChart from '../../../organism/filterbar/FilterBarChart';
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import FilterBarChartSceleton from '../../../organism/filterbar/sceletons/FilterBarChartSceleton';
import TableLoading from '../../../UI/loading/TableLoading';
import transformData from './transformData';
import LineChart from '../../../molecules/LineChart/LineChart';
import {checkDataForNaN} from "../../../../util";
import { dateRangeList, rangeConfigs } from './utils/config';
import { createHash } from '../../../../helpers/createHash';
import { UserContext } from '../../../../context/UserContext/UserContext';
import { LineChartClonedData, LineChartData, LineChartPrefixes } from './types/line-chart.types';
import { formatLocalISO } from '../../../../helpers/formatLocalISO';
import { DataRangesParamsList } from '../../../../types/enums/DataRangesParamsList';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
    clonedData: LineChartClonedData;
    passData: (data: LineChartClonedData) => void;
    passId: (id: string | number | null | undefined) => void;
    reportName: string
    reportType: 'salesPerformance' | 'salesPace'
    setRefreshKey: React.Dispatch<React.SetStateAction<number>>
    refreshKey: number
}

const SalesPerformanceReportLineChart: React.FC<Props> = ({
    clonedData,
    passData,
    passId,
    reportName,
    reportType,
    setRefreshKey,
    refreshKey
}: Props) => {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const { timezone: userTimezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
    const emptyArray: string[] = [];
    const [geoName, setGeoName] = useState<string[]>(clonedData?.geo_name ? clonedData.geo_name : []);
    const [affiliateName, setAffiliateName] = useState<string[]>(clonedData.affiliate_name ?? []);
    const [offerName, setOfferName] = useState<string[]>(clonedData.offer_name ?? []);
    const [startDate, setStartDate] = useState<string | Dayjs>(clonedData['start_date'] ? dayjs.tz(clonedData['start_date']) :dayjs.tz().startOf('day').subtract(1, "day").tz(userTimezone));
    const [endDate, setEndDate] = useState<string | Dayjs>(clonedData['end_date'] ? dayjs.tz(clonedData['end_date']) : dayjs.tz().startOf('day').tz(userTimezone));
    const [geoOptions, setGeoOptions] = useState<string[]>([]);
    const [affiliateOptions, setAffiliateOptions] = useState<string[]>([]);
    const [offerOptions, setOfferOptions] = useState<string[]>();
    const [previousData, setPreviousData] = useState<LineChartData[] | null>(null);
    const [currentData, setCurrentData] = useState<LineChartData[] | null>(null);
    const [dateRange, setDateRange] = useState<number>(clonedData.date_range ? clonedData.date_range : 0);
    const [dataKeys, setDataKeys] = useState<string[]>([]);
    const [datesApplyButton, setDatesApplyButton] = useState(true);
    const [enableFiltersApplyButton] = useState(true);
    const [datesPreviousStartDate, setDatesPreviousStartDate] = useState(startDate);
    const [datesPreviousEndDate, setDatesPreviousEndDate] = useState(endDate);
    const [copyButton, setCopyButton] = useState(!(clonedData));
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState(themedColors.gray.light);
    const [disableButton, setDisableButton] = useState(false);
    const [iconFillColor, setIconFillColor] = useState(themedColors.gray.bluish_light);
    const [componentCount] = useState<number>(0);
    const [clonedReportId] = useState(clonedData.id ? clonedData.id : 0);
    const [deletePopup, setDeletePopup] = useState(false);
    const [crmType, setCrmType] = useState('swiss');
    const [showDelete] = useState(clonedData.type === 'cloned' ? clonedData.type === 'cloned' : false);
    const [isDataFromBE, setIsDataFromBE] = useState(true);
    const [isFilterData, setIsFilterData] = useState(true);
    const [isLineChartData, setIsLineChartData] = useState(true);

    const isInitialLoad = useRef(true);
    const [ errorMessage, setErrorMessage ] = useState<string>('');

    const [legendGeoName, setLegendGeoName] = useState<string[]>(emptyArray);
    const [legendAffiliateName, setLegendAffiliateName] = useState<string[]>(emptyArray);
    const [legendOfferName, setLegendOfferName] = useState<string[]>(emptyArray);

    const [loaderKey, setLoaderKey] = useState(0);

    const createDataKeys = (geoNameFiltered?: string[], affiliateNameFiltered?: string[], offerNameFiltered?: string[]) => {
        const dataKeys: string[] = []
        geoNameFiltered?.forEach((value) => dataKeys.push(value))
        affiliateNameFiltered?.forEach((value) => dataKeys.push(value))
        offerNameFiltered?.forEach((value) => dataKeys.push(value))
        
        if (dataKeys.length === 0) {
            dataKeys.push('total')
        }

        return dataKeys
    }

    const saveFilters = async () => {
        await OdinForgeService.instance().postLineChartConfig(reportName,geoName, dateRange, affiliateName,offerName,true,clonedReportId, startDate, endDate);
    }

    const handleGeoSelectChange = (event: SelectChangeEvent<typeof geoName>) => {
        const { target: { value } } = event;
        let selectedGeos = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedGeos?.length > maxSelections) {
            selectedGeos = selectedGeos.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} geographies.`);
            return;
        }

        setGeoName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleAffiliateSelectChange = (event: SelectChangeEvent<typeof affiliateName>) => {
        const { target: { value } } = event;
        let selectedAffiliates = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedAffiliates?.length > maxSelections) {
            selectedAffiliates = selectedAffiliates.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} affiliates.`);
            return;
        }

        setAffiliateName(typeof value === 'string' ? value.split(',') : value);
    };

    const handleOfferSelectChange = (event: SelectChangeEvent<typeof offerName>) => {
        const { target: { value } } = event;
        let selectedOffers = typeof value === 'string' ? value.split(',') : value;
        const maxSelections = 5;

        if (selectedOffers?.length > maxSelections) {
            selectedOffers = selectedOffers.slice(0, maxSelections);
            Utils.instance().onFailure(`You can select up to ${maxSelections} offers.`);
            return;
        }

        setOfferName(typeof value === 'string' ? value.split(',') : value);
    };

    const getFilterData = async () => {
        const formattedStartDate = formatLocalISO((startDate as Dayjs).set('second', 0o0))

        const formattedEndDate = formatLocalISO((endDate as Dayjs).set('second', 0o0))

        const dataRangeParam = DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList]

        const fetchData = (type: 'salesPerformance' | 'salesPace') => {
            if (type === 'salesPerformance') {
                return OdinForgeService.instance().getSalesPerformanceGraphFilterData(formattedStartDate, formattedEndDate, userTimezone, tenantId, `${clonedReportId}-filters` , isSuperAdmin, superTenant, dataRangeParam);
            } else if (type === 'salesPace') {
                return OdinForgeService.instance().getSaleGraphFilterData(formattedStartDate, formattedEndDate, userTimezone, tenantId, `${clonedReportId}-filters`, isSuperAdmin, superTenant, dataRangeParam);
            }
        }

        try {
            setIsFilterData(true);
            const response = await fetchData(reportType)

            const responseData = response?.data;
            if (reportType === 'salesPerformance') {
                setGeoOptions(responseData.geo);
                setAffiliateOptions(responseData.affiliate_name);
                setOfferOptions(responseData.funnel_name);
                setDatesApplyButton(true);

                const geoNameFiltered = geoName ? geoName.filter((item) => responseData.geo.includes(item)) : []
                const affiliateNameFiltered = affiliateName ? affiliateName.filter((item) => responseData.affiliate_name.includes(item)) : []
                const offerNameFiltered = offerName ? offerName.filter((item) => responseData.funnel_name.includes(item)) : []


                setGeoName(geoNameFiltered);
                setAffiliateName(affiliateNameFiltered);
                setOfferName(offerNameFiltered);

                getLineChartData(geoNameFiltered, affiliateNameFiltered, offerNameFiltered)
            } else if (reportType === 'salesPace') {
                setGeoOptions(responseData.geo);
                setAffiliateOptions(responseData.affiliate);
                setOfferOptions(responseData.funnels);
                setDatesApplyButton(true);

                const geoNameFiltered = geoName ? geoName.filter((value) => responseData.geo.includes(value)) : [];
                const affiliateNameFiltered = affiliateName ? affiliateName.filter((value) => responseData.affiliate.includes(value)) : [];
                const offerNameFiltered = offerName ? offerName.filter((item) => responseData.funnels.includes(item)) : []

                setGeoName(geoNameFiltered);
                setAffiliateName(affiliateNameFiltered);
                setOfferName(offerNameFiltered);

                getLineChartData(geoNameFiltered, affiliateNameFiltered, offerNameFiltered)
            }

        } catch (error) {
            // @ts-ignore
            if (error?.code === 'ERR_CANCELED') {
                setLoaderKey(prevKey => prevKey + 1);
                console.log('Request canceled');
            } else {
                Utils.instance().onFailure("Error fetching graph data");
            }     
        }
         finally {
            setIsFilterData(false);
            setDatesPreviousEndDate(endDate);
            setDatesPreviousStartDate(startDate);

            if (clonedData.type === 'default') {
                await saveFilters()
            }

            
        }
    };

    const generateParams = (geoNameFiltered?: string[], affiliateNameFiltered?: string[], offerNameFiltered?: string[]) => {
        let lineChartParams = new URLSearchParams();

        lineChartParams.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
        lineChartParams.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 0o0)));

        lineChartParams.append('timezone', userTimezone)

        lineChartParams.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList]);

        if (reportType === 'salesPerformance') {
            if (geoNameFiltered === undefined) {
                geoNameFiltered = geoName
            }
            geoNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}geo[${index}]`, value);
            });

            if (affiliateNameFiltered === undefined) {
                affiliateNameFiltered = affiliateName
            }
            affiliateNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}affiliate_name[${index}]`, value);
            });

            if (offerNameFiltered === undefined) {
                offerNameFiltered = offerName
            }
            offerNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPerformance}funnel_name[${index}]`, value);
            });

            if (isSuperAdmin && superTenant) {
                superTenant?.toString() && lineChartParams.append('tenant', superTenant?.toString())
            } else {
                tenantId?.toString() && lineChartParams.append('tenant', tenantId?.toString())
            }

        } else if (reportType === 'salesPace') {
            if (geoNameFiltered === undefined) {
                geoNameFiltered = geoName
            }
            geoNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}geo[${index}]`, value);
            });

            if (affiliateNameFiltered === undefined) {
                affiliateNameFiltered = affiliateName
            }
            affiliateNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}affiliate_name[${index}]`, value);
            });

            if (offerNameFiltered === undefined) {
                offerNameFiltered = offerName
            }
            offerNameFiltered?.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes.salesPace}funnel_name[${index}]`, value);
            });

            if (isSuperAdmin && superTenant) {
                superTenant?.toString() && lineChartParams.append('tenant', superTenant?.toString())
            } else {
                tenantId?.toString() && lineChartParams.append('tenant', tenantId?.toString())
            }
        }

        const displayFilter = []
        if (geoNameFiltered === undefined) {
            geoNameFiltered = geoName
        }
        if (geoNameFiltered?.length) {
            displayFilter.push('geo')
        }
        if (affiliateNameFiltered === undefined) {
            affiliateNameFiltered = affiliateName
        }
        if (affiliateNameFiltered?.length) {
            displayFilter.push('affiliate_name')
        }
        if (offerNameFiltered === undefined) {
            offerNameFiltered = offerName
        }
        if (offerNameFiltered?.length) {
            displayFilter.push('funnel_name')
        }

        if (checkIfAtLeastOneFilterSelected(geoNameFiltered, affiliateNameFiltered, offerNameFiltered)) {
            displayFilter.forEach((value, index) => {
                lineChartParams.append(`${LineChartPrefixes[reportType]}display_filter[${index}]`, value);
            });
        } else {
            lineChartParams.append(`${LineChartPrefixes[reportType]}display_filter[0]`, 'total');
        }

        
        return lineChartParams;
    };
    

    function checkIfAtLeastOneFilterSelected(geoNameFiltered?: string[], affiliateNameFiltered?: string[], offerNameFiltered?: string[]) {
        return (geoNameFiltered?.length !== 0 || affiliateNameFiltered?.length !== 0 || offerNameFiltered?.length !== 0)
    }

    useEffect(() => {
        const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
        const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
        const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

        const valuesChanged = (
            geoName?.sort().toString() !== clonedData.geo_name?.sort().toString() ||
            affiliateName?.sort().toString() !== clonedData.affiliate_name?.sort().toString() ||
            offerName?.sort().toString() !== clonedData.offer_name?.sort().toString() ||
            dateRange !== clonedData.date_range
            || startDateStr !== clonedStartDateStr
            || endDateStr !== clonedEndDateStr
        );

        if (valuesChanged) {
            setIconFillColor(themedColors.gray.bluish_light);
            setDisableButton(false);
            setButtonBackgroundColor(themedColors.gray.light);
        } else {
            setIconFillColor(themedColors.white);
            setButtonBackgroundColor(themedColors.blue.medium);
            setDisableButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoName, affiliateName, offerName, dateRange, startDate, endDate]);

    function generateDataToHash() {
        const dataToHash = {
            geos: geoName,
            affiliateName: affiliateName,
            offerName: offerName,
            startDate: startDate,
            endDate: endDate,
            crmType: crmType
        };
        return dataToHash;
    }

    useEffect(() => {
        if (!isInitialLoad.current) {
            setGeoName(emptyArray);
            setAffiliateName(emptyArray);
            setOfferName(emptyArray);
            getFilterData();
            setGeoOptions(emptyArray);
            setAffiliateOptions(emptyArray);
            setOfferOptions(emptyArray);
            
        } else {
            isInitialLoad.current = false;
            setGeoName(emptyArray);
            setAffiliateName(emptyArray);
            setOfferName(emptyArray);
            setGeoOptions(emptyArray);
            setAffiliateOptions(emptyArray);
            setOfferOptions(emptyArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [superTenant]);

    const getLineChartData = async (geoNameFiltered?: string[] | undefined, affiliateNameFiltered?: string[] | undefined, offerNameFiltered?: string[] | undefined) => {
        try {
            setDataKeys(createDataKeys(geoNameFiltered || geoName, affiliateNameFiltered || affiliateName, offerNameFiltered || offerName))

            setLegendGeoName(geoNameFiltered || geoName);
            setLegendAffiliateName(affiliateNameFiltered || affiliateName);
            setLegendOfferName(offerNameFiltered || offerName);

                if (reportType === 'salesPerformance') {
                    setIsLineChartData(true);
                    const lineChartParams = generateParams(geoNameFiltered || undefined, affiliateNameFiltered || undefined, offerNameFiltered || undefined);
                    const cachedData = Utils.instance().checkHash(generateDataToHash(), reportName);

                    if (cachedData) {
                        if (cachedData.current.length === 0 && cachedData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(cachedData, isToday);
                        
                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }
                    } else {
                        const response = await OdinForgeService.instance().getSalesPerformanceGraphData(lineChartParams, clonedReportId);
                        const responseData = response.data;
                        if (responseData.current.length === 0 && responseData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(responseData, isToday);
                        if  (!transformedData) {
                            return
                        }

                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }

                        createHash(generateDataToHash, response.data, reportName, response.cacheTime)
                    }
                } else if (reportType === 'salesPace') {
                    setIsLineChartData(true);
                    const lineChartParams = generateParams(geoNameFiltered || undefined, affiliateNameFiltered || undefined, offerNameFiltered || undefined);
                    const cachedData = Utils.instance().checkHash(generateDataToHash(), reportName);

                    if (cachedData) {
                        if (cachedData.current.length === 0 && cachedData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(cachedData, isToday);
                        if  (!transformedData) {
                            return
                        }

                        if (transformedData.previous) {
                            setPreviousData(transformedData.previous);
                        }
                        if (transformedData.current) {
                            setCurrentData(transformedData.current);
                        }
                        if (crmType === 'swiss') {
                            checkDataForNaN(cachedData);
                        }
                    } else {
                        const response = await OdinForgeService.instance().getSaleGraphData(lineChartParams, clonedReportId);
                        const responseData = response.data;
                        if (responseData.current.length === 0 && responseData.previous.length === 0) {
                            setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                            setIsDataFromBE(false);
                            setIsLineChartData(false);
                            return;
                        }
                        const isToday = dayjs().format('YYYY-MM-DD') === (endDate as Dayjs).format('YYYY-MM-DD');
                        const transformedData: {previous: LineChartData[], current: LineChartData[]} | null = transformData(responseData, isToday);

                        if (transformedData!.previous) {
                            setPreviousData(transformedData!.previous);
                        }
                        if (transformedData!.current) {
                            setCurrentData(transformedData!.current);
                        }

                        createHash(generateDataToHash, response.data, reportName, response.cacheTime)

                        if (crmType === 'swiss') {
                            checkDataForNaN(responseData);
                        }
                    }
                }
                setIsLineChartData(false);
                setIsDataFromBE(true);
        } catch (error) {
            console.log('Error fetching line chart data', error);
            // @ts-ignore
            if (error?.code === 'ERR_CANCELED') {
                setLoaderKey(prevKey => prevKey + 1);
                console.log('Request canceled');
                
            } else {
                setIsDataFromBE(false);
                setErrorMessage("Sorry, no data found. Please select a different date range or filters.");
                Utils.instance().onFailure("There was an error fetching the graph data.");
            }
        } 
    };

    useEffect(() => {
        if (
            (startDate as Dayjs).format('YYYY-MM-DD') === (datesPreviousStartDate as Dayjs).format('YYYY-MM-DD') &&
            (endDate  as Dayjs).format('YYYY-MM-DD') === (datesPreviousEndDate  as Dayjs).format('YYYY-MM-DD')
        ) {
            setDatesApplyButton(true);
        } else {
            setDatesApplyButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    useEffect(() => {
        if (componentCount === 0 && dateRange === 0) {
            getFilterData().then(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
       if (dateRange && dateRange !== 0) {
           getFilterData()
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);



    const filterConfig = [
        { label: "Filter by Geo", name: "geoName", options: geoOptions, itemName: geoName, handleSelectChange: handleGeoSelectChange },
        { label: "Filter by Affiliate", name: "affiliateName", options: affiliateOptions, itemName: affiliateName, handleSelectChange: handleAffiliateSelectChange },
        { label: "Filter by Offer", name: "offerName", options: offerOptions, itemName: offerName, handleSelectChange: handleOfferSelectChange },
    ];

    const clearOptions = () => {
        setGeoName(emptyArray);
        setAffiliateName(emptyArray);
        setOfferName(emptyArray);
    }

    const getTooltipLabel = (reportType: string) => {
        if (reportType === 'salesPerformance') {
            return 'Clicks';
        } else if (reportType === 'salesPace') {
            return 'Sales';
        }
    }


    return (
        <div className='p-4 tablet-size:p-0'>
            {isFilterData && (
                <FilterBarChartSceleton title={reportName} />
            )}

            {!isFilterData && (
                <>
                    <FilterBarChart
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            generateMappedNames={geoName}
                            affiliateName={affiliateName}
                            offerName={offerName}
                            getLineChartData={getLineChartData}
                            passData={passData}
                            clonedReportId={clonedReportId}
                            passId={passId}
                            setDeletePopup={setDeletePopup}
                            deletePopup={deletePopup}
                            showDelete={showDelete}
                            datesApplyButton={datesApplyButton}
                            getFilterData={getFilterData}
                            copyButton={copyButton}
                            reportName={reportName}
                            disableButton={disableButton}
                            buttonBackgroundColor={buttonBackgroundColor}
                            setButtonBackgroundColor={setButtonBackgroundColor}
                            iconFillColor={iconFillColor}
                            setCopyButton={setCopyButton}
                            setIconFillColor={setIconFillColor}
                            setDisableButton={setDisableButton}
                            crmType={crmType}
                            setCrmType={setCrmType}
                            clonedData={clonedData}
                            enableFiltersApplyButton={enableFiltersApplyButton}
                            filterConfig={filterConfig}
                            title={reportName}
                            clearOptions={clearOptions}
                            isToggle={false}
                            setRefreshKey={setRefreshKey}
                            refreshKey={refreshKey}
                            rangeConfigs={rangeConfigs}
                    />  
                </>
            )}

            {(isFilterData || isLineChartData) && (
                <div key={loaderKey} style={{ background: themedColors.background_1, borderColor: `${theme === 'dark' ? 'rgb(39, 51, 73)' : ''}` }} className={'mt-4 border rounded-lg h-96 w-full justify-center items-center flex tablet-size:-ml-2'}>
                    <TableLoading />
                </div>
            )}

            {!isFilterData && !isLineChartData && (
                <div className={'flex flex-row w-full pt-4 tablet-size:-ml-2'}>
                    <LineChart
                        currentData={currentData}
                        previousData={previousData}
                        dataKeys={dataKeys}
                        startDate={startDate}
                        endDate={endDate}
                        isDataFromBE={isDataFromBE}
                        geoName={legendGeoName}
                        affiliateName={legendAffiliateName}
                        offerName={legendOfferName}
                        errorMessage={errorMessage}
                        tooltipLabel={getTooltipLabel(reportType)}
                    />
                </div>
            )}
        </div>
    );
};

export default SalesPerformanceReportLineChart;
