import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import LightModeIcon from "../../../assets/icons/LightModeIcon";
import DarkModeIcon from "../../../assets/icons/DarkModeIcon";

const ThemeToggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)!;

  const sliderPosition =
    theme === "light" ? { right: "44px" } : { right: "2px" };

  return (
    <button
      className={`relative cursor-pointer flex justify-between items-center gap-4 h-8 py-[2px] pl-2 pr-3 rounded-[50px] ${
        theme === "dark" ? "bg-odin-dark-gray-dark" : "bg-odin-light-gray-light"
      }`}
      onClick={toggleTheme}
      aria-label="Toggle dark/light mode"
    >
      <LightModeIcon />
      <DarkModeIcon />
      <div
        className={`z-[2] w-7 h-7 rounded-full absolute transition-all duration-300 ${
          theme === "dark" ? "bg-odin-dark-blue-dark" : "bg-odin-light-white"
        }`}
        style={sliderPosition}
      />
    </button>
  );
};

export default ThemeToggle;
