import React, { useContext } from "react";
import TableDateTimePicker from "../../UI/DatePickerComponent/TableDateTimePicker";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import FilterTabs from "../../UI/FilterTabs/FilterTabs";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";
import { FulfillmentSummaryReportClonedData, FulfillmentSummaryReportData, TabKey } from "../../pages/reports/FulfillmentSummaryReport/utils/types/fulfillment-summary-report.types";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarFulfillmentSummaryReportProps {
  handleBlur: () => void;
  startDate: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  selectedGroupings: number;
  setTempCounter?(): number[];
  copyButton: boolean;
  freezeButton: boolean;
  showDelete: boolean;
  clonedReportId: string | number | null | undefined;
  setDeletePopup: (newValue: boolean) => void;
  passId: (id: string | number | null | undefined) => void;
  deletePopup: boolean;
  setFreezeButton: (newValue: boolean) => void;
  setCopyButton: (newValue: boolean) => void;
  clonedData: FulfillmentSummaryReportClonedData;
  generateConfigRequestBody: () => object;
  generateClonedData(reportId: number): void;
  title: string;
  isEdit?: boolean;
  openEditTableDialog?: boolean;
  setOpenEditTableDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  columnList?: number[];
  setColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  actualColumnList?: number[];
  setActualColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  dialogApplyButton?: boolean;
  setDialogApplyButton?: React.Dispatch<React.SetStateAction<boolean>>;
  startNumber?: number;
  setIsCollapsedByExandButton?: (value: boolean) => void;
  setIsManualExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  data?: FulfillmentSummaryReportData[];
  fieldsForCSV?: { [key: string]: string };
  disabledCSV?: boolean;
  namesEnum?: { [key: string]: string };
  isCSV?: boolean;
  transformDataToCSVFormat?: (data: FulfillmentSummaryReportData[], namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
    [key: string]: string | number;
  }[];
  csvFilters?: string[];
  isCustomFilters?: boolean;
  tabs: TabKey[];
  activeTab: string;
  handleTabClick: (tab: TabKey) => void;
  isDisableNotNeeded?: boolean;
  isNotNeedFetchData?: boolean;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  }
  isSearch?: boolean;
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
  handleSearch?: (isReset?: boolean) => void
}

const FilterBarFulfillmentSummaryReport = ({
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  copyButton,
  freezeButton,
  showDelete,
  clonedReportId,
  setDeletePopup,
  passId,
  deletePopup,
  setFreezeButton,
  setCopyButton,
  clonedData,
  generateConfigRequestBody,
  generateClonedData,
  title,
  isEdit = false,
  openEditTableDialog,
  setOpenEditTableDialog,
  columnList,
  setColumnList,
  actualColumnList,
  setActualColumnList,
  dialogApplyButton,
  setDialogApplyButton,
  data,
  fieldsForCSV,
  disabledCSV,
  namesEnum,
  isCSV,
  transformDataToCSVFormat,
  csvFilters,
  isCustomFilters,
  tabs,
  activeTab,
  handleTabClick,
  isDisableNotNeeded,
  isNotNeedFetchData,
  setRefreshKey,
  refreshKey,
  rangeConfigs,
  isSearch,
  searchTerm,
  setSearchTerm,
  handleSearch,
}: FilterBarFulfillmentSummaryReportProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title}
        </div>
        <TableDateTimePicker
          handleBlur={handleBlur}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isNotNeedFetchData={isNotNeedFetchData}
          customRangeConfigs={rangeConfigs}
        />
      </div>

      <div>
        <FilterTabs tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <div className="max-w-[440px] mt-6">
            {
                isSearch && (
                    <SearchByRow
                    searchTerm={searchTerm ?? ""}
                    setSearchTerm={setSearchTerm ?? (() => {})}
                    handleSearch={handleSearch ?? (() => [])}
                    />
                )
            }
        </div>
        <div className="flex flex-wrap gap-3 items-end lala">
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            data={data}
            fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            namesEnum={namesEnum}
            disabledCSV={disabledCSV}
            isCSV={isCSV}
            transformDataToCSVFormat={transformDataToCSVFormat}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            groupings={csvFilters}
            isCustomFilters={isCustomFilters}
          />
        </div>
      </div>
    </>
  );
};

export default FilterBarFulfillmentSummaryReport;
