import { CardProcessingReportData, RestType, TransformedData } from "./types/card-processing-report.types";

export const transformDataToCSVFormat = (
    data: CardProcessingReportData[], 
    namesEnum: Partial<Record<keyof RestType, string>>, 
    fieldsForCSV: { [key: string]: string; }
): TransformedData[] => {
    const formatNumber = (value: number | null | undefined): string => {
        return value === null || value === undefined ? '---' : Number(value).toLocaleString();
    };

    const transformedData = data.map((item): TransformedData => {
        const { name, attempts, success, failures, ...rest } = item;

        const successRate = attempts ? ((success / attempts) * 100).toFixed(2) : '0';
        const formattedSuccessRate = `${Number(successRate).toLocaleString()}%`;

        const groupingKey = fieldsForCSV['grouping'] || 'Grouping';

        const renamedRest = Object.keys(rest).reduce((acc: { [key: string]: RestType[keyof RestType] | string }, key) => {
            const typedKey = key as keyof RestType;
            const newKey = namesEnum[typedKey] || key;

            // @ts-ignore
            acc[newKey] = rest[typedKey];
            return acc;
        }, {} as { [key: string]: RestType[keyof RestType] | string });

        const newItem: TransformedData = {
            [groupingKey]: name,
            [namesEnum['attempts'] || 'Attempts']: formatNumber(attempts),
            [namesEnum['success'] || 'Success']: formatNumber(success),
            [namesEnum['failures'] || 'Failures']: formatNumber(failures),
            [namesEnum['success_rate'] || 'Success Rate']: formattedSuccessRate,
            ...renamedRest
        };

        return newItem;
    });

    return transformedData;
};
