import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import MuiTooltip from '@mui/material/Tooltip';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { lineColors } from '../../../types/enums/LineColors';
import capitalizeSpecificWords from '../../../helpers/capitalizeSpecificWords';
import replaceSpacesWithUnderscores from '../../../helpers/replaceSpacesWithUnderscores';

interface LineChartSideBarProps {
    geoName?: string[];
    affiliateName?: string[];
    offerName?: string[];
    visibleLines: { [key: string]: boolean };
    toggleLineVisibility: (line: string) => void;
}

const LineChartSideBar = ({ visibleLines, toggleLineVisibility, geoName = [], affiliateName = [], offerName = [] }: LineChartSideBarProps) => {
    const { themedColors } = useContext(ThemeContext)!;

    const renderCategory = (category: string[], categoryName: string, startIndex: number, shouldDisplay: boolean) => (
        <>
            {category.length > 0 && (
                <>
                    <div className='flex flex-col gap-2 m-2' style={{ display: shouldDisplay ? 'flex' : 'none' }}>
                        {category.map((line, index) => (
                            <FormControlLabel
                                key={line}
                                labelPlacement="start"
                                className='flex justify-between gap-4 !ml-0'
                                control={
                                    <Checkbox
                                        checked={visibleLines[replaceSpacesWithUnderscores(line)]}
                                        onChange={() => toggleLineVisibility(replaceSpacesWithUnderscores(line))}
                                        icon={
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.481 9.80385 14.1962C9.51897 13.9113 9.29439 13.5719 9.14351 13.1984C8.99263 12.8248 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88001M1 1L23 23M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.6819 3.96914 7.65661 6.06 6.06001L17.94 17.94ZM9.9 4.24001C10.5883 4.07889 11.2931 3.99835 12 4.00001C19 4.00001 23 12 23 12C22.393 13.1356 21.6691 14.2047 20.84 15.19L9.9 4.24001Z" stroke="#677C9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                        checkedIcon={
                                            <svg width="24" height="24" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z" stroke="#677C9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12 11.9999C13.6568 11.9999 15 10.6567 15 8.99988C15 7.34302 13.6568 5.99988 12 5.99988C10.3431 5.99988 8.99999 7.34302 8.99999 8.99988C8.99999 10.6567 10.3431 11.9999 12 11.9999Z" stroke="#677C9F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        }
                                    />
                                }
                                label={
                                    <MuiTooltip title={capitalizeSpecificWords(line)}>
                                        <span style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: 14,
                                            color: visibleLines[line] ? lineColors[`color${startIndex + index + 1}`] : '#000',
                                            fontWeight: visibleLines[line] ? 400 : 400,
                                            maxWidth: '100%',
                                        }}>
                                            <div className='flex gap-2'>
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faCircle}
                                                        className='text-2xl p-[5px] w-2'
                                                        style={{
                                                            color: lineColors[`color${startIndex + index + 1}`],
                                                        }}
                                                    />
                                                </div>
                                                <div className={'capitalize flex items-center text-sm text-nowrap overflow-hidden'}>
                                                    <span 
                                                        style={{ 
                                                            overflow: 'hidden', 
                                                            textOverflow: 'ellipsis', 
                                                            whiteSpace: 'nowrap', 
                                                            fontSize: 14,
                                                        }}
                                                        className={'dark:text-odin-dark-content text-odin-light-content'}
                                                    >
                                                        {capitalizeSpecificWords(line)}
                                                    </span>
                                                </div>
                                            </div>
                                        </span>
                                    </MuiTooltip>
                                }
                                sx={{ 
                                    '& .MuiFormControlLabel-label': { minWidth: '100px' } 
                                }}
                            />
                        ))}
                    </div>
                </>
            )}
        </>
    );

    const hasAffiliateNames = affiliateName.length > 0;
    const hasOfferNames = offerName.length > 0;
    const hasGeoNames = geoName.length > 0;

    return (
        <div className='!rounded-lg !shadow-none !overflow-auto !min-w-[190px] !w-full !h-auto border border-solid dark:bg-odin-dark-blue-special' style={{ borderColor: themedColors.gray.light_2 }}>
            <div className='m-2'>
                {
                    <>
                        {renderCategory(['total'], 'Total', 0, !hasGeoNames && !hasAffiliateNames && !hasOfferNames)}
                        {renderCategory(geoName, 'Geo Names', 0, hasGeoNames && !hasAffiliateNames && !hasOfferNames)}
                        {renderCategory(affiliateName, 'Affiliate Names', geoName.length, hasAffiliateNames && !hasOfferNames)}
                        {renderCategory(offerName, 'Offer Names', geoName.length + affiliateName.length, hasOfferNames)}
                    </>
            }
            </div>
        </div>
    );
};

export default LineChartSideBar;
