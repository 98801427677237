import React, { useContext } from "react";
import TableDateTimePicker from "../../UI/DatePickerComponent/TableDateTimePicker";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import EditTableDialog from "../../pages/reports/transactionPerformanceReport/components/EditTableDialog";
import { EditTable } from "../../../assets/icons/EditTable";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import FilterTabs from "../../UI/FilterTabs/FilterTabs";
import CustomToggleButtonGroup from "../../UI/CustomToggleButtonGroup/CustomToggleButtonGroup";
import ConversionSelectDropdown from "../../pages/reports/conversationReport/components/ConversionSelectDropdown";
import { SelectChangeEvent } from "@mui/material";
import { ConversationReportClonedData, ConversationReportData, TabKey } from "../../pages/reports/conversationReport/utils/types/conversation-report.types";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarConversationReportProps {
  handleBlur: () => void;
  startDate: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
  dateRange: number;
  setDateRange: (newValue: number) => void;
  selectedGroupings: number;
  setTempCounter?(): number[];
  copyButton: boolean;
  freezeButton: boolean;
  showDelete: boolean;
  clonedReportId: string | number | null | undefined;
  setDeletePopup: (newValue: boolean) => void;
  passId: (id: string | number | null | undefined) => void;
  deletePopup: boolean;
  setFreezeButton: (newValue: boolean) => void;
  setCopyButton: (newValue: boolean) => void;
  clonedData: ConversationReportClonedData;
  generateConfigRequestBody: () => object;
  generateClonedData(reportId: number): void;
  title: string;
  isEdit?: boolean;
  openEditTableDialog?: boolean;
  setOpenEditTableDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  columnList?: number[];
  setColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  actualColumnList?: number[];
  setActualColumnList?: React.Dispatch<React.SetStateAction<number[]>>;
  dialogApplyButton?: boolean;
  setDialogApplyButton?: React.Dispatch<React.SetStateAction<boolean>>;
  startNumber?: number;
  setIsCollapsedByExandButton?: (value: boolean) => void;
  setIsManualExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  setIsAutoExpanding?: React.Dispatch<React.SetStateAction<boolean>>
  data?: ConversationReportData[];
  fieldsForCSV?: { [key: string]: string };
  disabledCSV?: boolean;
  namesEnum?: { [key: string]: string };
  isCSV?: boolean;
  transformDataToCSVFormat?: (data: ConversationReportData[], namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
    [key: string]: string | number;
  }[];
  csvFilters?: string[]
  isCustomFilters?: boolean
  clearOptions?: () => void;
  filterConfig: {
    label: string;
    name: string;
    options: string[] | undefined;
    itemName: string[];
    handleSelectChange: (event: SelectChangeEvent<string[]>) => void;
  }[];
  enableFiltersApplyButton?: boolean;
  getFilterData: () => void;
  tabs: TabKey[];
  activeTab: string;
  handleTabClick: (tab: TabKey) => void;
  selectedType?: string;
  handleChange?: (newValue: string) => void;
  toggleOptions?: { value: string; label: string }[];
  isFraudToggle?: boolean;
  isDisableNotNeeded?: boolean;
  isNotNeedFetchData?: boolean;
  setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
  refreshKey?: number
  rangeConfigs?: {
    [key: string]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
    [key: number]: {
        startDate: dayjs.Dayjs;
        endDate: dayjs.Dayjs;
    };
  }
  isSearch?: boolean;
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
  handleSearch?: (isReset?: boolean) => void
}

const FilterBarConversationReport = ({
  handleBlur,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  dateRange,
  setDateRange,
  copyButton,
  freezeButton,
  showDelete,
  clonedReportId,
  setDeletePopup,
  passId,
  deletePopup,
  setFreezeButton,
  setCopyButton,
  clonedData,
  generateConfigRequestBody,
  generateClonedData,
  title,
  isEdit = false,
  openEditTableDialog,
  setOpenEditTableDialog,
  columnList,
  setColumnList,
  actualColumnList,
  setActualColumnList,
  dialogApplyButton,
  setDialogApplyButton,
  data,
  fieldsForCSV,
  disabledCSV,
  namesEnum,
  isCSV,
  transformDataToCSVFormat,
  csvFilters,
  isCustomFilters,
  clearOptions,
  filterConfig,
  enableFiltersApplyButton,
  getFilterData,
  tabs,
  activeTab,
  handleTabClick,
  selectedType,
  handleChange,
  toggleOptions,
  isFraudToggle,
  isDisableNotNeeded,
  isNotNeedFetchData,
  setRefreshKey,
  refreshKey,
  rangeConfigs,
  isSearch,
  searchTerm,
  setSearchTerm,
  handleSearch,
}: FilterBarConversationReportProps) => {
  const { themedColors } = useContext(ThemeContext)!;

  return (
    <>
      <div
        className={
          "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
        }
      >
        <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
          {title}
        </div>
        <TableDateTimePicker
          handleBlur={getFilterData}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          isNotNeedFetchData={isNotNeedFetchData}
          customRangeConfigs={rangeConfigs}
        />
      </div>

      <div>
        <FilterTabs tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
      </div>

      <div
        className={
          "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
        }
      >
        <div
          className={
            "flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
          }
        >
          <div className="hidden tablet-size:block tablet-size:ml-2">
            {
              selectedType && handleChange && toggleOptions && (
                <CustomToggleButtonGroup
                  selectedValue={selectedType}
                  onChange={handleChange}
                  options={toggleOptions}
                />
              )
            }
          </div>
          <div className="tablet-size:ml-4">
            {
                clearOptions && (
                    <ConversionSelectDropdown
                    filterConfig={filterConfig}
                    getLineChartData={handleBlur}
                    enableFiltersApplyButton={enableFiltersApplyButton ? enableFiltersApplyButton : false}
                    clearOptions={clearOptions}
                    isDisableNotNeeded={isDisableNotNeeded}
                    selectedType={selectedType}
                    handleChange={handleChange}
                    toggleOptions={toggleOptions}
                    isFraudToggle={isFraudToggle}
                    reportName={title}
                  />
                )
            }
          </div>
            
        </div>
        <div className="flex flex-wrap gap-3 items-end lala">
          {isEdit && setOpenEditTableDialog && (
            <button
              onClick={() => {
                setOpenEditTableDialog(!openEditTableDialog);
              }}
              className={
                "hidden big-mobile-size:flex tablet-size:flex border-2 h-10 w-44 rounded-3xl copy justify-center items-center  hover:!shadow-btnShadow dark:hover:!bg-odin-dark-blue-custom-blue-hover transition-all"
              }
            >
              <EditTable />
              <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10 }}>
                Edit the table
              </span>
            </button>
          )}
          <CustomActionButtons
            copyButton={copyButton}
            freezeButton={freezeButton}
            showDelete={showDelete}
            clonedReportId={clonedReportId}
            setDeletePopup={setDeletePopup}
            passId={passId}
            deletePopup={deletePopup}
            setFreezeButton={setFreezeButton}
            setCopyButton={setCopyButton}
            clonedData={clonedData}
            configRequestBody={generateConfigRequestBody()}
            generateClonedData={generateClonedData}
            data={data}
            fieldsForCSV={fieldsForCSV}
            reportName={title}
            startDate={startDate}
            endDate={endDate}
            namesEnum={namesEnum}
            disabledCSV={disabledCSV}
            isCSV={isCSV}
            transformDataToCSVFormat={transformDataToCSVFormat}
            setRefreshKey={setRefreshKey}
            refreshKey={refreshKey}
            groupings={csvFilters}
            isCustomFilters={isCustomFilters}
          />
        </div>

        {isEdit && setOpenEditTableDialog && (
          <button
            onClick={() => {
              setOpenEditTableDialog(!openEditTableDialog);
            }}
            className={
              "flex big-mobile-size:hidden border-2 h-10 w-44 rounded-3xl copy justify-center items-center self-end hover:!shadow-btnShadow"
            }
          >
            <EditTable />
            <span style={{ fontSize: 16, color: "#0079FF", paddingLeft: 10, fontWeight: 500 }}>
              Edit the table
            </span>
          </button>
        )}

        {isEdit && (
          <EditTableDialog
            openEditTableDialog={openEditTableDialog ?? false}
            setOpenEditTableDialog={setOpenEditTableDialog ?? (() => {})}
            columnList={columnList ?? []}
            setColumnList={setColumnList ?? (() => {})}
            actualColumnList={actualColumnList ?? []}
            setActualColumnList={setActualColumnList ?? (() => {})}
            dialogApplyButton={dialogApplyButton ?? false}
            setDialogApplyButton={setDialogApplyButton ?? (() => {})}
          />
        )}
      </div>
      <div className="max-w-[440px] mt-6">
        {
          isSearch && (
            <SearchByRow
              searchTerm={searchTerm ?? ""}
              setSearchTerm={setSearchTerm ?? (() => {})}
              handleSearch={handleSearch ?? (() => [])}
            />
          )
        }
      </div>
    </>
  );
};

export default FilterBarConversationReport;
