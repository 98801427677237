import React, { MutableRefObject, useContext, useEffect, ReactPortal, useState } from 'react';
import { Cell, ColumnDef, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import ScrollContainer from 'react-indiana-drag-scroll';
import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@tremor/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { FilterFn } from '@tanstack/react-table';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './index.css';
import { darkMainColorEnum, mainColorEnum, subColorEnum, darkSubColorEnum } from '../../../types/enums/TableColors';
import SortedArrowAscending from '../../../assets/icons/SortedArrowAscending';
import SortedArrowDescending from '../../../assets/icons/SortedArrowDescending';
import DefaultTableMessage from '../DefaultTableMessage/DefaultTableMessage';
import ExclamationMark from '../../../assets/icons/ExclamationMark';
import { ReportTableCustomColumnMeta } from '../../../types/report-table.types';

type TipsHoveredState = {
  [index: number]: boolean;
};

interface ReportTableProps {
  data: any;
  initialExpandedDepth?: string;
  globalFilter: string;
  onNoData?: (isNoData: boolean) => void;
  searchTerm: string;
  groupingsCount: number;
  levelNames: string[];
  getColumns: (themedColors: any, searchTerm: string, theme: 'light' | 'dark', groupingsCount?: number, cycleKeys?: string[]) => ColumnDef<any>[];
  isGroupingsByHeaders?: boolean;
  colSpan?: number;
}

const ReportTable = ({
  data,
  initialExpandedDepth = '1',
  globalFilter,
  onNoData,
  searchTerm,
  groupingsCount,
  levelNames,
  getColumns,
  isGroupingsByHeaders = false,
  colSpan = 1,
}: ReportTableProps) => {
  const filteredData = React.useMemo(() => data, [data]);

  const cycleKeys = React.useMemo(() => {
    if (!filteredData?.length || !filteredData[0]?.stats) return [];

    return Object.keys(filteredData[0].stats)
      .filter((key) => key.startsWith('cycle') && !isNaN(Number(key.replace('cycle', ''))))
      .sort((a, b) => Number(a.replace('cycle', '')) - Number(b.replace('cycle', '')));
  }, [filteredData]);

  const { themedColors, theme } = useContext(ThemeContext)!;
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [expanded, setExpanded] = React.useState<any>(() => getExpandedState(data, initialExpandedDepth ?? 0));
  const columns = React.useMemo(
    () => getColumns(themedColors, searchTerm, theme, groupingsCount, cycleKeys),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [themedColors, searchTerm, theme, groupingsCount]
  );

  useEffect(() => {
    const initialExpandedState = getExpandedState(data, initialExpandedDepth ?? 0);
    setExpanded(initialExpandedState);
  }, [data, initialExpandedDepth]);

  const recursiveFilterFn: FilterFn<any> = (row, columnId, filterValue) => {
    const search = String(filterValue).toLowerCase();

    const nameCell = row.getAllCells().find((cell) => cell.column.id === 'name');

    if (nameCell) {
      const value = nameCell.getValue();
      if (String(value).toLowerCase().includes(search)) {
        return true;
      }
    }

    if (row.subRows && row.subRows.length > 0) {
      // @ts-ignore
      return row.subRows.some((subRow) => recursiveFilterFn(subRow, columnId, filterValue));
    }

    return false;
  };

  const table = useReactTable({
    columns,
    data: filteredData,
    state: {
      expanded,
      globalFilter,
    },
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    getExpandedRowModel: getExpandedRowModel(),
    //
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.child_data,

    getRowId: (row, index, parent) => {
      const id = parent ? [parent.id, row.name || index].join('.') : `${index}`;
      return id;
    },
    globalFilterFn: recursiveFilterFn,
  });

  useEffect(() => {
    if (onNoData) {
      onNoData(table.getRowModel().rows.length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getRowModel().rows.length, onNoData]);

  function getExpandedState(data: any[], depth: string): Record<string, boolean> {
    const expanded: Record<string, boolean> = {};

    function helper(rows: any[], currentDepth: number, parentId: string) {
      rows.forEach((row, index) => {
        const rowId = parentId ? [parentId, row.name || index].join('.') : `${index}`;
        if (currentDepth < Number(depth)) {
          expanded[rowId] = true;
          if (row.child_data && row.child_data.length > 0) {
            helper(row.child_data, currentDepth + 1, rowId);
          }
        }
      });
    }

    helper(data, 0, '');
    return expanded;
  }

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null) as React.MutableRefObject<HTMLDivElement>;

  const [subPage, setSubPage] = React.useState<number>(1);

  const [visibleStartIndex, setVisibleStartIndex] = React.useState(0);
  const [visibleEndIndex, setVisibleEndIndex] = React.useState(0);

  const combinedRows = React.useMemo(() => {
    const combined: any[] = [];
    const visibleRows = table.getRowModel().rows;

    const limitedRows = visibleRows;

    let previousDepth = -1; // Initialize with a value that won't match any valid depth

    limitedRows.forEach((row, index) => {
      const depth = row.depth;
      const levelName = levelNames[depth] || null;
      const isLevelStart = index === 0 || limitedRows[index - 1].depth !== depth;

      // Determine if the header should be rendered
      const isNeedToRenderHeader = isLevelStart && levelName && previousDepth < depth;

      if (isNeedToRenderHeader) {
        combined.push({
          type: 'header',
          depth,
          levelName,
          key: `level-header-${index}`,
        });
      }

      combined.push({
        type: 'row',
        row,
        key: row.id,
      });

      // Update previousDepth for the next iteration
      previousDepth = depth;
    });

    return combined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getRowModel().rows, levelNames, expanded, subPage]);

  const rowHeight = 42;
  const totalRows = combinedRows.length;

  const handleScroll = React.useCallback(() => {
    if (!tableContainerRef.current) return;

    const scrollTop = tableContainerRef.current.scrollTop;
    const clientHeight = tableContainerRef.current.clientHeight;

    const buffer = 5; // affect on performance

    const newVisibleStartIndex = Math.max(0, Math.floor(scrollTop / rowHeight) - buffer);
    const newVisibleEndIndex = Math.min(totalRows - 1, Math.ceil((scrollTop + clientHeight) / rowHeight) + buffer);

    if (visibleStartIndex !== newVisibleStartIndex || visibleEndIndex !== newVisibleEndIndex) {
      setVisibleStartIndex(newVisibleStartIndex);
      setVisibleEndIndex(newVisibleEndIndex);
    }

    if (newVisibleEndIndex >= combinedRows.length - buffer && subPage * 10 < table.getRowModel().rows.length) {
      setSubPage((prevSubPage) => prevSubPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowHeight, totalRows, visibleStartIndex, visibleEndIndex, combinedRows.length, subPage, table.getRowModel().rows.length]);

  useEffect(() => {
    if (tableContainerRef.current) {
      handleScroll();
    }
  }, [handleScroll]);

  const [tipsHovered, setTipsHovered] = useState<TipsHoveredState>({});
  const [tooltipOpen, setTooltipOpen] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    if (!isMobile) {
      setTipsHovered((prev) => ({ ...prev, [index]: true }));
    }
  };

  const handleMouseLeave = (index: number) => {
    if (!isMobile) {
      setTipsHovered((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleTooltipToggle = (index: number) => {
    if (isMobile) {
      setTooltipOpen((prev) => (prev === index ? null : index));
    }
  };

  return (
    <div className='pt-0 tablet-size:pt-6 w-full relative max-w-full'>
      {filteredData.length > 0 ? (
        <ScrollContainer
          className='scroll-container'
          hideScrollbars={false}
          mouseScroll={{ rubberBand: false }}
          style={{
            cursor: 'default',
            borderRadius: !isMobile ? 8 : 0,
            overflow: 'auto',
            position: 'relative',
            maxHeight: `${Math.max(window.innerHeight - 100, 700)}px`,
          }}
          onScroll={handleScroll}
          ref={tableContainerRef as ReactPortal & MutableRefObject<HTMLDivElement>}>
          <table
            className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
            style={{
              minWidth: 2000,
            }}>
            <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px] sticky bg-opacity-100 z-[2] top-[-1px]'>
              {table.getHeaderGroups().map((headerGroup, index) => {
                return (
                  <tr key={headerGroup.id + index}>
                    {headerGroup.headers.map((header, index) => {
                      const isFontBold = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isFontBold;
                      const headerBackground = header.column.getIsSorted() ? '!bg-[#CEE5FC] dark:!bg-slate-800' : '';
                      const isAlignTextRight = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isAlignTextRight;
                      const isPinned = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isPinned;
                      const isTooltip = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isTooltip;
                      const tooltipMessage = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.tooltipMessage;
                      const isNoBorderHeaderLeft = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isNoBorderHeaderLeft;
                      const isSpecialLeftPadding = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isSpecialLeftPadding;

                      const cellWidth = header.column.getSize();
                      const charCount = Math.floor(cellWidth! / 7);
                      const leftPadding = charCount * 7;

                      return (
                        <th
                          key={header.id + index}
                          colSpan={header.colSpan}
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            color: themedColors.gray.bluish,
                            fontWeight: isFontBold ? '600' : '500',
                            zIndex: isPinned ? 1 : 0,
                            boxShadow: isPinned ? '-4px 0 4px -4px gray inset' : '',
                            opacity: isPinned ? 1 : 1,
                            position: isPinned && !isMobile ? 'sticky' : 'relative',
                            left: isPinned && !isMobile ? `-1px` : '',
                            right: isPinned && !isMobile ? `-1px` : '',
                            top: isPinned && !isMobile ? `-1px` : '',
                            padding: 0,
                            minWidth: header.column.id === 'name' ? `${350 + (Number(groupingsCount) + 1) * 8}px` : header.getSize() ? `${header.getSize()}px` : '200px',
                          }}
                          className={`bg-blue-50 dark:bg-odin-dark-gray-light ${headerBackground}`}>
                          <div
                            {...{
                              className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                            className={`!text-xs font-inherit text-left ${header.column.getCanSort() ? 'cursor-pointer' : ''} 
                                  !h-[56px] !py-0 px-2 flex justify-start items-center`}
                            style={{
                              justifyContent: isAlignTextRight ? 'right' : 'left',
                              fontSize: '12px',
                              lineHeight: '14.52px',
                              borderLeft: !isNoBorderHeaderLeft ? (theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80') : undefined,

                              borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                              paddingLeft: isSpecialLeftPadding ? `${leftPadding}px` : '',
                            }}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: (
                                <span className='ml-2'>
                                  <SortedArrowAscending />
                                </span>
                              ),
                              desc: (
                                <span className='ml-2'>
                                  <SortedArrowDescending />
                                </span>
                              ),
                            }[header.column.getIsSorted() as string] ?? null}
                            {isTooltip && (
                              <Tooltip
                                classes={{
                                  tooltip:
                                    '!py-2 !px-4 !max-w-[245px] !text-xs !font-normal !bg-opacity-90 !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]',
                                }}
                                title={tooltipMessage}
                                placement='right-end'
                                open={isMobile ? tooltipOpen === index : undefined}>
                                <span
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={() => handleMouseLeave(index)}
                                  onClick={() => handleTooltipToggle(index)}
                                  className='ml-1'>
                                  <ExclamationMark isTipsHovered={!!tipsHovered[index]} />
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody>
              {visibleStartIndex > 0 && (
                <tr style={{ height: visibleStartIndex * rowHeight }}>
                  <td colSpan={columns.length} />
                </tr>
              )}

              {combinedRows.slice(visibleStartIndex, visibleEndIndex + 1).map((item, index) => {
                let depth = item?.row?.depth;
                if (item.type === 'header') {
                  depth = item.depth;
                }
                const backgroundColor = theme === 'light' ? subColorEnum[depth] : darkSubColorEnum[depth];
                const additionalBackgroundColor = theme === 'light' ? mainColorEnum[depth] : darkMainColorEnum[depth];

                if (item.type === 'header') {
                  return (
                    <tr
                      key={'header' + item.key + index}
                      style={{ background: additionalBackgroundColor }}>
                      {columns.map((column, columnIndex) => (
                        <td
                          key={`level-${item.depth}-${column.id}-${columnIndex}`}
                          className={`text-left text-white font-bold`}
                          style={{
                            fontSize: '12px',
                            height: `${rowHeight}px`,
                            zIndex: columnIndex === 0 ? 1 : 0,
                            position: columnIndex === 0 && !isMobile ? 'sticky' : 'relative',
                            top: 0,
                            left: columnIndex === 0 ? 0 : undefined,
                            background: additionalBackgroundColor,
                          }}
                          colSpan={colSpan}>
                          <div className='flex items-center justify-start w-full'>
                            {columnIndex === 0 &&
                              Array.from({ length: item.depth }, (_, i) => (
                                <span
                                  key={i}
                                  className='block'
                                  style={{
                                    background: theme === 'light' ? subColorEnum[i] : darkSubColorEnum[i],
                                    width: '2rem',
                                    position: 'absolute',
                                    height: `${rowHeight + 3}px`,
                                    left: `${-1 + i * 32}px`,
                                  }}></span>
                              ))}
                            <span
                              style={{
                                paddingLeft: columnIndex === 0 ? `${1.2 + item.depth * 2}rem` : '0.5rem',
                              }}>
                              {columnIndex === 0 && item.levelName}
                            </span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                } else if (item.type === 'row') {
                  const row = item.row;
                  return (
                    <React.Fragment key={'row' + row.id + index}>
                      <tr
                        key={row.id + index}
                        className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'
                        style={{
                          background: backgroundColor,
                          opacity: 1,
                        }}>
                        {row?.getVisibleCells().map((cell: any, index: number) => {
                          const isLastInGroup = (cell.column.columnDef.meta as ReportTableCustomColumnMeta)?.isLastInGroup;
                          const isAlignTextRight = (cell.column.columnDef.meta as ReportTableCustomColumnMeta)?.isAlignTextRight;
                          const isFirstColumn = (cell.column.columnDef.meta as ReportTableCustomColumnMeta)?.isFirstColumn;

                          function getIsTooltop(cell: Cell<any, unknown>) {
                            const cellWidth = cell.column.getSize();
                            const charCount = Math.floor(cellWidth! / 7);

                            return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                          }

                          const isPinned = (cell.column.columnDef.meta as any)?.isPinned;
                          return (
                            <td
                              key={'row' + cell.id + index}
                              id={cell.id}
                              className={`
                                !text-xs !h-[42px] !py-0 !px-2 !font-normal 
                              ${isPinned ? (theme === 'light' ? 'is-sticky' : 'is-sticky-dark') : ''}
                              }`}
                              style={{
                                borderBottom: theme === 'light' ? '1px solid #e0e0e0' : '1px solid #353E50',
                                borderRight: isLastInGroup ? (theme === 'light' ? '1px solid #e0e0e0' : '1px solid #353E50') : undefined,
                                color: themedColors.content,
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: isAlignTextRight ? 'right' : 'left',
                                zIndex: isPinned ? 1 : 0,
                                boxShadow: isPinned ? '-4px 0 4px -4px gray inset' : '',
                                opacity: isPinned ? 1 : 1,
                                position: isPinned && !isMobile ? 'sticky' : 'relative',
                                left: isPinned && !isMobile ? `-1px` : '',
                                right: isPinned && !isMobile ? `-1px` : '',
                              }}>
                              {isPinned &&
                                Array.from({ length: row.depth }, (_, i) => (
                                  <span
                                    key={i}
                                    className='block'
                                    style={{
                                      background: theme === 'light' ? subColorEnum[i] : darkSubColorEnum[i],
                                      width: '2rem',
                                      position: 'absolute',
                                      height: '45px',
                                      left: `${-1 + i * 32}px`,
                                      top: '-5px',
                                    }}></span>
                                ))}
                              <Tooltip
                                classes={{
                                  tooltip: '!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]',
                                  arrow: '!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90',
                                }}
                                title={getIsTooltop(cell) as string}
                                placement='left'
                                arrow>
                                <span
                                  className='relative flex items-center cursor-pointer'
                                  style={{ justifyContent: isAlignTextRight ? 'right' : 'left', fontWeight: isFirstColumn ? '500' : '400', fontSize: '12px' }}
                                  onClick={isFirstColumn ? () => row.toggleExpanded() : undefined}>
                                  {isFirstColumn ? (
                                    row.getIsExpanded() ? (
                                      <Icon
                                        icon={ChevronDownIcon}
                                        style={{ color: themedColors.content }}
                                        className='cursor-pointer'
                                      />
                                    ) : (
                                      <Icon
                                        icon={ChevronRightIcon}
                                        style={{ color: themedColors.content }}
                                        className='cursor-pointer'
                                      />
                                    )
                                  ) : (
                                    ''
                                  )}
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </span>
                              </Tooltip>
                            </td>
                          );
                        })}
                      </tr>
                    </React.Fragment>
                  );
                }
                return null;
              })}

              {visibleEndIndex < combinedRows.length - 1 && (
                <tr style={{ height: (combinedRows.length - visibleEndIndex - 1) * rowHeight }}>
                  <td colSpan={columns.length} />
                </tr>
              )}
            </tbody>
          </table>
        </ScrollContainer>
      ) : (
        <>{onNoData && <DefaultTableMessage state={'noData'} />}</>
      )}
    </div>
  );
};

export default ReportTable;
