// @ts-nocheck
import { v4 as uuidv4 } from 'uuid';
import { StatsInterface, TransformedRetentionReportData } from '../types/retention-report.types';
import { transformNames } from './config';

export const prepareDataForTransform = (data: any) => {
  if (Object.keys(data).length === 0) {
    return {};
  }

  const transformObject = (obj: any, objName: any) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== 'stats') {
        if (typeof value === 'object' && !Array.isArray(value)) {
          transformed[key] = Object.entries(value)
            .sort((a: any, b: any) => (b[1].stats?.cancels || 0) - (a[1].stats?.cancels || 0))
            .map(([nestedKey, nestedValue]) => {
              if (nestedKey === '') {
                return transformObject(nestedValue, 'NaN');
              }
              return transformObject(nestedValue, nestedKey);
            });
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: any } = {};
  Object.entries(data).forEach(([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
    result[mainKey] = Object.entries(mainValue)
      .sort((a: any, b: any) => (b[1].stats?.cancels || 0) - (a[1].stats?.cancels || 0))
      .map(([nestedKey, nestedValue]) => {
        if (nestedKey === '') {
          return transformObject(nestedValue, 'NaN');
        }
        return transformObject(nestedValue, nestedKey);
      });
  });

  return result;
};

export function transformData(data: any): TransformedRetentionReportData[] | [] {
  const mergedData = mergeCyclesIntoInitials(data.initals, data.cycles);
  const preparedData = prepareDataForTransform(mergedData);

  function recursiveTransform(node: any): TransformedRetentionReportData[] {
    if (Array.isArray(node)) {
      const transformedArray = node.flatMap((item) => recursiveTransform(item));
      transformedArray.sort((a, b) => (Number(b.stats.cancels) || 0) - (Number(a.stats.cancels) || 0));
      return transformedArray;
    } else if (typeof node === 'object' && node !== null) {
      const nameKey = transformNames.find((key) => key in node && typeof node[key] === 'string');

      const calculateTotals = (initialStats: any, cycleStats?: any) => {
        if (!initialStats) {
          return {
            grossRevenueTotal: 0,
            netRevenueTotal: 0,
            ordersTotal: 0,
            avgLtv: 0,
          };
        }

        const cycleKeys = Object.keys(cycleStats || {}).filter((key) => key.startsWith('cycle') && key !== 'cycle1');

        const totalRetentionGrossRevenue = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_gross_revenue) || 0), 0);
        const totalRetentionNet = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_net) || 0), 0);
        const totalRetentionOrders = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_orders) || 0), 0);

        const grossRevenueTotal = (parseFloat(initialStats.gross_revenue) || 0) + totalRetentionGrossRevenue;
        const netRevenueTotal = (parseFloat(initialStats.net) || 0) + totalRetentionNet;
        const ordersTotal = (parseFloat(initialStats.orders) || 0) + totalRetentionOrders;
        const avgLtv = ordersTotal > 0 ? netRevenueTotal / ordersTotal : 0;

        return {
          grossRevenueTotal: grossRevenueTotal,
          netRevenueTotal: netRevenueTotal,
          ordersTotal,
          avgLtv: avgLtv,
        };
      };

      if (nameKey) {
        const name = node.name ?? '---';
        const stats: StatsInterface = {
          name,
          customers: node.customers || null,
          cancels: node.cancels || null,
          chargebacks: node.chargebacks || null,
          rdr: node.rdr || null,
          alerts: node.alerts || null,
          ethoca_alerts: node.ethoca_alerts || null,
          collab_alerts: node.collab_alerts || null,
          gross_revenue: node.gross_revenue || null,
          partial_refund: node.partial_refund || null,
          full_refund: node.full_refund || null,
          customer_ltv: node.customer_ltv || null,
          net: node.net || null,
          currency: node.currency || null,
          orders: node.orders || null,
          pending_orders: node?.pending_orders || null,
          rebill_schedule_is_success: node?.rebill_schedule_is_success || null,
          declined: node?.declined || null,
          recycle_success: node?.recycle_success || null,
          recycle_failed: node?.recycle_failed || null,
          retention_chargebacks: node?.retention_chargebacks || null,
          retention_rdr: node?.retention_rdr || null,
          retention_alerts: node?.retention_alerts || null,
          retention_ethoca_alerts: node?.retention_ethoca_alerts || null,
          retention_collab_alerts: node?.retention_collab_alerts || null,
          retention_gross_revenue: node?.retention_gross_revenue || null,
          retention_partial_refund: node?.retention_partial_refund || null,
          retention_full_refund: node?.retention_full_refund || null,
          retention_net: node?.retention_net || null,
          retention_orders: node?.orders || null,
          cohort_count: node?.cohort_count || null,
          cohort_ltv: node?.cohort_ltv || null,
          cohort_gross: node?.cohort_gross || null,
          ...Object.keys(node)
            .filter((key) => key.startsWith('cycle'))
            .reduce((acc, key) => {
              acc[key] = node[key];
              return acc;
            }, {}),
          avgLtv: calculateTotals(node, node).avgLtv,
          grossRevenueTotal: calculateTotals(node, node).grossRevenueTotal,
          netRevenueTotal: calculateTotals(node, node).netRevenueTotal,
        };

        const child_data: TransformedRetentionReportData[] = [];

        for (const key in node) {
          if (transformNames.includes(key) && key !== nameKey) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
          } else if (typeof node[key] === 'object' && transformNames.includes(key)) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
          }
        }

        child_data.sort((a, b) => (Number(b.stats.cancels) || 0) - (Number(a.stats.cancels) || 0));

        return [
          {
            name,
            stats,
            child_data,
          },
        ];
      } else {
        const child_data: TransformedRetentionReportData[] = [];

        for (const key in node) {
          const child = recursiveTransform(node[key]);
          child_data.push(...child);
        }

        child_data.sort((a, b) => (Number(b.stats.cancels) || 0) - (Number(a.stats.cancels) || 0));

        return child_data;
      }
    }
    return [];
  }

  return recursiveTransform(preparedData);
}

export const mergeCyclesIntoInitials = (initials, cycles) => {
  const mergeStats = (initialStats, cycleStats, cycleKey) => {
    if (!cycleStats) return initialStats;
    const updatedCycleStats = { ...cycleStats, retention_orders: cycleStats.orders };

    return {
      ...initialStats,
      [`cycle${Number(cycleKey) + 1}`]: updatedCycleStats,
    };
  };

  const recursiveMerge = (initialNode, cycleNode, cycleKey) => {
    if (!cycleNode || typeof cycleNode !== 'object') {
      return initialNode;
    }

    if (!initialNode || typeof initialNode !== 'object') {
      return JSON.parse(JSON.stringify(cycleNode));
    }

    const allKeys = new Set([...Object.keys(initialNode), ...Object.keys(cycleNode)]);

    for (const key of allKeys) {
      const inInitial = initialNode.hasOwnProperty(key);
      const inCycle = cycleNode.hasOwnProperty(key);

      if (!inCycle) continue;

      if (key === 'stats') {
        initialNode[key] = mergeStats(inInitial ? initialNode[key] : {}, cycleNode[key], cycleKey);
        continue;
      }

      if (
        inInitial &&
        typeof initialNode[key] === 'object' &&
        typeof cycleNode[key] === 'object' &&
        initialNode[key] !== null &&
        cycleNode[key] !== null &&
        !Array.isArray(initialNode[key]) &&
        !Array.isArray(cycleNode[key])
      ) {
        initialNode[key] = recursiveMerge(initialNode[key], cycleNode[key], cycleKey);
      } else {
        initialNode[key] = JSON.parse(JSON.stringify(cycleNode[key]));
      }
    }

    return initialNode;
  };

  for (const cycleKey in cycles) {
    if (cycles.hasOwnProperty(cycleKey)) {
      initials = recursiveMerge(initials, cycles[cycleKey], Number(cycleKey));
    }
  }
  return initials;
};

export const transformDataForCSV = (data: RetentionReportData) => {
  if (Object.keys(data).length === 0) {
    return {};
  }

  const calculateTotals = (initialStats: any, cycleStats?: any) => {
    const cycleKeys = Object.keys(cycleStats || {}).filter((key) => key.startsWith('cycle') && key !== 'cycle1');
    const totalRetentionGrossRevenue = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_gross_revenue) || 0), 0);
    const totalRetentionNet = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_net) || 0), 0);
    const totalRetentionOrders = cycleKeys.reduce((sum, key) => sum + (parseFloat(cycleStats?.[key]?.retention_orders) || 0), 0);

    const grossRevenueTotal = (parseFloat(initialStats.gross_revenue) || 0) + totalRetentionGrossRevenue;
    const netRevenueTotal = (parseFloat(initialStats.net) || 0) + totalRetentionNet;
    const ordersTotal = (parseFloat(initialStats.orders) || 0) + totalRetentionOrders;
    const avgLtv = ordersTotal > 0 ? netRevenueTotal / ordersTotal : 0;

    return {
      grossRevenueTotal: grossRevenueTotal,
      netRevenueTotal: netRevenueTotal,
      ordersTotal,
      avgLtv: avgLtv,
    };
  };

  const transformObject = (obj: any, objName: string, cycleObj: any = null) => {
    const transformed = { ...obj.stats, name: objName, id: uuidv4() };
    if (cycleObj) {
      transformed.cycles = cycleObj.stats;
      transformed.total = calculateTotals(obj.stats, obj.stats);
    } else {
      transformed.total = calculateTotals(obj.stats);
    }

    Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
      if (key !== 'stats') {
        if (typeof value === 'object' && !Array.isArray(value)) {
          transformed[key] = Object.entries(value)
            .sort((a: any, b: any) => (b[1].stats?.cancels || 0) - (a[1].stats?.cancels || 0))
            .map(([nestedKey, nestedValue]) => {
              const nestedCycleValue = cycleObj && cycleObj[key] ? cycleObj[key][nestedKey] : null;
              return transformObject(nestedValue, nestedKey, nestedCycleValue);
            });
        } else {
          transformed[key] = value;
        }
      }
    });
    return transformed;
  };

  const result: { [key: string]: any } = {};
  const initialData = data.initals || {};
  const cycleData = data.cycles || {};

  Object.entries(initialData).forEach(([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
    result[mainKey] = Object.entries(mainValue)
      .sort((a: any, b: any) => (b[1].stats?.cancels || 0) - (a[1].stats?.cancels || 0))
      .map(([nestedKey, nestedValue]) => {
        // @ts-ignore
        for (const cycleKey in cycleData) {
          if (cycleData.hasOwnProperty(cycleKey)) {
            const cycleValue = cycleData[cycleKey]?.[mainKey]?.[nestedKey] ?? null;
            return transformObject(nestedValue, nestedKey, cycleValue);
          }
        }
      });
  });

  return result;
};
