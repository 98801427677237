import { StatsInterface, TransformedCapProjectionsReportData } from "../types/cap-projections-report.types";
import { transformNames } from "./config";
import { v4 as uuidv4 } from 'uuid';

export const prepareDataForTransform = (data: any) => {
    if (Object.keys(data).length === 0) {
      return {};
    }
    
    const transformObject = (obj: any, objName: any) => {
      const transformed = { ...obj.stats, name: objName, id: uuidv4() };
      Object.entries(obj).forEach(([key, value]: [key: any, value: any]) => {
        if (key !== "stats") {
          if (typeof value === "object" && !Array.isArray(value)) {
            transformed[key] = Object.entries(value)
              .sort((a: any, b: any) =>(b[1].stats?.total_monthly_cap || 0) - (a[1].stats?.total_monthly_cap || 0))
              .map(
                ([nestedKey, nestedValue]) => {
                  if (nestedKey === "") {
                    return transformObject(nestedValue, "NaN");
                  }
                  return transformObject(nestedValue, nestedKey);
                }
              );
          } else {
            transformed[key] = value;
          }
        }
      });
      return transformed;
    };
  
    const result: { [key: string]: any } = {};
    Object.entries(data).forEach(
      ([mainKey, mainValue]: [mainKey: any, mainValue: any]) => {
        result[mainKey] = Object.entries(mainValue)
          .sort((a: any, b: any) =>(b[1].stats?.total_monthly_cap || 0) - (a[1].stats?.total_monthly_cap || 0))
          .map(
            ([nestedKey, nestedValue]) => {
              if (nestedKey === "") {
                return transformObject(nestedValue, "NaN");
              }
              return transformObject(nestedValue, nestedKey);
            }
          );
      }
    );
    return result;
};

export function transformData(data: any): TransformedCapProjectionsReportData[] | [] {
    const preparedData = prepareDataForTransform(data);
    function recursiveTransform(node: any): TransformedCapProjectionsReportData[] {
        if (Array.isArray(node)) {
          // Process each item in the array and collect the results
          const transformedArray = node.flatMap(item => recursiveTransform(item));
          // Sort the array here based on your sorting criteria
          // For example, sort by stats.clicks in descending order
          transformedArray.sort((a, b) => (Number(b.stats.total_monthly_cap) || 0) - (Number(a.stats.total_monthly_cap) || 0));
          return transformedArray;
        } else if (typeof node === 'object' && node !== null) {
        const nameKey = transformNames.find(key => key in node && typeof node[key] === 'string');

        if (nameKey) {
            const name = node.name ?? '---';

            const stats: StatsInterface = {
              name,
              total_monthly_cap: node.total_monthly_cap || null,
              sales_month_to_date: node.sales_month_to_date || null,
              monthly_cap_remaining: node.monthly_cap_remaining || null,
              month_to_date_aov: node.month_to_date_aov || null,
              projected_number_of_rebills: node.projected_number_of_rebills || null,
              projected_amount_of_rebills: node.projected_amount_of_rebills || null,
              daily_sales_cap_remaining: node.daily_sales_cap_remaining || null,
              total_daily_cap: node.total_daily_cap || null,
              currency: node.currency || null,
            };

            const child_data: TransformedCapProjectionsReportData[] = [];

            for (const key in node) {
              if (transformNames.includes(key) && key !== nameKey) {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              } else if (typeof node[key] === 'object') {
                  const child = recursiveTransform(node[key]);
                  child_data.push(...child);
              }
            }

            child_data.sort((a, b) => (Number(b.stats.total_monthly_cap) || 0) - (Number(a.stats.total_monthly_cap) || 0));

            return [{
              name,
              stats,
              child_data,
            }];
        } else {
            const child_data: TransformedCapProjectionsReportData[] = [];

            for (const key in node) {
            const child = recursiveTransform(node[key]);
            child_data.push(...child);
            }

            child_data.sort((a, b) => (Number(b.stats.total_monthly_cap) || 0) - (Number(a.stats.total_monthly_cap) || 0));

            return child_data;
        }
        }
        return [];
    }

    return recursiveTransform(preparedData);
}