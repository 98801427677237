import chevronDownIcon from "@heroicons/react/solid/esm/ChevronDownIcon";
import {Icon} from "@tremor/react";
import SessionManager from "../../../odinForgeService/SessionManager";
import {Avatar, Dialog, DialogActions} from "@mui/material";
import React, {useContext, useState} from "react";
import ThemeToggle from "../../UI/ThemeToggle/ThemeToggle";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import BurgerIcon from "../../../assets/sideBarIcons/BurgerIcon";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { UserContext } from "../../../context/UserContext/UserContext";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Logo from "../../../assets/icons/Logo";
import TenantDropDown from "../../molecules/TenantDropDown/TenantDropDown";
import useTenantRedirect from "../../../hooks/useTenantRedirect";

interface TopBarProps {
    handleButtonClick: (arg: string) => void,
    openMobileSidebar: () => void,
    handleLogoutClick: () => void,
    handleOpenDialog: () => void,
    handleCloseDialog: () => void,
    openDialog: boolean
    setIsLogout: (arg: boolean) => void
}

function TopBar({
    handleButtonClick,
    openMobileSidebar,
    handleLogoutClick,
    handleOpenDialog,
    handleCloseDialog,
    openDialog,
    setIsLogout
}: TopBarProps) {
    const { theme, themedColors } = useContext(ThemeContext)!;
    const userContext = useContext(UserContext)!;
    const { email,avatar,name,isSuperAdmin } = userContext;

    const [isProfileHovered, setIsProfileHovered] = useState(false);
    const [isLogoutHovered, setIsLogoutHovered] = useState(false);

    const isMobile = useMediaQuery('(max-width: 1023px)')

    const [ isHovered, setIsHovered ] = useState(false);

    const { redirectToAppropriateUrl } = useTenantRedirect({ isSuperAdmin, isProfile: true });

    const handleAccontClick = () => {
        redirectToAppropriateUrl()
        handleCloseDialog()
    }

    const handleLogoutClickCustom = () => {
        setIsLogout(true);
    }
    
    return (
        <div className="flex flex-row justify-between">
            {
                theme === 'dark' ? (
                    <div className="hidden tablet-size:block px-4 py-[14px] relative">
                        <Logo width='104px' height='36px' />
                    </div>
                ) : (
                    <div className="hidden tablet-size:block px-4 py-[14px] relative">
                        <Logo width='104px' height='36px' />
                    </div>
                )
            }
            
            <div className={`flex tablet-size:hidden items-center pl-5 cursor-pointer`} onClick={openMobileSidebar}>
                <BurgerIcon />
            </div>
            <div className="flex gap-2 tablet-size:gap-4 items-center"> 
                <ThemeToggle />
                {
                    isSuperAdmin ? <TenantDropDown /> : null
                }
                <div className="flex flex-row p-4 tablet-size:cursor-pointer" onClick={handleOpenDialog} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Avatar
                        alt={SessionManager.instance().getEmail() ?? undefined}
                        sx={{ width:32, height:32, cursor: isMobile ? 'pointer' : 'default'}}
                        src={avatar ?? ''}
                    />
                    <p className={`hidden tablet-size:block text-sm py-1 px-2 text-odin-light-content dark:text-odin-dark-content ${openDialog || isHovered ? "!text-odin-light-blue-medium" : ""}`}>{name}</p>
                    {/* @ts-ignore -- "black" is not on the list of colors defined by tremor and throws a type error, but still works. */}
                    <Icon className={`hidden relative top-[-2px] tablet-size:block transition ease-in-out text-odin-light-content dark:text-odin-dark-content ${openDialog ? "rotate-180" : ""} ${openDialog || isHovered ? "!text-odin-light-blue-medium" : ""}`} icon={chevronDownIcon} size={"sm"} color={"black"}/>
                </div>
            </div>

            <Dialog open={openDialog} onClose={handleCloseDialog}
                    PaperProps={{ style: { position: 'absolute', top: isMobile ? '0px' : '56px', right: isMobile ? 0 : -15, width : '320px',height: 'auto',display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: isMobile ? 'flex-start' : 'center', borderRadius: isMobile ? '0px' : '8px', backgroundColor: `${theme === 'dark' ? themedColors.blue.dark : themedColors.white}`, padding: '24px'} }}
                    BackdropProps={{
                        invisible: true // Make the backdrop invisible
                    }}
                    className="tablet-size:block flex flex-col "
                    disableScrollLock={ true }
            >   
                <p className="absolute tablet-size:hidden text-xl font-bold p-4 w-full left-0 top-0" style={{color: themedColors.content, borderBottom: `2px solid ${themedColors.border}`}}>
                    Profile
                </p>
                <div className="flex justify-start gap-6 items-center w-full pb-6 mt-16 tablet-size:mt-0" style={{borderBottom: `2px solid ${themedColors.border}`}}>
                    <div className="rounded-full h-[48px] w-[48px] bg-[#ACBFDF] text-center">
                        <Avatar
                            alt={SessionManager.instance().getEmail() ?? undefined}
                            sx={{ width:48, height:48}}
                            src={avatar ?? ''}
                        />  
                    </div>
                    <div className="flex flex-col items-start gap-3">
                        <p style={{color: themedColors.content, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: !isMobile ? '200px' : '65vw'}} className="text-center !font-semibold !text-base">{name}</p>
                        <div style={{marginTop: "-12px", color: themedColors.content, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: !isMobile ? '200px' : '65vw'}} className="text-odin-light-content dark:text-odin-dark-content !font-normal !text-sm">{email}</div>
                    </div>
                </div>  


                <div className="w-full pt-6" style={{width: '100%'}}>
                    <div className="flex flex-col">
                        <div className="flex gap-3 cursor-pointer items-center p-3 rounded" onClick={handleAccontClick} onMouseEnter={() => setIsProfileHovered(true)} onMouseLeave={() => setIsProfileHovered(false)}
                            style={{
                                backgroundColor: isProfileHovered ? `${theme === 'dark' ? 'rgb(39, 51, 73)' : '#DDEDFD'}` : 'transparent',
                            }}    
                        >
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19" stroke={isProfileHovered ? `${theme === 'dark' ? '#DDEDFD' : '#242546'}` : `${theme === 'light' ? '#677C9F' : '#677C9F'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z" stroke={isProfileHovered ? `${theme === 'dark' ? '#DDEDFD' : '#242546'}` : `${theme === 'light' ? '#677C9F' : '#677C9F'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <p 
                                style={{
                                    color: isProfileHovered ? `${theme === 'dark' ? '#DDEDFD' : '#242546'}` : `${theme === 'light' ? '#677C9F' : '#677C9F'}`
                                }}
                            >
                                Profile Settings
                            </p>
                        </div>
                        <div className="flex gap-3 cursor-pointer items-center p-3 rounded" onClick={handleLogoutClickCustom}  onMouseEnter={() => setIsLogoutHovered(true)} onMouseLeave={() => setIsLogoutHovered(false)}
                            style={{
                                backgroundColor: isLogoutHovered ? `${theme === 'dark' ? 'rgb(39, 51, 73)' : '#FEF2F2'}` : 'transparent',
                            }}    
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7" stroke={theme === 'light' ? '#D80027' : '#FF554E'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <p 
                                style={{
                                    color: theme === 'light' ? '#D80027' : '#FF554E'
                                }}
                            >
                                Logout
                            </p>
                        </div>
                    </div>
                </div>
                <DialogActions className="absolute tablet-size:!hidden top-4 right-4 text-[#ACBFDF] text-xl cursor-pointer" onClick={handleCloseDialog}>
                    <p className="tablet-size:hidden" >
                        <CloseIcon />
                    </p>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TopBar;