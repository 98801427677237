import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";
import getSymbolFromCurrency from 'currency-symbol-map';

dayjs.extend(utc);
dayjs.extend(timezone);

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
};

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
];

export const transformNames = [
    'currency',
    'geo',
    'affiliate_name',
    'mid_name',
    'funnel_name',
];

export const fields = {
    "Sales": "sales",
    "Partials": "partials",
    "Partial Success": "partial_success",
    "Gross Revenue": "gross_revenue",
    "Net Revenue": "net_revenue",
    "AOV": "aov",
    "Net AOV": "net_aov",
    "Refunds": "refunds",
    "Refunds Revenue": "refunds_revenue",
    "CBs": "chargebacks",
    "CBs Revenue": "chargebacks_revenue",
} as const;

export const fieldsForCSV = {
    "Sales": "sales",
    "Partials": "partials",
    "Partial Success": "partial_success",
    "Gross Revenue": "gross_revenue",
    "Net Revenue": "net_revenue",
    "AOV": "aov",
    "Net AOV": "net_aov",
    "Refunds": "refunds",
    "Refunds Revenue": "refunds_revenue",
    "CBs": "chargebacks",
    "CBs Revenue": "chargebacks_revenue",
};

export const tableHeadingNames = {
    sales: 'Sales',
    partials: 'Partials',
    partial_success: 'Partial Success',
    gross_revenue: 'Gross Revenue',
    aov: 'AOV',
    refunds: 'Refunds',
    refunds_revenue: 'Refunds Revenue',
    chargebacks: 'CBs',
    chargebacks_revenue: 'CBs Revenue',
}

export function getColumns(themedColors: any , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(350 + ((Number(groupingsCount)) * 8)) - ((row.depth + 1) * 32)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.sales,
            id: 'Sales',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Sales',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partials,
            id: 'Partials',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Partials',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.partial_success,
            id: 'Partial Success',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? (`${parseFloat(value?.toString() || '0').toFixed(2)}%`) : '0.00%';
            },
            header: 'Partial Success',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.gross_revenue,
            id: 'Gross Revenue',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Gross Revenue',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.net_revenue,
            id: 'Net Revenue',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Net Revenue',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.aov,
            id: 'AOV',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'AOV',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.net_aov,
            id: 'Net AOV',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Net AOV',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.refunds,
            id: 'Refunds',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Refunds',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.refunds_revenue,
            id: 'Refunds Revenue',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Refunds Revenue',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.chargebacks,
            id: 'CBs',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'CBs',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.chargebacks_revenue,
            id: 'CBs Revenue',
            cell: (info) => {
              const value = info.getValue();
              const currency = info.row.original.stats.currency;
              return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'CBs Revenue',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
        ]
}