import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";
import getSymbolFromCurrency from 'currency-symbol-map';
import { Colors } from '../../../../../context/ThemeContext/ThemeContext';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'Merchant Account',
    'Corporation',
    'Gateway Profile',
    'Bank Name',
];

export const namesEnum: { [key: string]: string } = {
    'Currency': 'currency',
    'Merchant Account': 'mid_name',
    "Corporation": "corporation_name",
    "Gateway Profile": "gateway_profile",
    "Bank Name": "bank_name",
};

export const transformNames = [
  'currency',
  'mid_name',
  'corporation_name',
  'gateway_profile',
  'bank_name',
];

export const fieldsForCSV = {
    "Total Monthly Cap": "total_monthly_cap",
    "Sales Month-to-Date": "sales_month_to_date",
    "Monthly Cap Remaining": "monthly_cap_remaining",
    "AOV Month-to-Date": "month_to_date_aov",
    "Projected Rebills #": "projected_number_of_rebills",
    "Projected Rebills $": "projected_amount_of_rebills",
    "Daily Sales Cap Remaining": "daily_sales_cap_remaining",
    "Total Daily Cap": "total_daily_cap",
} as const;

export const options = [
    { value: 'main_offer', label: 'Main Offer' },
    { value: 'upsells', label: 'Upsells' },
    { value: 'both', label: 'Both' },
];

export const dateRangeList = [
  "Custom Range",
  "This Month",
  "Last Month",
  "2 Month Ago",
  "3 Month Ago",
];

export const rangeConfigs: { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } = {
  1: { startDate: dayjs.tz().startOf('M'), endDate: dayjs.tz().endOf('D') },
  2: { startDate: dayjs.tz().startOf('M').subtract(1, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "s") },
  3: { startDate: dayjs.tz().startOf('M').subtract(2, "M"), endDate: dayjs.tz().startOf('M').subtract(1, "m").subtract(1, "s") },
  4: { startDate: dayjs.tz().startOf('M').subtract(3, "M"), endDate: dayjs.tz().startOf('M').subtract(2, "m").subtract(1, "s") },
};

export const generateRangeConfigs = (timezone: string): { [key: string | number]: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } } => {
  return {
      1: { startDate: dayjs.tz().startOf('month').tz(timezone), endDate: dayjs.tz().endOf('D').tz(timezone) },
      2: { startDate: dayjs.tz().startOf('month').subtract(1, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "second").tz(timezone) },
      3: { startDate: dayjs.tz().startOf('month').subtract(2, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(1, "month").subtract(1, "second").tz(timezone) },
      4: { startDate: dayjs.tz().startOf('month').subtract(3, "month").tz(timezone), endDate: dayjs.tz().startOf('month').subtract(2, "month").subtract(1, "second").tz(timezone) },
  };
};

export function getColumns(themedColors: Colors , searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
    return [
          {
            accessorKey: 'name',
            header: () => (
              <>
                Groupings
              </>
            ),
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth))
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className='flex gap-1 items-center flex-nowrap'>
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                        
                      >
                        {row.getIsExpanded() ? <Icon icon={ChevronDownIcon} style={{color:themedColors.content}}  className='cursor-pointer' />  :  <Icon icon={ChevronRightIcon} style={{color:themedColors.content}} className='cursor-pointer' />}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span 
                      className='overflow-hidden text-ellipsis whitespace-nowrap'
                      style={{
                        maxWidth: `${(350 + ((Number(groupingsCount)) * 8)) - ((row.depth + 1) * 32)}px`,
                      }}
                    >
                      <span className={hasFlag(getValue<string>())?'pr-3 text-center':'text-center hidden'}>
                      {
                        hasFlag(getValue<string>()) ? <ReactCountryFlag countryCode={getValue<string>()} svg style={{ width: '18px', height: '16px', }} /> : null
                      } 
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              )
            },
            footer: props => props.column.id,
            meta: {
              isLastInGroup: true,
              isPinned: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true
            },
            filterFn: 'includesStringSensitive'
          },
          {
            accessorFn: row => row.stats.total_monthly_cap,
            id: 'Total Monthly Cap',
            cell: (info) => {
                const value = info.getValue();
                const currency = info.row.original.stats.currency;
                return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Total Monthly Cap',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.sales_month_to_date,
            id: 'Sales Month-to-Date',
            cell: (info) => {
                const value = info.getValue();
                const currency = info.row.original.stats.currency;
                return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Sales Month-to-Date',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.monthly_cap_remaining,
            id: 'Monthly Cap Remaining',
            cell: (info) => {
                const value = info.getValue();
                const currency = info.row.original.stats.currency;
                return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Monthly Cap Remaining',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.month_to_date_aov,
            id: 'AOV Month-to-Date',
            cell: (info) => {
                const value = info.getValue();
                const currency = info.row.original.stats.currency;
                return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'AOV Month-to-Date',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.projected_number_of_rebills,
            id: 'Projected Rebills #',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Projected Rebills #',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.projected_amount_of_rebills,
            id: 'Projected Rebills $',
            cell: (info) => {
                const value = info.getValue();
                const currency = info.row.original.stats.currency;
                return `${currency && currency !== 'NaN' ? getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency : ''}${Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}`;
            },
            header: 'Projected Rebills $',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.daily_sales_cap_remaining,
            id: 'Daily Sales Cap Remaining',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Daily Sales Cap Remaining',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: row => row.stats.total_daily_cap,
            id: 'Total Daily Cap',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Total Daily Cap',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
            },
            sortingFn: numericStringSortingFn,
          },

        ]
}