enum Routes {
    login = 'api/user/login',
    logout = 'api/user/logout',
    loginUI= '/',
    forgotPassword= '/api/user/forgotPassword',
    resetPassword= '/api/user/resetPassword',
    sales = 'api/sales',
    reportConfig = 'api/report/config',
    getReportConfig = 'api/report/config/search',
    getSaleGraphFilterData = 'api/sales/graph/filter',
    getSaleGraphData = 'api/sales/graph',
    transactionReport = '/api/transaction/performance',
    profile = 'api/profile',
    changePassword = 'api/profile/changePassword',
    affiliateReport = 'api/clicks',
    affiliateReportV2 = 'api/clicks-test',
    retentionReport = 'api/orders',
    salesPerformanceReport = '/api/sales/performance',
    salesPerformanceReportChartFilterData = '/api/sales/performance/chart/filters',
    salesPerformanceReportChartData = '/api/sales/performance/chart',
    cancellationReasons = '/api/orders/cancellations',
    cancellationChart = '/api/orders/cancellations/chart',
    cancellationSubscriptionReasons = '/api/subscriptions/cancellations',
    cancellationSubscriptionChart = '/api/subscriptions/cancellations/chart',
    forecastedRebills = '/api/orders/forecasted-rebills',
    forecastedRebillsChart = '/api/orders/forecasted-rebills/chart',
    conversationReportFilterData = '/api/conversions/filters',
    conversationReportData = '/api/conversions',
    conversationReportMetrics = '/api/conversions/metrics',
    binRoutingPerformaceReport = '/api/bin-routing-performances',
    varianceReportFilterData = '/api/variances/filters',
    varianceReportData = '/api/variances',
    cardProcessingData = '/api/card-processings',
    duplicateCustomerReport = '/api/duplicate-customer-report',
    chargebackSummaryReport = '/api/chargeback-summary-report',
    fraudAnalysisReport = '/api/fraud-analysis-report',
    saveCsvExport = '/api/user/activity',
    updateProfilePicture = '/api/user/profilePicture',
    declineResponseReport = '/api/declines',
    checkToken = '/api/user/checkLogin',
    getTenants = '/api/tenants',
    fulfillmentSummaryReport = '/api/fulfillment-summary-report',
    errorsShownCustomerReport = '/api/errors/message',
    customerPurchaseAttemptsReport = '/api/customer-purchase-attempts',
    revenueSummaryReport = '/api/revenue',
    dtcPopulatedFieldsReport = '/api/errors/autofills',
    capProjectionsReport = '/api/cap-projections',
}

export default Routes;
