import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { CancellationSubscriptionReportData, CancellationSubscriptionRowInterface } from "../types/cancellation-subscription-report.types";

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(
  data: CancellationSubscriptionReportData,
  namesEnum: { [key: string]: string },
  fieldsForCSV: { [key: string]: string },
  filters?: string[] | undefined
) {
  const result: { [key: string]: string | number }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = [...filters!] 

  function processItem(item: CancellationSubscriptionRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string | number } = {
      cancelled_subscriptions: item.cancelled_subscriptions || '',
      cancelation_percentage: item.cancelation_percentage ? Number(item.cancelation_percentage)?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : '0.00%',
      customer_called_to_cancel: item.customer_called_to_cancel || '',
      blacklisted: item.blacklisted || '',
      fake_customer: item.fake_customer || '',
      ethoca_alert: item.ethoca_alert || '',
      order_cancelled: item.order_cancelled || '',
      unknown_cancellation_reason: item.unknown_cancellation_reason || '',
    };

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

    baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: CancellationSubscriptionRowInterface) =>
          processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
        );
      }
    });
  }

  Object.values(namesEnum).forEach((topLevelKey) => {
    if (data[topLevelKey] && Array.isArray(data[topLevelKey])) {
      // @ts-ignore
      data[topLevelKey].forEach((item) => processItem(item));
    }
  });

  const groupingFields = Array.from(groupingLevels).map((level) => {
    const foundKey = getKeyByValue(namesEnum, filtersList[level]);
    return foundKey ?? filtersList[level];
  });

  return result.map((item) => {
    const transformedItem: { [key: string]: string | number } = {};
    groupingFields.forEach((field) => {
      transformedItem[field] = item[field];
    });
    Object.keys(fieldsForCSV).forEach((key) => {
      transformedItem[key] = item[fieldsForCSV[key]];
    });
    return transformedItem;
  });
}

export default transformDataToCSVFormat;