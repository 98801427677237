import React from 'react';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Icon} from "@tremor/react";
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from "@tanstack/react-table";

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = [
    'GEO',
    'Affiliate',
    'Merchant Account',
    'Offer',
    'Subid',
    'Campaign Category',
    'Card Brand',
    'BIN',
    'Merchant Corporation',
];

export const namesEnum: { [key: string]: string } = {
    'GEO': 'geo',
    'Affiliate': 'affiliate_name',
    'Merchant Account': 'mid_name',
    'Offer': 'funnel_name',
    'Subid': 'c1',
    'Campaign Category': 'campaign_category_name',
    'Card Brand': 'card_brand',
    'BIN': 'card_bin',
    'Merchant Corporation': 'corporation_name',
};

export const transformNames = [
    'geo',
    'affiliate_name',
    'mid_name',
    'funnel_name',
    'c1',
    'campaign_category_name',
    'card_brand',
    'card_bin',
    'corporation_name',
];

export const fieldsForCSV = {
  "Total": "number_of_all_attempts",
  "Successful %": "percentage_successful_attempts_all",
  "First": "number_of_first_attempts",
  "First-%": "percentage_of_successful_first_attempts",
  "First-Difference": "difference_all_and_first_attempt_successes",
  "Second": "number_of_second_attempts",
  "Second-%": "percentage_of_successful_second_attempts",
  "Second-Difference": "difference_first_and_second_attempt_successes",
  "Third": "number_of_third_attempts",
  "Third-%": "percentage_of_successful_third_attempts",
  "Third-Difference": "difference_second_and_third_attempt_successes",
  "Fourth": "number_of_fourth_attempts",
  "Fourth-%": "percentage_of_successful_fourth_attempts",
  "Fourth-Difference": "difference_third_and_fourth_attempt_successes",
  "Avg. per person": "average_attempts_per_customer",
  "Multiple declines & zero approvals %": "percentage_customers_with_declines",
  "Avg. Time Between 1 and 2 Purchase Attempts": "average_time_between_attempt_1_and_2",
  "Avg. Time Between 2 and 3 Purchase Attempts": "average_time_between_attempt_2_and_3",
  "Avg. Time Between 3 and 4 Purchase Attempts": "average_time_between_attempt_3_and_4",
};

export function getColumns(
    themedColors: any,
    searchTerm: string,
    theme: 'light' | 'dark' = 'light',
    groupingsCount?: number
  ): ColumnDef<any>[] {
    return [
      {
        header: '',
        id: 'groupingsGroup',
        meta: {
            isLastInGroup: true,
            isGroupingColumn: true,
            isNoBorderHeaderLeft: true,
            isPinned: true,
        },
        columns: [
          {
            accessorKey: 'name',
            header: 'Groupings',
            cell: ({ row, getValue }) => {
              const rowName = formatRowName(
                getValue<string>(),
                row.original.stats.currency,
                Number(row.depth)
              );
  
              return (
                <div
                  style={{
                    paddingLeft: `${row.depth * 2}rem`,
                  }}
                >
                  <div className="flex gap-1 items-center flex-nowrap">
                    {row.getCanExpand() ? (
                      <button
                        {...{
                          onClick: row.getToggleExpandedHandler(),
                          style: { cursor: 'pointer' },
                        }}
                      >
                        {row.getIsExpanded() ? (
                          <Icon
                            icon={ChevronDownIcon}
                            style={{ color: themedColors.content }}
                            className="cursor-pointer"
                          />
                        ) : (
                          <Icon
                            icon={ChevronRightIcon}
                            style={{ color: themedColors.content }}
                            className="cursor-pointer"
                          />
                        )}
                      </button>
                    ) : (
                      <span style={{ width: 16 }} />
                    )}{' '}
                    <span
                      className="overflow-hidden text-ellipsis whitespace-nowrap"
                      style={{
                        maxWidth: `${350 +
                          (Number(groupingsCount)) * 8 -
                          (row.depth + 1) * 32}px`,
                      }}
                    >
                      <span
                        className={
                          hasFlag(getValue<string>())
                            ? 'pr-3 text-center'
                            : 'text-center hidden'
                        }
                      >
                        {hasFlag(getValue<string>()) ? (
                          <ReactCountryFlag
                            countryCode={getValue<string>()}
                            svg
                            style={{ width: '18px', height: '16px' }}
                          />
                        ) : null}
                      </span>
                      {highlightMatch(rowName, searchTerm, theme)}
                    </span>
                  </div>
                </div>
              );
            },
            footer: (props) => props.column.id,
            meta: {
              isLastInGroup: true,
              isGroupingColumn: true,
              isNoBorderHeaderLeft: true,
              isPinned: true,
            },
            filterFn: 'includesStringSensitive',
          },
        ],
      },
      {
        header: '',
        id: 'groupingsGroup',
        meta: {
            isLastInGroup: true,
            isGroupingColumn: true,
            isNoBorderHeaderLeft: true,
        },
        columns: [
          {
            accessorFn: (row) => row.stats.number_of_all_attempts,
            id: 'Total',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value)?.toLocaleString() : '0';
            },
            header: 'Total',
            meta: {
                isLastInGroup: true,
                isGroupingColumn: true,
                isNoBorderHeaderLeft: true,
                isAlignTextRight: true,
                isTooltip: true,
                tooltipMessage: 'Total number of purchase attempts'
              },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: (row) => row.stats.percentage_successful_attempts_all,
            id: 'Successful %',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value)
                ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                : '0.00%';
            },
            header: 'Successful %',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Percentage of successful purchase attempts of total purchase attempts'
            },
            sortingFn: numericStringSortingFn,
          },
        ],
      },
      {
        header: 'First',
        id: 'First',
        meta: {
          isSpecialLeftPadding: true
        },
        columns: [
            {
                accessorFn: (row) => row.stats.number_of_first_attempts,
                id: 'First-#',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value) ? Number(value)?.toLocaleString() : '0';
                },
                header: '#',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Number of customers who made their first purchase attempt'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.percentage_of_successful_first_attempts,
                id: 'First-%',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: '%',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Percentage of successful first purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.difference_all_and_first_attempt_successes,
                id: 'First-Difference',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: 'Difference',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Difference between total % and first purchase attempt %'
                },
                sortingFn: numericStringSortingFn,
            },
        ],
      },
      {
        header: 'Second',
        id: 'Second',
        meta: {
          isSpecialLeftPadding: true
        },
        columns: [
            {
                accessorFn: (row) => row.stats.number_of_second_attempts,
                id: 'Second-#',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value) ? Number(value)?.toLocaleString() : '0';
                },
                header: '#',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Number of customers who made their second purchase attempt'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.percentage_of_successful_second_attempts,
                id: 'Second-%',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: '%',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Percentage of successful second purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.difference_first_and_second_attempt_successes,
                id: 'Second-Difference',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: 'Difference',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Difference between first and second purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
        ],
      },
      {
        header: 'Third',
        id: 'Third',
        meta: {
          isSpecialLeftPadding: true
        },
        columns: [
            {
                accessorFn: (row) => row.stats.number_of_third_attempts,
                id: 'Third-#',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value) ? Number(value)?.toLocaleString() : '0';
                },
                header: '#',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Number of customers who made their third purchase attempt'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.percentage_of_successful_third_attempts,
                id: 'Third-%',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: '%',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Percentage of successful third purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.difference_second_and_third_attempt_successes,
                id: 'Third-Difference',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: 'Difference',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Difference between second and third purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
        ],
      },
      {
        header: 'Fourth',
        id: 'Fourth',
        meta: {
          isSpecialLeftPadding: true
        },
        columns: [
            {
                accessorFn: (row) => row.stats.number_of_fourth_attempts,
                id: 'Fourth-#',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value) ? Number(value)?.toLocaleString() : '0';
                },
                header: '#',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Number of customers who made their fourth purchase attempt'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.percentage_of_successful_fourth_attempts,
                id: 'Fourth-%',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: '%',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Percentage of successful fourth purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.difference_third_and_fourth_attempt_successes,
                id: 'Fourth-Difference',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: 'Difference',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Difference between third and fourth purchase attempts'
                },
                sortingFn: numericStringSortingFn,
            },
        ],
      },
      {
        header: 'Customer',
        id: 'Customer',
        meta: {
          isSpecialLeftPadding: true
        },
        columns: [
            {
                accessorFn: (row) => row.stats.average_attempts_per_customer,
                id: 'Avg. per person',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value) ? Number(value).toFixed(4)?.toLocaleString() : '0';
                },
                header: 'Avg. per person',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Average number of purchase attempts per customer'
                },
                sortingFn: numericStringSortingFn,
            },
            {
                accessorFn: (row) => row.stats.percentage_customers_with_declines,
                id: 'Multiple declines & zero approvals %',
                cell: ({ getValue }) => {
                  const value = getValue();
                  return Number(value)
                    ? `${parseFloat(value?.toString() || '0').toFixed(2)}%`
                    : '0.00%';
                },
                header: 'Multiple declines & zero approvals %',
                meta: {
                  isLastInGroup: true,
                  isAlignTextRight: true,
                  isSecondColumn: true,
                  isTooltip: true,
                  tooltipMessage: 'Percentage of customers who made multiple declines or zero approvals'
                },
                sortingFn: numericStringSortingFn,
            },
            {
              accessorFn: (row) => row.stats.average_time_between_attempt_1_and_2,
              id: 'Avg. Time Between 1 and 2 Purchase Attempts',
              cell: ({ getValue }) => {
                const value = getValue();
                return Number(value) ? Number(value).toFixed(2)?.toLocaleString() : '---';
              },
              header: 'Avg. Time Between 1 and 2 Purchase Attempts',
              meta: {
                isLastInGroup: true,
                isAlignTextRight: true,
                isSecondColumn: true,
                isTooltip: true,
                tooltipMessage: 'Average time between first and second purchase attempts'
              },
              sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: (row) => row.stats.average_time_between_attempt_2_and_3,
            id: 'Avg. Time Between 2 and 3 Purchase Attempts',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value).toFixed(2)?.toLocaleString() : '---';
            },
            header: 'Avg. Time Between 2 and 3 Purchase Attempts',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Average time between second and third purchase attempts'
            },
            sortingFn: numericStringSortingFn,
          },
          {
            accessorFn: (row) => row.stats.average_time_between_attempt_3_and_4,
            id: 'Avg. Time Between 3 and 4 Purchase Attempts',
            cell: ({ getValue }) => {
              const value = getValue();
              return Number(value) ? Number(value).toFixed(2)?.toLocaleString() : '---';
            },
            header: 'Avg. Time Between 3 and 4 Purchase Attempts',
            meta: {
              isLastInGroup: true,
              isAlignTextRight: true,
              isSecondColumn: true,
              isTooltip: true,
              tooltipMessage: 'Average time between third and fourth purchase attempts'
            },
            sortingFn: numericStringSortingFn,
          },
        ],
      },
    ];
}