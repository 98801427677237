import getSymbolFromCurrency from 'currency-symbol-map';
import { RetentionReportData, TransactionReportRowInterface } from '../types/retention-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(data: RetentionReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
  const result: { [key: string]: string }[] = [];
  const groupingLevels = new Set<number>();
  const filtersList = ['Currency', ...filters!];

  function processItem(item: TransactionReportRowInterface, hierarchy: string[] = [], level: number = 0) {
    const baseItem: { [key: string]: string } = {
      customers: item.customers?.toString() || '0',
      cancels: parseFloat(item.cancels).toFixed(2) + ' %' || '0.00',
      chargebacks: item.chargebacks || '0',
      rdr: item.rdr || '0',
      alerts: item.alerts || '0',
      collab_alerts: item.collab_alerts || '0',
      ethoca_alerts: item.ethoca_alerts || '0',
      gross_revenue:
        (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.gross_revenue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      partial_refund:
        (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.partial_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      full_refund:
        (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.full_refund?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      customer_ltv:
        (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.customer_ltv?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
      net: (item.currency ? getSymbolFromCurrency(item.currency) : '') + (item.net?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || ''),
    };

    Object.keys(item).forEach((key) => {
      const cycleMatch = /^cycle(\d+)$/i.exec(key);
      if (cycleMatch) {
        const cycleNumber = cycleMatch[1];
        const cycleData = item[key];

        baseItem[`Cycle ${cycleNumber}+ Rebill Schedule Is Success`] = cycleData?.rebill_schedule_is_success?.toLocaleString() || '0';
        baseItem[`Cycle ${cycleNumber}+ Declined`] = cycleData?.declined?.toLocaleString() || '0';
        baseItem[`Cycle ${cycleNumber}+ Recycle Success`] = cycleData?.recycle_success?.toLocaleString() || '0';
        baseItem[`Cycle ${cycleNumber}+ Recycle Failed`] = cycleData?.recycle_failed?.toLocaleString() || '0';
        baseItem[`Cycle ${cycleNumber}+ Chargebacks`] = cycleData?.retention_chargebacks || '0';
        baseItem[`Cycle ${cycleNumber}+ RDR`] = cycleData?.retention_rdr || '0';

        baseItem[`Cycle ${cycleNumber}+ Retention Ethoca Alerts`] = cycleData?.retention_ethoca_alerts || '0';
        baseItem[`Cycle ${cycleNumber}+ Retention Collab`] = cycleData?.retention_collab_alerts || '0';
        baseItem[`Cycle ${cycleNumber}+ All Alerts`] = cycleData?.retention_alerts || '0';
        baseItem[`Cycle ${cycleNumber}+ Gross Revenue`] =
          cycleData?.currency && cycleData?.retention_gross_revenue != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.retention_gross_revenue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';

        baseItem[`Cycle ${cycleNumber}+ Partial Refund`] =
          cycleData?.currency && cycleData?.retention_partial_refund != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.retention_partial_refund?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';

        baseItem[`Cycle ${cycleNumber}+ Full Refund`] =
          cycleData?.currency && cycleData?.retention_full_refund != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.retention_full_refund?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';

        baseItem[`Cycle ${cycleNumber}+ Cohort Count`] = cycleData?.cohort_count?.toLocaleString() || '0';
        baseItem[`Cycle ${cycleNumber}+ Cohort LTV`] =
          cycleData?.currency && cycleData?.cohort_ltv != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.cohort_ltv?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';

        baseItem[`Cycle ${cycleNumber}+ Cohort Gross`] =
          cycleData?.currency && cycleData?.cohort_gross != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.cohort_gross?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';

        baseItem[`Cycle ${cycleNumber}+ Net`] =
          cycleData?.currency && cycleData?.retention_net != null
            ? `${getSymbolFromCurrency(cycleData?.currency)}${cycleData?.retention_net?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : '---';
      }
    });
    if (item.total) {
      baseItem['Total Avg LTV'] =
        (item?.currency ? getSymbolFromCurrency(item?.currency) : '') +
        (item?.total?.avgLtv?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '---');
      baseItem['Total Gross Revenue'] =
        (item?.currency ? getSymbolFromCurrency(item?.currency) : '') +
        (item?.total?.grossRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '---');
      baseItem['Total Net Revenue'] =
        (item?.currency ? getSymbolFromCurrency(item?.currency) : '') +
        (item?.total?.netRevenueTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '---');
    }

    if (item.name || hierarchy.length > 0) {
      groupingLevels.add(level);
    }

    const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

    baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

    result.push(baseItem);

    Object.keys(namesEnum).forEach((key) => {
      const nestedKey = namesEnum[key];
      if (Array.isArray(item[nestedKey])) {
        item[nestedKey].forEach((nestedItem: TransactionReportRowInterface) => processItem(nestedItem, [...hierarchy, item.name || ''], level + 1));
      }
    });
  }

  if (data.currency && Array.isArray(data.currency)) {
    // @ts-ignore
    data.currency.forEach((currencyItem: DataItem) => {
      processItem(currencyItem);
    });
  }

  const groupingFields = Array.from(groupingLevels).map((level) => {
    const foundKey = getKeyByValue(namesEnum, filtersList[level]);
    return foundKey ?? filtersList[level];
  });

  const transformedResult = result.map((item) => {
    const transformedItem: { [key: string]: string } = {};
    groupingFields.forEach((field) => {
      if (field === 'Currency' && item[field]) {
        transformedItem[field] = `${item[field]?.toUpperCase()} - ${getSymbolFromCurrency(item[field])}`;
      } else {
        transformedItem[field] = item[field] || '';
      }
    });
    Object.keys(item).forEach((key) => {
      if (!groupingFields.includes(key) && !Object.keys(fieldsForCSV).includes(key)) {
        if (Object.values(fieldsForCSV).includes(key)) {
          Object.keys(fieldsForCSV).forEach((key) => {
            transformedItem[key] = item[fieldsForCSV[key]];
          });
        } else {
          transformedItem[key] = item[key];
        }
      }
    });
    return transformedItem;
  });

  return transformedResult;
}

export default transformDataToCSVFormat;
