import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Icon } from '@tremor/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from '@tanstack/react-table';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Tooltip } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = ['GEO', 'Affiliate', 'Merchant Account', 'Offer', 'Subid', 'Merchant Category', 'Product Category', 'Card Brand', 'BIN', 'Descriptor', 'Descriptor Prefix'];

export const namesEnum: { [key: string]: string } = {
  Currency: 'currency',
  GEO: 'geo',
  Affiliate: 'affiliate_name',
  'Merchant Account': 'mid_name',
  Offer: 'funnel_name',
  Subid: 'c1',
  'Merchant Category': 'mid_category',
  'Product Category': 'product_type',
  'Card Brand': 'card_brand',
  BIN: 'card_bin',
  Descriptor: 'descriptor_value',
  'Descriptor Prefix': 'descriptor_prefix',
};

export const transformNames = [
  'currency',
  'geo',
  'affiliate_name',
  'mid_name',
  'funnel_name',
  'c1',
  'mid_category',
  'product_type',
  'card_brand',
  'card_bin',
  'descriptor_value',
  'descriptor_prefix',
];

export const columnHeadings: { width: string; text: string; minWidth: number; hasSortField: boolean }[] = [
  { width: '120px', text: 'Initial Sales', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Initial Declines', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Initial Approval %', minWidth: 95, hasSortField: true },
  { width: '120px', text: 'Reproc Sales', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Reproc Declines', minWidth: 0, hasSortField: true },
  { width: '150px', text: 'Reproc Approval %', minWidth: 95, hasSortField: true },
  { width: '120px', text: 'Rebill Sales', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Rebill Declines', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Rebill Approval %', minWidth: 95, hasSortField: true },
  { width: '120px', text: 'Rebill Gross', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'CB #', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'CB %', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Refunds', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Refund Amount', minWidth: 0, hasSortField: true },

  { width: '120px', text: 'Partial Refunds', minWidth: 0, hasSortField: true },
  { width: '170px', text: 'Partial Refunds Amount', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Pending Txn', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Invalid Txn', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Canceled Orders', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Gross Revenue', minWidth: 0, hasSortField: true },
  { width: '120px', text: 'Net Revenue', minWidth: 0, hasSortField: true },
];

export const fields = {
  'Initial Sales': 'initial_sales',
  'Initial Declines': 'initial_declines',
  'Initial Approval %': 'initial_approval_rate',
  'Reproc Sales': 'reproc_sales',
  'Reproc Declines': 'reproc_declines',
  'Reproc Approval %': 'reproc_approval_rate',
  'Rebill Sales': 'rebill_sales',
  'Rebill Declines': 'rebill_declines',
  'Rebill Approval %': 'rebill_approval_rate',
  'Rebill Gross': 'rebill_gross',
  'CB #': 'charge_back',
  'CB %': 'charge_back_percentage',
  Refunds: 'refunds',
  'Refund Amount': 'refund_amount',
  'Partial Refunds': 'partial_refunds',
  'Partial Refunds Amount': 'partial_refunds_amount',
  'Pending Txn': 'pending',
  'Invalid Txn': 'invalid',
  'Canceled Orders': 'canceled_orders',
  'Gross Revenue': 'gross_revenue',
  'Net Revenue': 'net_revenue',
} as const;

export const fieldsForCSV = {
  'Initial Sales': 'initial_sales',
  'Initial Declines': 'initial_declines',
  'Initial Approval %': 'initial_approval_rate',
  'Reproc Sales': 'reproc_sales',
  'Reproc Declines': 'reproc_declines',
  'Reproc Approval %': 'reproc_approval_rate',
  'Rebill Sales': 'rebill_sales',
  'Rebill Declines': 'rebill_declines',
  'Rebill Approval %': 'rebill_approval_rate',
  'Rebill Gross': 'rebill_gross',
  'CB #': 'charge_back',
  'CB %': 'charge_back_percentage',
  Refunds: 'refunds',
  'Refund Amount': 'refund_amount',
  'Partial Refunds': 'partial_refunds',
  'Partial Refunds Amount': 'partial_refunds_amount',
  'Pending Txn': 'pending',
  'Invalid Txn': 'invalid',
  'Canceled Orders': 'canceled_orders',
  'Gross Revenue': 'gross_revenue',
  'Net Revenue': 'net_revenue',
};

export function getColumns(themedColors: any, searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number): ColumnDef<any>[] {
  return [
    {
      accessorKey: 'name',
      id: 'name',
      header: () => <>Groupings</>,
      cell: ({ row, getValue }) => {
        const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth));

        return (
          <div
            style={{
              paddingLeft: `${row.depth * 2}rem`,
            }}>
            <div className='flex gap-1 items-center flex-nowrap'>
              {row.getCanExpand() ? (
                <button
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}>
                  {row.getIsExpanded() ? (
                    <Icon
                      icon={ChevronDownIcon}
                      style={{ color: themedColors.content }}
                      className='cursor-pointer'
                    />
                  ) : (
                    <Icon
                      icon={ChevronRightIcon}
                      style={{ color: themedColors.content }}
                      className='cursor-pointer'
                    />
                  )}
                </button>
              ) : (
                <span style={{ width: 16 }} />
              )}{' '}
              <span
                className='overflow-hidden text-ellipsis whitespace-nowrap'
                style={{
                  maxWidth: `${350 + Number(groupingsCount) * 8 - (row.depth + 1) * 32}px`,
                }}>
                <span className={hasFlag(getValue<string>()) ? 'pr-3 text-center' : 'text-center hidden'}>
                  {hasFlag(getValue<string>()) ? (
                    <ReactCountryFlag
                      countryCode={getValue<string>()}
                      svg
                      style={{ width: '18px', height: '16px' }}
                    />
                  ) : null}
                </span>
                {highlightMatch(rowName, searchTerm, theme)}
              </span>
            </div>
          </div>
        );
      },
      footer: (props) => props.column.id,
      meta: {
        isLastInGroup: true,
        isPinned: true,
        isGroupingColumn: true,
        isNoBorderHeaderLeft: true,
      },
      filterFn: 'includesStringSensitive',
    },
    {
      accessorFn: (row) => row.stats.initial_sales,
      id: 'Initial Sales',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Initial Sales',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.initial_declines,
      id: 'Initial Declines',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Initial Declines',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.initial_approval_rate,
      id: 'Initial Approval %',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? `${parseFloat(value?.toString() || '0').toFixed(2)}%` : '0.00%';
      },
      header: 'Initial Approval %',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.reproc_sales,
      id: 'Reproc Sales',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Reproc Sales',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.reproc_declines,
      id: 'Reproc Declines',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Reproc Declines',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.reproc_approval_rate,
      id: 'Reproc Approval %',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? `${parseFloat(value?.toString() || '0').toFixed(2)}%` : '0.00%';
      },
      header: 'Reproc Approval %',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.rebill_sales,
      id: 'Rebill Sales',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Rebill Sales',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.rebill_declines,
      id: 'Rebill Declines',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Rebill Declines',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.rebill_approval_rate,
      id: 'Rebill Approval %',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? `${parseFloat(value?.toString() || '0').toFixed(2)}%` : '0.00%';
      },
      header: 'Rebill Approval %',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.rebill_gross,
      id: 'Rebill Gross',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: 'Rebill Gross',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.charge_back,
      id: 'CB #',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'CB #',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.charge_back_percentage,
      id: 'CB %',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? `${parseFloat(value?.toString() || '0').toFixed(2)}%` : '0.00%';
      },
      header: 'CB %',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.refunds,
      id: 'Refunds',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Refunds',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.refund_amount,
      id: 'Refund Amount',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: 'Refund Amount',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.partial_refunds,
      id: 'Partial Refunds',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Partial Refunds',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.partial_refunds_amount,
      id: 'Partial Refunds Amount',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: 'Partial Refunds Amount',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.partial_refunds_amount + row.stats.refund_amount,
      id: 'Total Refunds',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: () => (
        <Tooltip title='This is a combined amount for partial refunds and full refunds'>
          <span>Total Refunds</span>
        </Tooltip>
      ),
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.pending,
      id: 'Pending Txn',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Pending Txn',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.invalid,
      id: 'Invalid Txn',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Invalid Txn',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.canceled_orders,
      id: 'Canceled Orders',
      cell: ({ getValue }) => {
        const value = getValue();
        return Number(value) ? Number(value)?.toLocaleString() : '0';
      },
      header: 'Canceled Orders',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.gross_revenue,
      id: 'Gross Revenue',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: 'Gross Revenue',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
    {
      accessorFn: (row) => row.stats.net_revenue,
      id: 'Net Revenue',
      cell: (info) => {
        const value = info.getValue();
        const currency = info.row.original.stats.currency;
        return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
          Number(value) ? Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'
        }`;
      },
      header: 'Net Revenue',
      meta: {
        isLastInGroup: true,
        isAlignTextRight: true,
        isSecondColumn: true,
      },
      sortingFn: numericStringSortingFn,
    },
  ];
}
