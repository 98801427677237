import React, { useContext, useMemo } from "react";
import {
  Tooltip,
} from "@mui/material";
import CarIcon from "../../../../../assets/icons/CarIcon";
import ProductIcon from "../../../../../assets/icons/ProductIcon";
import PayCard from "../../../../../assets/icons/PayCard";
import PayCards from "../../../../../assets/icons/PayCards";
import { ThemeContext } from "../../../../../context/ThemeContext/ThemeContext";
import capitalizeSpecificWords from "../../../../../helpers/capitalizeSpecificWords";
import { createColumnHelper } from "@tanstack/react-table";
import { tableHeadingNames } from "../utils/config";
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import DefaultTableMessage from "../../../../organism/DefaultTableMessage/DefaultTableMessage";
import SortedArrowAscending from "../../../../../assets/icons/SortedArrowAscending";
import SortedArrowDescending from "../../../../../assets/icons/SortedArrowDescending";
import ProductsIcon from "../../../../../assets/icons/ProductsIcon";
import MiskStat from "../../../../../assets/icons/MiskStat";
import MiskStats from "../../../../../assets/icons/MiskStats";
import { ReportTableCustomColumnMeta } from "../../../../../types/report-table.types";

interface DashboardTableProps {
  data: any[];
  title: keyof typeof tableHeadingNames;
  tableType: string;
  height?: string;
}

const DashboardTable = ({
  data,
  title,
  tableType,
  height,
}: DashboardTableProps) => {
  const { theme, themedColors } = useContext(ThemeContext)!;

  function getIcon(tableType: string | undefined, index: number) {
    const caseKey = `${tableType}-${index}`;

    switch (caseKey) {
      case "car-0":
        return <CarIcon />;
      case "product-0":
        return <ProductIcon />;
      case "card-0":
        return <PayCard />;
      case "misk-0":
        return <MiskStat />;
      default:
        return "";
    }
  }

  function getTitleIcon(tableType: string | undefined, index: number) {
    const caseKey = `${tableType}-${index}`;

    switch (caseKey) {
      case "car-0":
        return <CarIcon />;
      case "product-0":
        return <ProductsIcon />;
      case "card-0":
        return <PayCards />;
      case "misk-0":
        return <MiskStats />;
      default:
        return "";
    }
  }

  const totalCount = useMemo(() => {
    return data.reduce((sum, item) => sum + (item.count || 0), 0);
  }, [data]);

  const columns = [
    createColumnHelper<any>().accessor('name', {
      header: `Card type`,
      cell: info =>
        info.getValue() ? (
          <span className="flex gap-2">
            {getIcon(tableType, 0)}{' '}
            {capitalizeSpecificWords(info.getValue() as string)}
          </span>
        ) : (
          '---'
        ),
      meta: { isFirstColumn: true },
    }),
    createColumnHelper<any>().accessor('count', {
      header: `Count (${totalCount})`,
      cell: info => info.getValue() ? info.getValue() : '---',
      meta: { isBorderLeft: true, isAlignTextRight: true, isLastColumn: true },
    }),
    createColumnHelper<any>().accessor('ratio', {
      header: 'Ratio',
      cell: info => info.getValue() ? `${info.getValue()}%` : '---',
      meta: { isBorderLeft: true, isAlignTextRight: true, isLastColumn: true },
    }),
  ];

  const table = useReactTable({
    columns,
    data: data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
  });

  if (!data) return;

  return (
    <div className="w-full flex flex-col gap-4">
      <h2 
        className="text-textColor-black font-semibold text-xl flex gap-2"
        style={{
          color: themedColors.content
        }}
      >
        {getTitleIcon(tableType, 0)} {title}
      </h2>
      {
          data.length > 0 ? (
            <>
              <table
              className='w-full relative max-w-full border border-blue-50 dark:border-odin-dark-gray-light'
            >
              <thead className='bg-blue-50 dark:bg-odin-dark-gray-light dark:text-odin-dark-content h-[47px]'>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          const isLastInGroup = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isLastInGroup;
                          const isFontBold = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isFontBold;
                          const headerBackground = header.column.getIsSorted() ? 'bg-[#CEE5FC] dark:bg-slate-800' : '';
                          const isAlignTextRight = (header.column.columnDef.meta as ReportTableCustomColumnMeta)?.isAlignTextRight;
    
                          return (
                            <th key={header.id} colSpan={header.colSpan} 
                              style={{
                                borderRight: isLastInGroup ? theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80' : undefined,
                                borderBottom: theme === 'light' ? '1px solid #ACBFDF' : '1px solid #495D80',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                color: themedColors.gray.bluish,
                                fontWeight: isFontBold ? '600' : '500',
                              }}
                              className={`${headerBackground}`}
                            >
                              <div
                                {...{
                                  className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                  onClick: header.column.getToggleSortingHandler(),
                                }}
                                className={`font-inherit text-left ${
                                  header.column.getCanSort() ? 'cursor-pointer' : ''
                                } 
                                  !h-[56px] !py-0 !px-2 flex justify-start items-center`}
                                  style={{
                                    justifyContent: isAlignTextRight ? 'right' : 'left',
                                    fontSize: '12px',
                                    lineHeight: '14.52px',
                                  }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  asc: <span className='ml-2'><SortedArrowAscending /></span>,
                                  desc: <span className='ml-2'><SortedArrowDescending /></span>,
                                }[header.column.getIsSorted() as string] ?? null}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    )
                  )
                })}
              </thead>
    
              <tbody>
                {table.getRowModel().rows.map((row) => {
    
                  return (
                    <tr key={row.id} className='hover:!bg-[#F7F9FC] dark:hover:!bg-[#212C3F]'>
                      {row.getVisibleCells().map((cell) => {
                        const isLastInGroup = (cell.column.columnDef.meta as ReportTableCustomColumnMeta)?.isLastInGroup;
                        const isAlignTextRight = (cell.column.columnDef.meta as ReportTableCustomColumnMeta)?.isAlignTextRight;

                        function getIsTooltop(cell: Cell<any, unknown>) {
                          const cellWidth = cell.column.getSize()
                          const charCount = Math.floor(cellWidth! / 8);

                          return cell?.getValue() !== null && (cell?.getValue() as string)?.length > charCount ? cell?.getValue() : '';
                        }

                        return (
                          <td
                            key={cell.id}
                            id={cell.id}
                            className='!text-sm !h-[42px] !py-0 !px-2 !font-normal'
                            style={{
                              borderBottom: row.getIsExpanded()
                                ? ''
                                :  theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)',
                              color: themedColors.content,
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              borderRight: isLastInGroup ? theme === 'light' ? '1px solid #e0e0e0' : '1px solid rgb(39 51 73)' : undefined,
                              textAlign: isAlignTextRight ? 'right' : 'left'
                            }}
                          >
                            <Tooltip
                              classes={{
                                tooltip: "!py-2 !px-4 !max-w-[245px] !text-xs !font-normal  !rounded-md !text-[#FFFFFF] dark:!text-[#17202E] !bg-[#242546] dark:!bg-[#EBEFF7]",
                                arrow: "!text-[#242546] dark:!text-[#EBEFF7] relative  !bg-opacity-90"
                              }}
                              title={getIsTooltop(cell) as string}
                              placement="left"
                              arrow
                            >
                                <span className='' style={{fontSize: '12px',lineHeight: '16.94px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {flexRender(cell.column.columnDef.cell,cell.getContext())}
                              </span>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
          
          ) : (
            <DefaultTableMessage
              state={'noData'}
            />
          )
        }
    </div>
  );
};

export default DashboardTable;
