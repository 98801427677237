import React, { useContext } from "react";
import CloseIcon from "../../../assets/icons/CloseIcon";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import DefaultButton from "../../UI/buttons/DefaultButton";

interface SessionExpiredModalProps {
    onClose: Function;
    message: string;
}

const BannedByBackend = ({ onClose, message }: SessionExpiredModalProps) => {

    const { theme, themedColors } = useContext(ThemeContext)!;

    const handlePopupClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <>
            <div
                className={`remove-overlay ${theme === 'dark' ? 'bg-[rgba(0,0,0,0.8)]' : 'bg-[rgba(105,105,105,0.8)]'}`}
                onClick={() => onClose()}
            >
                <div
                    className="remove-popup gap-6 max-w-[297px] tablet-size:max-w-[390px] !h-[290px]"
                    style={{ backgroundColor: `${theme === 'dark' ? themedColors.blue.dark : themedColors.white}` }}
                    onClick={handlePopupClick}
                >
                    <button
                        className={"cursor-pointer p-2"}
                        style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer', color: `${themedColors.content}` }}
                        onClick={() => onClose()}
                    >
                        <CloseIcon />
                    </button>

                    <div className="loading-text-cover">
                        <p className="remove-text" style={{ color: themedColors.content }}>{message}</p>
                    </div>

                    <DefaultButton type="button" label="Close" onClick={() => onClose()} className="!text-sm w-full" />
                </div>
            </div>
        </>
    );
};

export default BannedByBackend;
