import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Icon } from '@tremor/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import highlightMatch from '../../../../../helpers/highlightMatch';
import formatRowName from '../../../../../helpers/formatRowName';
import { hasFlag } from 'country-flag-icons';
import ReactCountryFlag from 'react-country-flag';
import { numericStringSortingFn } from '../../../../../helpers/numericStringSortingFn';
import { ColumnDef } from '@tanstack/react-table';
import getSymbolFromCurrency from 'currency-symbol-map';

dayjs.extend(utc);
dayjs.extend(timezone);

export const names = ['GEO', 'Affiliate', 'Merchant Account', 'Offer', 'Subid', 'Merchant Category', 'Campaign Category', 'Product Category', 'Card Brand'];

export const namesEnum: { [key: string]: string } = {
  Currency: 'currency',
  GEO: 'geo',
  Affiliate: 'affiliate_name',
  'Merchant Account': 'mid_name',
  Offer: 'funnel_name',
  Subid: 'c1',
  'Merchant Category': 'mid_category',
  'Campaign Category': 'campaign_category_name',
  'Product Category': 'product_category',
  'Card Brand': 'card_brand',
};

export const transformNames = ['currency', 'geo', 'affiliate_name', 'mid_name', 'funnel_name', 'c1', 'mid_category', 'campaign_category_name', 'product_category', 'card_brand'];

interface IRowData {
  id: number;
  name: string;
  fontSize: string;
  width: string;
  padding: string;
  isTips: boolean;
  tips: string;
  tips2: string;
  hasSortField: boolean;
  border?: boolean;
}

export const firstRowData: IRowData[] = [
  {
    id: 1,
    name: 'Cancels',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 3,
    name: 'Chargebacks',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 4,
    name: 'RDR',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: true,
    tips: 'Chargebacks / (Chargebacks + Sales)',
    tips2: 'Chargebacks % = ',
    hasSortField: true,
  },
  {
    id: 5,
    name: 'All Alerts',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 6,
    name: 'Ethoca Alerts',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 7,
    name: 'Collab',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 8,
    name: 'Gross Revenue',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: true,
    tips: 'Refunds / (Refunds + Sales)',
    tips2: 'Refunds % = ',
    hasSortField: true,
  },
  {
    id: 8,
    name: 'Partial Refund',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 10,
    name: 'Full Refund',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
  },
  {
    id: 11,
    name: 'Net',
    fontSize: '12px',
    width: '150px',
    padding: '16px 8px 16px 0px',
    isTips: false,
    tips: '',
    tips2: '',
    hasSortField: true,
    border: true,
  },
];

export const fields = {
  Cancels: 'cancels',
  Chargebacks: 'chargebacks',
  RDR: 'rdr',
  'Ethoca Alerts': 'ethoca_alerts',
  Collab: 'collab_alerts',
  'All Alerts': 'alerts',
  'Gross Revenue': 'gross_revenue',
  'Partial Refund': 'partial_refund',
  'Full Refund': 'full_refund',
  'Customer LTV': 'customer_ltv',
  Net: 'net',
} as const;

export const fieldsForCSV = {
  Cancels: 'cancels',
  Chargebacks: 'chargebacks',
  RDR: 'rdr',
  'Ethoca Alerts': 'ethoca_alerts',
  Collab: 'collab_alerts',
  'All Alerts': 'alerts',
  'Gross Revenue': 'gross_revenue',
  'Partial Refund': 'partial_refund',
  'Full Refund': 'full_refund',
  'Customer LTV': 'customer_ltv',
  Net: 'net',
};

export function getColumns(themedColors: any, searchTerm: string, theme: 'light' | 'dark' = 'light', groupingsCount?: number, cycleKeys?: string[]): ColumnDef<any>[] {
  console.log(cycleKeys);
  return [
    {
      header: '',
      id: 'groupingsGroup',
      meta: {
        isLastInGroup: true,
        isPinned: true,
        isGroupingColumn: true,
        isNoBorderHeaderLeft: true,
      },
      columns: [
        {
          accessorKey: 'name',
          header: 'Groupings',
          cell: ({ row, getValue }) => {
            const rowName = formatRowName(getValue<string>(), row.original.stats.currency, Number(row.depth));

            return (
              <div
                style={{
                  paddingLeft: `${row.depth * 2}rem`,
                }}>
                <div className='flex gap-1 items-center flex-nowrap'>
                  {row.getCanExpand() ? (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: 'pointer' },
                      }}>
                      {row.getIsExpanded() ? (
                        <Icon
                          icon={ChevronDownIcon}
                          style={{ color: themedColors.content }}
                          className='cursor-pointer'
                        />
                      ) : (
                        <Icon
                          icon={ChevronRightIcon}
                          style={{ color: themedColors.content }}
                          className='cursor-pointer'
                        />
                      )}
                    </button>
                  ) : (
                    <span style={{ width: 16 }} />
                  )}{' '}
                  <span
                    className='overflow-hidden text-ellipsis whitespace-nowrap'
                    style={{
                      maxWidth: `${350 + Number(groupingsCount) * 8 - (row.depth + 1) * 32}px`,
                    }}>
                    <span className={hasFlag(getValue<string>()) ? 'pr-3 text-center' : 'text-center hidden'}>
                      {hasFlag(getValue<string>()) ? (
                        <ReactCountryFlag
                          countryCode={getValue<string>()}
                          svg
                          style={{ width: '18px', height: '16px' }}
                        />
                      ) : null}
                    </span>
                    {highlightMatch(rowName, searchTerm, theme)}
                  </span>
                </div>
              </div>
            );
          },
          footer: (props) => props.column.id,
          meta: {
            isLastInGroup: true,
            isPinned: true,
            isGroupingColumn: true,
            isNoBorderHeaderLeft: true,
          },
          filterFn: 'includesStringSensitive',
        },
      ],
    },
    {
      header: 'Initial',
      id: 'initialGroup',
      meta: {
        isSpecialLeftPadding: true,
      },
      columns: [
        {
          accessorFn: (row) => row.stats.customers,
          id: 'Customers',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Customers',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.cancels,
          id: 'Cancels',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? `${parseFloat(value?.toString() || '0').toFixed(2)}%` : '0.00%';
          },
          header: 'Cancels',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.chargebacks,
          id: 'Chargebacks',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Chargebacks',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.rdr,
          id: 'RDR',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'RDR',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },

        {
          accessorFn: (row) => row.stats.ethoca_alerts,
          id: 'Ethoca Alerts',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Ethoca Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.collab_alerts,
          id: 'Collab',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Collab',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.alerts,
          id: 'All Alerts',
          cell: ({ getValue }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'All Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.gross_revenue,
          id: 'Gross Revenue',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Gross Revenue',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.partial_refund,
          id: 'Partial Refund',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Partial Refund',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.full_refund,
          id: 'Full Refund',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Full Refund',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.customer_ltv,
          id: 'Customer LTV',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Customer LTV',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.net,
          id: 'Net',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Net',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
      ],
    },
    ...(cycleKeys?.map((key, index) => ({
      header: `Cycle ${key.replace(/\D/g, "")}+`,
      id: `${key}Group`,
      meta: {
        isSpecialLeftPadding: true,
      },
      columns: [
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.rebill_schedule_is_success || 0);
              }, 0);
          },
          // accessorFn: (row: any) => row.stats?.[key]?.rebill_schedule_is_success,
          id: `${key}+ Success`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Success',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.declined || 0);
              }, 0);
          },
          // accessorFn: (row: any) => row.stats?.[key]?.declined,
          id: `${key}+ Declined`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Declined',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.recycle_success || 0);
              }, 0);
          },
          id: `${key}+ Recycle Success`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Recycle Success',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.recycle_failed || 0);
              }, 0);
          },
          id: `${key}+ Recycle Failed`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Recycle Failed',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_chargebacks || 0);
              }, 0);
          },
          id: `${key}+ Chargebacks`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Chargebacks',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_rdr || 0);
              }, 0);
          },
          id: `${key}+ RDR`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'RDR',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_ethoca_alerts || 0);
              }, 0);
          },
          id: `${key}+ Ethoca Alerts`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Ethoca Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_collab_alerts || 0);
              }, 0);
          },
          id: `${key}+ Collab`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Collab',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_alerts || 0);
              }, 0);
          },
          id: `${key}+ Alerts`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'All Alerts',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_gross_revenue || 0);
              }, 0);
          },
          id: `${key}+ Gross Revenue`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Gross Revenue',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_partial_refund || 0);
              }, 0);
          },
          id: `${key}+ Partial Refund`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Partial Refund',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_full_refund || 0);
              }, 0);
          },
          id: `${key}+ Full Refund`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Full Refund',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.cohort_count || 0);
              }, 0);
          },
          id: `${key}+ Cohort Count`,
          cell: ({ getValue }: { getValue: any }) => {
            const value = getValue();
            return Number(value) ? Number(value)?.toLocaleString() : '0';
          },
          header: 'Cohort Count',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.cohort_ltv || 0);
              }, 0);
          },
          id: `${key}+ Cohort LTV`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Cohort LTV',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.cohort_gross || 0);
              }, 0);
          },
          id: `${key}+ Cohort Gross`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Cohort Gross',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row: any) => {
            return cycleKeys
              ?.slice(index)
              ?.reduce((sum, cycleKey) => {
                return sum + (row.stats?.[cycleKey]?.retention_net || 0);
              }, 0);
          },
          id: `${key}+ Nets`,
          cell: (info: any) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Net',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
      ],
    })) ?? []),
    
    {
      header: 'Total',
      id: 'totalGroup',
      meta: {
        isSpecialLeftPadding: true,
      },
      columns: [
        {
          accessorFn: (row) => row.stats.avgLtv,
          id: 'Total AVG LTV',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'AVG LTV',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.grossRevenueTotal,
          id: 'Total Gross Revenue',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Gross Revenue',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
        {
          accessorFn: (row) => row.stats.netRevenueTotal,
          id: 'Total Net',
          cell: (info) => {
            const value = info.getValue();
            const currency = info.row.original.stats.currency;
            return `${currency && currency !== 'NaN' ? (getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : currency) : ''}${
              Number(value)
                ? Number(value).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : '0.00'
            }`;
          },
          header: 'Net',
          meta: {
            isLastInGroup: true,
            isAlignTextRight: true,
            isSecondColumn: true,
          },
          sortingFn: numericStringSortingFn,
        },
      ],
    },
  ];
}
