import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { UserContext } from '../../../context/UserContext/UserContext';
import { Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Upload from '../../../assets/icons/Upload';
import { ThemeContext } from '../../../context/ThemeContext/ThemeContext';
import DeleteAvatar from '../../../assets/icons/DeleteAvatar';
import DefaultButton from '../../UI/buttons/DefaultButton';
import CloseIcon from '../../../assets/icons/CloseIcon';
import Utils from '../../../odinForgeService/Utils';
import "./ProfileImageController.css";
import OdinForgeService from '../../../odinForgeService/OdinForgeService';
import RemovePopup from '../../UI/removePopup/RemovePopup';
import useMediaQuery from '../../../hooks/useMediaQuery';
import resizeImage from './utils/resizeImage';
import TableLoading from '../../UI/loading/TableLoading';

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ProfileImageController = () => {
  const userContext = useContext(UserContext)!;
  const { setAvatar,avatar, name } = userContext;
  const { theme, themedColors } = useContext(ThemeContext)!;
  const [ isHovered, setIsHovered ] = useState(false);

  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50
  })
  const [src, setSrc] = useState('');
  const { getRootProps, getInputProps, acceptedFiles, isDragAccept, isFocused, isDragReject } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [aspect, setAspect] = useState<number | undefined>(1)
  const imgRef = useRef<HTMLImageElement>(null)
  const blobUrlRef = useRef('')

  const [ isShowDropZone, setIsShowDropZone ] = useState(false);

  const [ isShowDeletePopup, setIsShowDeletePopup ] = useState(false);

  const [ isLoading, setIsLoading ] = useState(false);

  const isMobile = useMediaQuery('(max-width: 1023px)')

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => setSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  }, [acceptedFiles]);  

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      const initialCrop = centerAspectCrop(width, height, aspect);
  
      // Set initial crop state
      setCrop(initialCrop);
  
      // Directly set the completed crop as PixelCrop
      setCompletedCrop({
        unit: 'px',
        x: (initialCrop.x / 100) * width,
        y: (initialCrop.y / 100) * height,
        width: (initialCrop.width / 100) * width,
        height: (initialCrop.height / 100) * height,
      });
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!imgRef.current || !completedCrop) {
      console.error("Image reference or crop data is missing.");
      return;
    }
  
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
  
    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
  
    if (!ctx) {
      console.error("Unable to get 2D context.");
      return;
    }
  
    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
  
    // Create a Blob from the canvas
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });
  
    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }

    try {
      const resizedBlob = await resizeImage(new File([blob], "profile_image.png"),800, 800, 0.7);
      const response = await OdinForgeService.instance().updateProfileImage(resizedBlob);
      if (response.data.status) {
        Utils.instance().onSuccess(response.data.message);
        setAvatar(
          response.data.profile_picture.url
            ? `${process.env.REACT_APP_BASE_URL}/${response.data.profile_picture.url}?t=${new Date().getTime()}`
            : null
        );
        setIsShowDropZone(false);
        setSrc('')
        setCompletedCrop(undefined)
        window.location.reload();
      }
    } catch (error) {
      console.error("Error uploading the image", error);

      Utils.instance().onFailure('Size of the image is too large. Please decrease the area and try again.');
    } finally {
      setIsLoading(false);
    }
  }

  const handleUploadClick = () => {
    setIsShowDropZone(true)
  }

  const handleDeleteClick = () => {
    setIsShowDeletePopup(true)
  }

  const deleteProfileImage = async () => {
    try {
      const response = await OdinForgeService.instance().deleteProfileImage();
      if (response.data.status) {
        Utils.instance().onSuccess(response.data.message);
        setAvatar(null);
        setIsShowDeletePopup(false);
      }
    } catch (error) {
      console.error("Error deleting the image", error);
    }
  }

  const baseStyle : React.CSSProperties = {
    flex: 1,
    display: !!src ? 'none' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: '8px',
    borderColor: '#ACBFDF',
    borderStyle: 'dashed',
    backgroundColor: theme === 'light' ? '#EAEEF6' : '',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#1976d2'
  };
  
  const acceptStyle = {
    borderColor: '#1976d2',
  };
  
  const rejectStyle = {
    borderColor: 'red'
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  

  return ( 
      <>
        <div
          onMouseLeave={() => setIsHovered(false)}
          onMouseEnter={() => setIsHovered(true)} 
          className='w-fit relative'
        >
          <Avatar
            alt={name ?? ''}
            sx={{ width:180, height:180}}
            src={avatar ?? ''}
          />  
          {
            isHovered ? !avatar ? (
              <div className='absolute top-0 w-[180px] h-[180px] bg-gray-600 dark:bg-black  rounded-full flex flex-col items-center justify-center opacity-70 dark:opacity-60'>
                <button className='flex gap-3' onClick={handleUploadClick}>
                  <p style={{color: theme === 'light' ? '#FFFFFF' : '#FFFFFF'}} className='text-base'>Upload</p>
                  <Upload />
                </button>
              </div>
            ) : (
              <div className='absolute top-0 w-[180px] h-[180px] bg-gray-600 dark:bg-black rounded-full flex flex-col gap-3 items-center justify-center opacity-70 dark:opacity-60'>
                <button className='flex gap-3' onClick={handleUploadClick}>
                  <p style={{color: theme === 'light' ? '#FFFFFF' : '#FFFFFF'}} className='text-base'>Upload</p>
                  <Upload />
                </button>
                <button className='flex gap-3' onClick={handleDeleteClick}>
                  <p style={{color: theme === 'light' ? '#FF3B30' : '#FF3B30'}} className='text-base'>Delete</p>
                  <DeleteAvatar />
                </button>
              </div>
            ) : null
          }
          
        </div>
        {
          isShowDropZone && (
            <Dialog
                fullWidth={true}
                open={isShowDropZone}
                className="tablet-size:block"
                onClose={() => {
                    setIsShowDropZone(false)
                    setSrc('')
                    setCompletedCrop(undefined)
                }}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit,
                    onReset: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault()
                        setIsShowDropZone(false)
                        setSrc('')
                        setCompletedCrop(undefined)
                    },
                    sx: {
                        width: '844px',
                        maxWidth: '100%',
                    },
                }}  
            >
              {
                isLoading && (
                  <div className='fixed z-20 inset-0 flex items-center justify-center'>
                    <div className='absolute inset-0 bg-black opacity-50'></div>
                    <div className='relative z-30'>
                      <TableLoading />
                    </div>
                  </div>
                )
              }
                <div className={'flex flex-row justify-end absolute right-4 top-4'}>
                    <button
                        aria-label="close"
                        type={'reset'}
                        style={{
                            position: 'relative',
                            color: '#ACBFDF',
                            justifyContent: 'flex-end',
                            padding: 3
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <DialogTitle 
                    sx={{fontWeight: 'bold', paddingTop: !isMobile ? 8 : 6, paddingLeft: 5, paddingRight: 5, paddingBottom: 0}}
                >
                    Upload Picture
                </DialogTitle>
                <DialogContent style={{paddingTop: !isMobile ? 24 : 16, paddingLeft: !isMobile ? 40 : 16, paddingRight: !isMobile ? 40 : 16, paddingBottom: 0}}>
                    <DialogContentText style={{color: themedColors.content, lineHeight: '19px'}}>
                      <section className={` flex items-center justify-center flex-col`} style={{height: !src ? '340px' : '340px'}}>
                      {!src && (
                        <div {...getRootProps({style})} className='w-full h-full flex items-center justify-center flex-col'>
                          <input {...getInputProps()}  className='w-full h-full flex items-center justify-center flex-col' />
                          <p>Drag and drop an image file here or click</p>
                        </div>
                      )}
                        
                        {!!src && (
                          <ReactCrop crop={crop} onChange={newCrop => setCrop(newCrop)} onComplete={(c) => setCompletedCrop(c)}>
                            <img className={`!max-h-[340px]`} ref={imgRef} alt="Crop preview" src={src} onLoad={onImageLoad} />
                          </ReactCrop>
                        )}
                      </section>
                    </DialogContentText>
                    <DialogActions sx={{
                                justifyContent: 'start',
                                paddingBottom: 6,
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 4,
                                gap: 1,
                                width: '100%'
                            }}>
                                <DefaultButton disabled={!completedCrop} type='submit' label='Submit'  className="w-full h-10" />
                                <DefaultButton disabled={false} type={'reset'} label='Cancel' variant='outlined'  className="w-full h-10" />
                            </DialogActions>
                </DialogContent>
            </Dialog>
          )
        }

        {isShowDeletePopup && <RemovePopup onClose={() => setIsShowDeletePopup(false)} onNoClick={() => setIsShowDeletePopup(false)}
                                      removeText="Confirm deleting profile picture?"
                                      onYesClick={() => deleteProfileImage()}/>}

        

      </>

  );
};

export default ProfileImageController;
