import getSymbolFromCurrency from 'currency-symbol-map';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';
import { CapProjectionsReportData, CapProjectionsReportRowInterface } from '../types/cap-projections-report.types';
function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(data: CapProjectionsReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
    const result: { [key: string]: string | number }[] = [];
    const groupingLevels = new Set<number>();
    const filtersList = ['Currency', ...filters!] 

    function processItem(item: CapProjectionsReportRowInterface, hierarchy: string[] = [], level: number = 0) {
      const baseItem: { [key: string]: string | number } = {
        total_monthly_cap: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.total_monthly_cap) ? Number(item.total_monthly_cap).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        sales_month_to_date: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.sales_month_to_date) ? Number(item.sales_month_to_date).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        monthly_cap_remaining: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.monthly_cap_remaining) ? Number(item.monthly_cap_remaining).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        month_to_date_aov: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.month_to_date_aov) ? Number(item.month_to_date_aov).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        projected_number_of_rebills: item.projected_number_of_rebills !== null || item.projected_number_of_rebills !== undefined ? (Number(item.projected_number_of_rebills))?.toLocaleString() : '0',
        projected_amount_of_rebills: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.projected_amount_of_rebills) ? Number(item.projected_amount_of_rebills).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        daily_sales_cap_remaining: item.daily_sales_cap_remaining !== null || item.daily_sales_cap_remaining !== undefined ? (Number(item.daily_sales_cap_remaining))?.toLocaleString() : '0',
        total_daily_cap: item.total_daily_cap !== null || item.total_daily_cap !== undefined ? (Number(item.total_daily_cap))?.toLocaleString() : '0',
      };

      if (item.name || hierarchy.length > 0) {
        groupingLevels.add(level);
      }

      const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

      baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

      result.push(baseItem);

      Object.keys(namesEnum).forEach((key) => {
        const nestedKey = namesEnum[key];
        if (Array.isArray(item[nestedKey])) {
          item[nestedKey].forEach((nestedItem: CapProjectionsReportRowInterface) =>
            processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
          );
        }
      });
    }

    if (data.currency && Array.isArray(data.currency)) {
      // @ts-ignore
      data.currency.forEach((currency) => processItem(currency));
    }

    const groupingFields = Array.from(groupingLevels).map((level) => {
      const foundKey = getKeyByValue(namesEnum, filtersList[level]);
      return foundKey ?? filtersList[level];
    });

    return result.map((item) => {
      const transformedItem: { [key: string]: string | number } = {};
      groupingFields.forEach((field) => {
        if (field === 'Currency' && item[field]) {
          transformedItem[field] = `${String(item[field])?.toUpperCase()} - ${getSymbolFromCurrency(String(item[field]))}`;
        } else {
          transformedItem[field] = item[field] || '';
        }
      });
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[fieldsForCSV[key]];
      });
      return transformedItem;
    });
  }

  export default transformDataToCSVFormat;