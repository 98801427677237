import { DuplicateCustomerReportData } from "../types/duplicate-customer-report.types";

const transformDataToCSVFormat = (
  data: DuplicateCustomerReportData[]
): { [key: string]: string | number }[] => {
  if (!data || !data.length) {
    return [];
  }

  const transformedData: { [key: string]: string | number }[] = [];

  data.forEach((item) => {
    transformedData.push({
      "Customer ID": item.customer_id,
      'Txns Duplicate': item.number_of_duplicate_txns,
      'Initial Sales Duplicate': item.number_of_duplicate_initial_sales,
      'Rebill Duplicate': item.number_of_duplicate_rebill_sales,
      'Reproc': item.number_of_duplicate_reproc_sales,
      'Product Vertical': item.product_vertical_is_same === 1 ? "Yes" : "No",
      'Nested Table Indicator': "Total"
    });

    item.details.forEach((detail) => {
      transformedData.push({
        "Customer ID": "",
        'Txns Duplicate': "",
        'Initial Sales Duplicate': "",
        'Rebill Duplicate': "", 
        'Reproc': "", 
        'Product Vertical': "",
        'Date': detail.transacted_at_date,
        'Detail Txns Duplicate': detail.number_of_duplicate_txns,
        'Detail Initial Sales Duplicate': detail.number_of_duplicate_initial_sales,
        'Detail Rebill Duplicate': detail.number_of_duplicate_rebill_sales,
        'Detail Reproc': detail.number_of_duplicate_reproc_sales,
        'Detail Product Vertical': detail.product_vertical_is_same === 1 ? "Yes" : "No",
        'Nested Table Indicator': "Details Row"
      });
    });
  });

  return transformedData;
};

export default transformDataToCSVFormat;
