import React, { useContext, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import ReportName from '../../../../odinForgeService/Enums/ReportName';
import OdinForgeService from '../../../../odinForgeService/OdinForgeService';
import Utils from '../../../../odinForgeService/Utils';
import '../../../../css/PaceReport.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { TotalSectionComponent } from './components/TotalSectionComponent';
import FilterBar from '../../../organism/filterbar/FilterBar';
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import { prepareDataForTransform, transformData } from './utils/transformData';
import ChartsSkeleton from './skeletons/ChartsSkeleton';
import { determineNestingLevels, reorderLevels, updateExpandedState } from '../../../../helpers/collapsingFunctions';
import { fieldsForCSV, getColumns, namesEnum } from './utils/config';
import transformDataToCSVFormat from './utils/transformDataToCSVFormat';
import { formatLocalISO } from '../../../../helpers/formatLocalISO';
import { createHash } from '../../../../helpers/createHash';
import { UserContext } from '../../../../context/UserContext/UserContext';
import {
  CancellationSubscriptionReportClonedData,
  CancellationSubscriptionReportData,
  NestedLevels,
  TransformedCancellationSubscriptionReportData,
} from './types/cancellation-subscription-report.types';
import DefaultTableMessage from '../../../organism/DefaultTableMessage/DefaultTableMessage';
import { DataRangesParamsList, dateRangeList } from '../../../../types/enums/DataRangesParamsList';
import { generateRangeConfigs } from '../../../../types/enums/RangeConfigs';
import ReportTable from '../../../organism/ReportTable/ReportTable';

dayjs.extend(utc);
dayjs.extend(timezone);

interface props {
  names: string[];
  clonedData: CancellationSubscriptionReportClonedData;
  passData: (data: CancellationSubscriptionReportClonedData) => void;
  passId: (id: string | number | null | undefined) => void;
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>;
  refreshKey: number;
}
const CancellationSubscriptionReport: React.FC<props> = ({ names, clonedData, passData, passId, setRefreshKey, refreshKey }: props) => {
  const { theme } = useContext(ThemeContext)!;
  const { timezone, tenantId, superTenant, isSuperAdmin } = useContext(UserContext)!;
  const [groupings, setGroupings] = React.useState<string[]>(clonedData['group_sequence'] ? clonedData['group_sequence'] : []);
  const [selectedGroupings, setSelectedGroupings] = React.useState(3);
  const [collapse, setCollapse] = React.useState(clonedData['expand_level'] ? clonedData['expand_level'] : '0');
  const [copyButton, setCopyButton] = React.useState(!clonedData['group_sequence']);
  const [freezeButton, setFreezeButton] = React.useState(!clonedData['group_sequence']);
  const [startDate, setStartDate] = React.useState<string | Dayjs>(clonedData['start_date'] ? dayjs.tz(clonedData['start_date']) : dayjs.tz().startOf('day'));
  const [endDate, setEndDate] = React.useState<string | Dayjs>(clonedData['end_date'] ? dayjs.tz(clonedData['end_date']) : dayjs.tz());
  const params = new URLSearchParams();
  const [showTable, setShowTable] = React.useState(!!clonedData['data']);
  const [data, setData] = React.useState<CancellationSubscriptionReportData | []>(clonedData['data'] ? clonedData['data'] : []);
  const expandLevel = collapse;
  const reportName = ReportName.CancellationSubscriptionReasons;
  const groupSequence = groupings;
  const reportConfigValues = useRef({ expandLevel, reportName, groupSequence });
  const [clonedReportId] = React.useState(clonedData['id']);
  const [showDelete] = React.useState(clonedData['type'] === 'cloned');
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [dateRange, setDateRange] = useState(clonedData['date_range'] ? clonedData['date_range'] : 0);
  const [previousGroupings, setPreviousGroupings] = useState<string[]>([]);
  const [previousSuperTenant, setPreviousSuperTenant] = useState<string | null>(superTenant);
  const [previousStartDate, setPreviousStartDate] = useState(startDate);
  const [previousEndDate, setPreviousEndDate] = useState(endDate);

  const [isChartLoader, setIsChartLoader] = useState(false);
  const [chartData, setChartData] = useState<CancellationSubscriptionReportData | []>([]);
  const [isChartData, setIsChartData] = useState(false);

  const [nestedLevels, setNestedLevels] = useState<NestedLevels>({});

  const isInitialLoad = useRef(true);

  const isInitialLoadCollapse = useRef(true);

  const [loaderKey, setLoaderKey] = useState(0);

  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');

  const [globalFilter, setGlobalFilter] = useState('');
  const [isNoData, setIsNoData] = useState(false);
  const [transformedTableDataCSV, setTransformedTableDataCSV] = useState<TransformedCancellationSubscriptionReportData[]>([]);

  const [csvFilters, setCsvFilters] = useState<string[]>([]);

  useEffect(() => {
    if (data && collapse !== undefined) {
      const updatedLevels = updateExpandedState(nestedLevels, Number(collapse));
      setNestedLevels(updatedLevels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse, data]);

  useEffect(() => {
    if (dateRange !== 0) handleBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((startDate as Dayjs).isAfter(endDate)) {
      setEndDate(startDate);
    }
  }, [startDate, endDate]);

  const previousValuesRef = useRef({
    groupings: JSON.stringify(groupings),
    collapse: collapse,
    dateRange: dateRange,
  });

  useEffect(() => {
    const isButtonActive = selectedGroupings > 0;

    const startDateStr = (startDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
    const endDateStr = (endDate as Dayjs).format('YYYY-MM-DDTHH:mm:ss');
    const clonedStartDateStr = dayjs(clonedData['start_date']).format('YYYY-MM-DDTHH:mm:ss');
    const clonedEndDateStr = dayjs(clonedData['end_date']).format('YYYY-MM-DDTHH:mm:ss');

    const valuesChanged =
      collapse !== clonedData['expand_level'] ||
      JSON.stringify(groupings) !== JSON.stringify(clonedData['group_sequence']) ||
      dateRange !== clonedData['date_range'] ||
      startDateStr !== clonedStartDateStr ||
      endDateStr !== clonedEndDateStr;
    if (valuesChanged) {
      setFreezeButton(!isButtonActive);
    }
    if (!valuesChanged) {
      setFreezeButton(true);
    }
    reportConfigValues.current = { expandLevel, reportName, groupSequence };

    // Refetch if date range is not custom
    const previousValues = previousValuesRef.current;
    if (dateRange && (previousValues.groupings !== JSON.stringify(groupings) || previousValues.collapse !== collapse || previousValues.dateRange !== dateRange)) {
      handleBlur();
      previousValuesRef.current = {
        groupings: JSON.stringify(groupings),
        collapse: collapse,
        dateRange: dateRange,
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupings, reportName, dateRange, startDate, endDate]);

  useEffect(() => {
    if (dateRange === 0) {
      handleBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupings]);

  useEffect(() => {
    previousValuesRef.current = {
      groupings: JSON.stringify(groupings),
      collapse: collapse,
      dateRange: dateRange,
    };
  }, [groupings, collapse, reportName, dateRange, theme]);

  function generateParams() {
    if (dateRange === 0) {
      params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
      params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
    } else {
      const { startDate, endDate } = generateRangeConfigs(timezone)[dateRange];

      params.append('start_date', formatLocalISO((startDate as Dayjs).set('second', 0o0)));
      params.append('end_date', formatLocalISO((endDate as Dayjs).set('second', 59)));
    }

    let filters: string[] = [];
    groupings.forEach((value) => {
      filters.push(namesEnum[value]);
    });
    filters.forEach((value, index) => {
      params.append(`filters[${index}]`, value);
    });
    params.append('timezone', timezone);

    if (isSuperAdmin && superTenant) {
      superTenant?.toString() && params.append('tenant', superTenant?.toString());
    } else {
      tenantId?.toString() && params.append('tenant', tenantId?.toString());
    }

    params.append('date_range', DataRangesParamsList[dateRangeList[dateRange] as keyof typeof DataRangesParamsList]);
  }

  function generateObjectToHash() {
    let filters: string[] = [];
    groupings.forEach((value) => {
      filters.push(namesEnum[value]);
    });
    const dataToHash: object = {
      filters: filters,
      startDate: (startDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
      endDate: (endDate as Dayjs).set('second', 0o0).format('YYYY-MM-DD HH:mm:ss'),
    };

    return dataToHash;
  }

  const isDataSame = (
    groupings: string[],
    previousGroupings: string[],
    startDate: string | Dayjs,
    previousStartDate: string | Dayjs,
    superTenant: string | null = null,
    previousSuperTenant: string | null = null
  ) => {
    if (!isSuperAdmin) {
      return groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate;
    }
    return groupings === previousGroupings && startDate === previousStartDate && endDate === previousEndDate && superTenant === previousSuperTenant;
  };

  useEffect(() => {
    if (!isInitialLoad.current) {
      handleBlur();
    } else {
      isInitialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superTenant]);

  async function handleBlur(isRefresh?: boolean) {
    if (tenantId === null) return;
    setShowTable(false);
    setTableLoading(true);
    if (groupings.length === 0) {
      setTableLoading(false);
      return;
    }
    if (isRefresh) {
    } else {
      setSearchTerm('');
      localStorage.removeItem('searchTerm');
    }
    generateParams();
    if (!isDataSame(groupings, previousGroupings, startDate, previousStartDate, superTenant, previousSuperTenant) || isRefresh) {
      setData([]);
      OdinForgeService.instance()
        .getCancellationSubscriptionReasons(params, clonedReportId)
        .then(async (result: { data: CancellationSubscriptionReportData; cacheTime: number }) => {
          setTableLoading(false);
          const transformedData = await transformData(result.data);

          if (result.data[namesEnum[groupings[0]]] && (result.data[namesEnum[groupings[0]]]! as []).length !== 0) {
            // @ts-ignore
            setData(transformedData);
            const csvData = prepareDataForTransform(result.data);
            // @ts-ignore
            setTransformedTableDataCSV(csvData);
            setNestedLevels(reorderLevels(determineNestingLevels(transformedData, 1, 'currency', namesEnum)));
            setSelectedGroupings(groupings.length);
            if (Number(collapse) > groupings.length) {
              setCollapse(groupings.length.toString());
            }
            setShowTable(true);
            getChartData();

            createHash(generateObjectToHash, result.data, reportName, result.cacheTime);

            // It is for expanding the table to the last level
            if (!isInitialLoadCollapse.current) {
              setCollapse(groupings.length.toString());
            } else {
              if (collapse === '0') {
                setCollapse('1');
              }
              isInitialLoadCollapse.current = false;
            }
          } else {
            setIsChartData(false);
            setTableLoading(false);
            setData([]);
          }
        })
        .catch((error) => {
          if (error.code === 'ERR_CANCELED') {
            setLoaderKey((prevKey) => prevKey + 1);
            console.log('Request canceled');
          } else {
            setIsChartData(false);
            setTableLoading(false);
            Utils.instance().onFailure('An error occurred while fetching the report');
          }
        });
    } else {
      if (data.length !== 0) setShowTable(true);
      setTableLoading(false);
    }
    setPreviousGroupings(groupings);
    setPreviousStartDate(startDate);
    setPreviousEndDate(endDate);
    setPreviousSuperTenant(superTenant);
  }

  async function getChartData() {
    setIsChartLoader(true);
    const cachedData = Utils.instance().checkHash(generateObjectToHash(), `${reportName}-chart`);
    if (cachedData) {
      setChartData(cachedData);
      setIsChartData(true);
      setIsChartLoader(false);
    } else {
      OdinForgeService.instance()
        .getCancellationSubscriptionChart(params, `${clonedReportId}-chart`)
        .then(async (result: { data: CancellationSubscriptionReportData; cacheTime: number }) => {
          setChartData(result.data);
          setIsChartData(true);

          createHash(generateObjectToHash, result.data, `${reportName}-chart`, result.cacheTime);
        })
        .catch((error) => {
          if (error.code === 'ERR_CANCELED') {
            console.log('Request canceled');
          } else {
            setIsChartLoader(false);
            Utils.instance().onFailure('An error occurred while fetching the chart data');
          }
        });
    }
  }

  function generateConfigRequestBody() {
    return {
      filters: {
        group_sequence: groupings,
        expand_level: collapse,
        date_range: dateRange,
        start_date: startDate ? formatLocalISO(startDate as Dayjs) : null,
        end_date: endDate ? formatLocalISO(endDate as Dayjs) : null,
      },
      report_name: reportName,
    };
  }

  function generateClonedData(reportId: number) {
    const dataToPass = {
      group_sequence: groupings,
      id: reportId,
      expand_level: collapse,
      start_date: startDate,
      end_date: endDate,
      data: data,
      type: 'cloned',
      date_range: dateRange,
    };
    passData(dataToPass);
  }

  useEffect(() => {
    handleBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, superTenant, groupings]);

  const handleSearch = (isReset = false) => {
    if (searchTerm !== '') localStorage.setItem('searchTerm', searchTerm);

    if (!isReset) {
      setSearchTerm('');
      localStorage.removeItem('searchTerm');
      setGlobalFilter('');
      setIsNoData(false);
    } else {
      setGlobalFilter(searchTerm);
    }

    handleBlur(true);
  };

  useEffect(() => {
    setCsvFilters(Array.from(new Set([...groupings.map((grouping) => `${namesEnum[grouping]}`), ...(searchTerm ? [`search-${searchTerm}`] : [])])));
  }, [groupings, searchTerm]);

  return (
    <div className={'pb-10'}>
      <div className='!p-6 tablet-size:!p-0'>
        <FilterBar
          handleBlur={handleBlur}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          groupings={groupings}
          setGroupings={setGroupings}
          collapse={collapse}
          setCollapse={setCollapse}
          selectedGroupings={selectedGroupings}
          copyButton={copyButton}
          freezeButton={freezeButton}
          showDelete={showDelete}
          clonedReportId={clonedReportId}
          setDeletePopup={setDeletePopup}
          passId={passId}
          deletePopup={deletePopup}
          setFreezeButton={setFreezeButton}
          setCopyButton={setCopyButton}
          clonedData={clonedData}
          generateConfigRequestBody={generateConfigRequestBody}
          generateClonedData={generateClonedData}
          names={names}
          title={ReportName.CancellationSubscriptionReasons}
          isAdditionalCurrencyField={false}
          maxSelections={5}
          data={transformedTableDataCSV}
          fieldsForCSV={fieldsForCSV}
          disabledCSV={!showTable}
          namesEnum={namesEnum}
          isCSV={true}
          transformDataToCSVFormat={transformDataToCSVFormat}
          csvFilters={csvFilters}
          isCustomFilters={true}
          setRefreshKey={setRefreshKey}
          refreshKey={refreshKey}
          isSearch={true}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
        />
      </div>
      {data && data.length !== 0 && showTable && groupings.length !== 0 ? (
        <ReportTable
          data={data}
          initialExpandedDepth={(Number(collapse) - 1)?.toString()}
          onNoData={setIsNoData}
          globalFilter={globalFilter}
          searchTerm={searchTerm}
          groupingsCount={groupings.length}
          levelNames={groupings}
          getColumns={getColumns}
        />
      ) : (
        <div className='mt-6'>
          <DefaultTableMessage
            key={loaderKey}
            tableLoading={tableLoading}
            state={groupings.length === 0 ? 'noFilters' : isNoData ? 'emptySearchResult' : 'noData'}
          />
        </div>
      )}
      {isChartLoader && !isChartData && <ChartsSkeleton />}
      <div className={'mt-10 flex justify-between gap-10 flex-wrap !p-4 tablet-size:!p-1'}>
        {showTable && isChartData ? (
          <>
            <div className={'flex flex-col gap-4'}>
              <div className={'ring-stone-200 dark:ring-[#353E50] ring-2 w-full rounded-xl h-full p-4 tablet-size:p-6 min-w-min'}>
                <TotalSectionComponent data={chartData} />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CancellationSubscriptionReport;
