import getSymbolFromCurrency from 'currency-symbol-map';
import { RevenueSummaryReportData, RevenueSummaryReportRowInterface } from '../types/revenue-summary-report.types';
import capitalizeSpecificWords from '../../../../../helpers/capitalizeSpecificWords';

function getKeyByValue(obj: { [key: string]: string }, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function transformDataToCSVFormat(data: RevenueSummaryReportData, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }, filters?: string[] | undefined) {
    const result: { [key: string]: string | number }[] = [];
    const groupingLevels = new Set<number>();
    const filtersList = ['Currency', ...filters!] 

    function processItem(item: RevenueSummaryReportRowInterface, hierarchy: string[] = [], level: number = 0) {
      const baseItem: { [key: string]: string | number } = {
        sales: item.sales !== null || item.sales !== undefined ? (Number(item.sales))?.toLocaleString() : '0',
        partials: item.partials !== null || item.partials !== undefined ? (Number(item.partials))?.toLocaleString() : '0',
        partial_success: Number(item.partial_success) ? (`${parseFloat(item.partial_success.toString()).toFixed(2)}%`) : '0.00%',
        gross_revenue: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.gross_revenue) ? Number(item.gross_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        net_revenue: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.net_revenue) ? Number(item.net_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        aov: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.aov) ? Number(item.aov).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        net_aov: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.net_aov) ? Number(item.net_aov).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        refunds: item.refunds !== null || item.refunds !== undefined ? (Number(item.refunds))?.toLocaleString() : '0',
        refunds_revenue: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.refunds_revenue) ? Number(item.refunds_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
        chargebacks: item.chargebacks !== null || item.chargebacks !== undefined ? (Number(item.chargebacks))?.toLocaleString() : '0',
        chargebacks_revenue: `${(item.currency && item.currency !== 'NaN' ? getSymbolFromCurrency(item.currency) ? getSymbolFromCurrency(item.currency) : item.currency : '') + " " + (Number(item.chargebacks_revenue) ? Number(item.chargebacks_revenue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00')}`,
      };

      if (item.name || hierarchy.length > 0) {
        groupingLevels.add(level);
      }

      const humanReadableKey = getKeyByValue(namesEnum, filtersList[level]) ?? filtersList[level];

      baseItem[humanReadableKey] = item.name && item.name !== 'NaN' ? capitalizeSpecificWords(item.name) : '---';

      result.push(baseItem);

      Object.keys(namesEnum).forEach((key) => {
        const nestedKey = namesEnum[key];
        if (Array.isArray(item[nestedKey])) {
          item[nestedKey].forEach((nestedItem: RevenueSummaryReportRowInterface) =>
            processItem(nestedItem, [...hierarchy, item.name || ''], level + 1)
          );
        }
      });
    }

    if (data.currency && Array.isArray(data.currency)) {
      // @ts-ignore
      data.currency.forEach((currency) => processItem(currency));
    }

    const groupingFields = Array.from(groupingLevels).map((level) => {
      const foundKey = getKeyByValue(namesEnum, filtersList[level]);
      return foundKey ?? filtersList[level];
    });

    return result.map((item) => {
      const transformedItem: { [key: string]: string | number } = {};
      groupingFields.forEach((field) => {
        if (field === 'Currency' && item[field]) {
          transformedItem[field] = `${String(item[field])?.toUpperCase()} - ${getSymbolFromCurrency(String(item[field]))}`;
        } else {
          transformedItem[field] = item[field] || '';
        }
      });
      Object.keys(fieldsForCSV).forEach((key) => {
        transformedItem[key] = item[fieldsForCSV[key]];
      });
      return transformedItem;
    });
  }

  export default transformDataToCSVFormat;