import React, { useContext } from "react";
import CustomActionButtons from "../../UI/actionButtons/actionButtons";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeContext } from "../../../context/ThemeContext/ThemeContext";
import ReportName from "../../../odinForgeService/Enums/ReportName";
import TableSelectDropdown from "../../UI/selection/TableSelectDropdown";
import ExpandButtons from "../../UI/expandButtons/ExpandButtons";
import TableDateTimePicker from "../../UI/DatePickerComponent/TableDateTimePicker";
import SearchByRow from "../../molecules/SearchByRow/SearchByRow";

dayjs.extend(utc);
dayjs.extend(timezone);

interface FilterBarFraudAnalysisReportProps<T1, T2, T3> {
    handleBlur: () => void;
    startDate: string | Dayjs;
    endDate: string | Dayjs;
    setStartDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    setEndDate: React.Dispatch<React.SetStateAction<string | dayjs.Dayjs>>
    dateRange: number;
    setDateRange: (newValue: number) => void;
    selectedGroupings: number;
    setTempCounter?(): number[];
    copyButton: boolean;
    freezeButton: boolean;
    buttonBackgroundColor?: string;
    iconFillColor?: string;
    showDelete: boolean;
    clonedReportId: string | number | null | undefined;
    setDeletePopup: (newValue: boolean) => void;
    passId: (id: string | number | null | undefined) => void;
    deletePopup: boolean;
    setButtonBackgroundColor?: (newValue: string) => void;
    setFreezeButton: (newValue: boolean) => void;
    setCopyButton: (newValue: boolean) => void;
    setIconFillColor?: (newValue: string) => void;
    clonedData: T1;
    generateConfigRequestBody: () => {
      filters: {
          date_range: number;
          start_date: string | null;
          end_date: string | null;
      };
      report_name: ReportName;
    };
    generateClonedData(reportId: number): void;
    title: string;
    data: T2;
    fieldsForCSV?: { [key: string]: string };
    disabledCSV?: boolean;
    namesEnum?: { [key: string]: string };
    isCSV?: boolean;
    transformDataToCSVFormat?: (data: T3, namesEnum: { [key: string]: string }, fieldsForCSV: { [key: string]: string }) => {
      [key: string]: string | number;
    }[];
    csvFilters?: string[];
    isCustomFilters?: boolean;
    disableFutureDates?: boolean;
    rangeConfigs?: {
      [key: string]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
      [key: number]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
  }
    dateRangeList?: string[];
    setRefreshKey?: React.Dispatch<React.SetStateAction<number>>
    refreshKey?: number
    generateRangeConfigs?: (timezone: string) => {
      [key: string | number]: {
          startDate: dayjs.Dayjs;
          endDate: dayjs.Dayjs;
      };
    }
    dateRangeListCustom?: string[];
    groupings: string[];
    setGroupings: (newValue: string[]) => void;
    names: string[];
    maxSelections: number;
    collapse: string;
    setCollapse: React.Dispatch<React.SetStateAction<string>>;
    isSearch?: boolean;
    searchTerm?: string;
    setSearchTerm?: React.Dispatch<React.SetStateAction<string>>
    handleSearch?: (isReset?: boolean) => void
}

const FilterBarFraudAnalysisReport = <T1,T2,T3,> ({
    handleBlur,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRange,
    setDateRange,
    selectedGroupings,
    setTempCounter,
    copyButton,
    freezeButton,
    buttonBackgroundColor,
    iconFillColor,
    showDelete,
    clonedReportId,
    setDeletePopup,
    passId,
    deletePopup,
    setButtonBackgroundColor,
    setFreezeButton,
    setCopyButton,
    setIconFillColor,
    clonedData,
    generateConfigRequestBody,
    generateClonedData,
    title,
    data,
    fieldsForCSV,
    disabledCSV,
    namesEnum,
    isCSV,
    transformDataToCSVFormat,
    csvFilters,
    isCustomFilters,
    disableFutureDates,
    rangeConfigs,
    dateRangeList,
    setRefreshKey,
    refreshKey,
    generateRangeConfigs,
    dateRangeListCustom,
    groupings,
    setGroupings,
    names,
    maxSelections,
    collapse,
    setCollapse,
    isSearch,
    searchTerm,
    setSearchTerm,
    handleSearch,
}: FilterBarFraudAnalysisReportProps<T1,T2,T3>) => {
    const { themedColors } = useContext(ThemeContext)!;

    return (
        <>
            <div
                className={
                "flex tablet-size:justify-between gap-4 tablet-size:gap-4 flex-wrap items-center"
                }
            >
                <div style={{color: themedColors.content}}  className={"text-xl tablet-size:text-2xl font-bold text-wrap mr-auto tablet-size:mr-0"}>
                    {title} 
                </div>
                <TableDateTimePicker
                    handleBlur={handleBlur}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    disableFutureDates={disableFutureDates}
                    customRangeConfigs={rangeConfigs}
                    customDateRangeList={dateRangeList}
                    reportName={title}
                    customGenerateRangeConfigs={generateRangeConfigs}
                />
            </div>

            <div
                className={
                "flex flex-row justify-between flex-wrap gap-4 mt-6 tablet-size:mt-"
                }
            >
                <div
                    className={
                        "flex flex-row items-end flex-wrap relative tablet-size:left-[-8px] gap-5"
                    }
                >
                    <TableSelectDropdown
                        handleBlur={handleBlur}
                        groupings={groupings}
                        setGroupings={setGroupings}
                        names={names}
                        maxSelections={maxSelections}
                    />
                    {
                        data && Object.keys(data).length > 0 && (
                            <div className="relative tablet-size:mt-0 top-[2px] tablet-size:top-0">
                                <ExpandButtons
                                collapse={collapse}
                                setCollapse={setCollapse}
                                // remove the last element from groupings
                                groupings={groupings.slice(0, -1)}
                                />
                            </div>
                        )
                    }
                </div>
                <div className="flex flex-wrap gap-3 items-end tablet-size:ml-auto">
                <CustomActionButtons
                    copyButton={copyButton}
                    freezeButton={freezeButton}
                    showDelete={showDelete}
                    clonedReportId={clonedReportId}
                    setDeletePopup={setDeletePopup}
                    passId={passId}
                    deletePopup={deletePopup}
                    setFreezeButton={setFreezeButton}
                    setCopyButton={setCopyButton}
                    clonedData={clonedData}
                    configRequestBody={generateConfigRequestBody()}
                    generateClonedData={generateClonedData}
                    data={data}
                    fieldsForCSV={fieldsForCSV}
                    reportName={title}
                    startDate={startDate}
                    endDate={endDate}
                    namesEnum={namesEnum}
                    disabledCSV={disabledCSV}
                    isCSV={isCSV}
                    transformDataToCSVFormat={transformDataToCSVFormat}
                    setRefreshKey={setRefreshKey}
                    refreshKey={refreshKey}
                    groupings={csvFilters ? csvFilters : groupings}
                    isCustomFilters={isCustomFilters}
                />
                </div>
            </div>
            <div className="max-w-[440px] mt-6">
                {
                    isSearch && (
                    <SearchByRow
                        searchTerm={searchTerm ?? ""}
                        setSearchTerm={setSearchTerm ?? (() => {})}
                        handleSearch={handleSearch ?? (() => [])}
                    />
                    )
                }
            </div>
        </>
    )
}

export default FilterBarFraudAnalysisReport