enum ReportName {
    SalesPaceReportTable = 'Sales Pace Report',
    SalesPaceReportLineChart = 'Sales Pace Report Line Chart',
    SalesPerformanceReport = 'Sales Performance Report',
    SalesPerformanceReportLineChart = 'Sales Performance Report Line Chart',
    TransactionPerformanceReport = 'Transaction Performance Report',
    RetentionReport = 'Retention Report',
    AffiliateSalesSummary = 'Affiliate Sales Summary',
    CancellationReasons = 'Cancellation Reasons (Orders)',
    CancellationSubscriptionReasons = 'Cancellation Reasons (Subscriptions)',
    ForecastedRebillReport = 'Forecasted Rebills Report',
    DashboardOverview = 'Dashboard Overview',
    VarianceReport = 'Variance Report',
    ConversionReport = 'Conversion Report',
    DeclineResponseReport = 'Decline Response Report',
    BinRoutingPerformaceReport = 'BIN Routing Performance Report',
    ChargebackSummaryReport = 'Chargeback Summary Report',
    CardProcessingReport = 'Card Processing Report',
    DuplicateCustomerReport = 'Duplicate Customer Report',
    FraudAnalysisReport = 'Fraud Analysis Report',
    FulfillmentSummaryReport = 'Fulfillment Summary Report',
    ErrorsShownCustomerReport = 'Errors Shown to Customer Report',
    CustomerPurchaseAttemptsReport = 'Customer Purchase Attempts on Landers',
    RevenueSummaryReport = 'Revenue Summary Report',
    DtcPopulatedFieldsReport = 'DTC Populated Fields',
    CapProjectionsReport = 'Cap Projections Report',
};

enum ReportIds {
    SalesPaceReportTable = 1,
    SalesPaceReportLineChart = 2,
    SalesPerformanceReport = 4,
    SalesPerformanceReportLineChart = 6,
    AffiliateSalesSummary = 7,
    RetentionReport = 8,
    CancellationReasons = 9,
    ForecastedRebillReport = 11,
    TransactionPerformanceReport = 13,
    ConversionReport = 14,
    DeclineResponseReport = 16,
    CancellationSubscriptionReasons = 18,
    BinRoutingPerformaceReport = 19,
    VarianceReport = 20,
    CardProcessingReport = 22,
    DuplicateCustomerReport = 23,
    ChargebackSummaryReport = 25,
    FraudAnalysisReport = 24,
    FulfillmentSummaryReport = 26,
    ErrorsShownCustomerReport = 27,
    CustomerPurchaseAttemptsReport = 28,
    RevenueSummaryReport = 29,
    DtcPopulatedFieldsReport = 30,
    CapProjectionsReport = 31,
}

export default ReportName;

export { ReportIds };