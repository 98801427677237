import { ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { ReactNode, useContext } from 'react'
import { ThemeContext } from '../../../../context/ThemeContext/ThemeContext';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface PaginationSelectProps {
    value: number;
    onChange: (event: SelectChangeEvent<number>, child: ReactNode) => void
}

const PaginationSelect = ({value,onChange}: PaginationSelectProps) => {
    const { theme, themedColors } = useContext(ThemeContext)!;

  return (
    <Select
        value={value}
        onChange={onChange}
        IconComponent={ChevronDownIcon}
        className={"!h-10 text-sm hover:!border-odin-dark-blue-medium focus:!border-odin-dark-blue-medium"}
        sx={{
              "& .MuiSelect-icon, & .MuiSelect-iconOpen": {
                top: "11px",
                width: "18px",
                color: themedColors.content,
              },
              "& .MuiSelect-icon.MuiSelect-iconOpen": {
                width: "20px",
                color: "#0079FF",
              },
              "&.MuiOutlinedInput-root": { borderRadius: "6px" },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline": { borderColor: "#0079FF" },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" },
              "& .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${theme === 'light' ? '#ACBFDF' : '#495D80'}`,
              },
              "& .MuiTypography-root": {
                fontSize: "14px !important",
              },
        }}
    >
        {[10, 20, 30, 40, 50].map((size) => (
            <MenuItem 
                key={size} 
                value={size}
                sx={{
                    "&.MuiMenuItem-root .MuiTypography-root": {
                      backgroundColor: themedColors.background_1,
                      fontSize: "14px !important",
                    },
                    "&.MuiMenuItem-root:hover, &.Mui-selected": {
                      backgroundColor: themedColors.background_1,
                      fontSize: "14px !important",
                    },
                  }}
            >
                <ListItemText primary={size} style={{ fontSize: "14px !important" }} />
            </MenuItem>
        ))}
  </Select>
  )
}

export default PaginationSelect